import axios from 'axios'
import React, { useEffect, useState } from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import coinGecko from '../api/coinGecko'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import { Button, Grid } from '@mui/material'



const OperationPro = (props) => {

  const Logged = localStorage.getItem('userStatus')
  const role = localStorage.getItem('role')

  const [finalPrice3,                     setFinalPrice3          ] = useState()
  const [LastMontantTotal3,               setLastMontantTotal3    ] = useState()
  const [cPstatusData,                    setCPstatusData         ] = useState()
  const [RechargeData,                    setRechargeData         ] = useState([])
  const [TransData,                       setTransData            ] = useState([])
  const [BuyData,                         setBuyData              ] = useState([])
  const [Recharges,                       setRecharges            ] = useState(false)
  const [Buyings,                         setBuyings              ] = useState(true)
  const [Trans,                           setTrans                ] = useState(false)

  useEffect(()=>{
    if(Logged === 'Logout'){
      props.history.push(`/login`)
    }

    if (Number(role) !== 1) {
      props.history.push('/')
    }
  },[Logged, props.history, role])



  useEffect(()=>{
    setLastMontantTotal3(LastMontantTotal3)
    setFinalPrice3(finalPrice3)
  },[LastMontantTotal3,finalPrice3])



  useEffect(()=>{

      const fetchDada = async () => {

      try {
          const { data } = await axios.get(
          '/api/v1/get-p-recharge',
          )
          setRechargeData(data)
      } catch (error) {}

      try {
          const { data } = await axios.get(
          '/api/v1/get-p-trans',
          )
          setTransData(data)
      } catch (error) {}

      try {
          const { data } = await axios.get(
          '/api/v1/get-p-buy',
          )
          setBuyData(data)
      } catch (error) {}

      }

      fetchDada()

  },[])

  useEffect(() => {
    const fetchData = async () => {

      try {
        const { data } = await axios.get(
          `/api/v1/get-cinetpay-status`,
        )
        setCPstatusData(data)
      } catch (error) {
        console.log(error)
      }
    }

    fetchData()

  }, [cPstatusData])

  const detailOption = (e) => {
    const __orderId = e.target.getAttribute('value')
    if (__orderId) {
      props.history.push(`/order-details/${__orderId}`)
    }
  }



  function rechargeHanlder(){
    setRecharges(true)
    setBuyings(false)
    setTrans(false)
  }

  function buyingHanlder(){
    setBuyings(true)
    setRecharges(false)
    setTrans(false)
  }

  function transHanlder(){
    setTrans(true)
    setRecharges(false)
    setBuyings(false)
  }
  const [_BTC, setBTC] = useState([])

  useEffect(()=>{
    const fetchDada = async () => {
      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids: 'bitcoin',
        },
      })
      setBTC(response.data[0].current_price)
    }
    fetchDada()
},[])


  return (
    <div className="container">
      <div className='d-flex justify-content-around p-4'>
        <Grid container>
            <Grid item xs sx={{ mr: 1}}>
              {
                Recharges?
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#010a13', fontWeight:'bolder'}} >Rechargements</Button>:
                <Button type="submit" onClick={rechargeHanlder} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558', fontWeight:'bolder' }} >Rechargements</Button>
              }
            </Grid>
            <Grid item xs sx={{ ml: 1, mr: 1}}>
              {
                Buyings?
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#010a13', fontWeight:'bolder'}} >Achats</Button> :
                <Button type="submit" onClick={buyingHanlder} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558', fontWeight:'bolder' }} >Achats</Button> 
              }
            </Grid>
            <Grid item xs sx={{ ml: 1, mr: 1}}>
              {
                Trans?
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#010a13', fontWeight:'bolder'}} >Transactions</Button> :
                <Button type="submit" onClick={transHanlder} fullWidth variant="contained" sx={{ mt: 3, mb: 2, bgcolor: '#133558', fontWeight:'bolder'}} >Transactions</Button> 
              }
            </Grid>
          </Grid>
      </div>
        <div className="__op-body-table">
          {
            Recharges ? 
            <table className='__op-responsive-table'>
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Service</th>
                  <th scope="col">Action</th>
                  <th scope="col">Montant</th>
                  <th scope="col">Compte</th>
                  <th scope="col">Date</th>
                  <th scope="col">Statut</th>
                </tr>
              </thead>
              <tbody>
                {!RechargeData ? null : 
                  RechargeData.map((item) => (
                    <tr key={item.ID}>
                      <th scope="row"><span className='text-success'>{item.email}</span></th>
                      <td data-title="Contact"><span className='text-success'>{item.phone}</span></td>
                      <td data-title="Service"><span className='text-success'><img className='images-drapeau mr-2' src={item.banqueImage} alt="" /></span></td>
                      <td data-title="Action"><span className='text-success'>{item.banqueName}</span></td>
                      <td data-title="Montant"><span className='text-success'>{item.rbserved} XOF</span></td>
                      <td data-title="Compte"><span className='text-success'>{item.numcompte}</span></td>
                      <td data-title="Date"><span className='text-success'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))}{item.updatedAt.slice(13, 19)}</span></td>
                      <td data-title="Statut">
                        {
                          item.status === "processing" ? 
                          <span className="creditation-btn"><a href={`/p-recharge-confirmation/${item.ID}`} style={{fontSize:'11px'}}>...</a></span> :
                          item.status === "successful" ?
                          <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span> :
                          item.status === "failed" ?
                          <span className="text-danger" style={{fontSize:'11px'}}> <SmsFailedIcon/> </span> : null
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table> :
            Buyings ? 
            <table className='__op-responsive-table'>
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Quantité</th>
                  <th scope="col">Crypto</th>
                  <th scope="col">Taux</th>
                  <th scope="col">Montant</th>
                  <th scope="col">Date</th>
                  <th scope="col">Statut</th>
                </tr>
              </thead>
              <tbody>
                {!BuyData ? <div className="d-flex justify-content-center"><div className="nkab-spinner "></div></div> : BuyData.map((item) => (
                    <tr key={item.ID}>
                      <th scope="row"><span className='text-success'>{item.email}</span></th>
                      <td data-title="Quantité"> {item.converted}</td>
                      <td data-title="Crypto"><img className='images-drapeau mr-2' src={item.moneyImage} alt="" /></td>
                      <td data-title="Taux"><span className='text-success'>{item.taux} XOF</span></td>
                      <td data-title="Montant"><span className='text-success'>{item.montant} XOF</span></td>
                      <td data-title="Date"><span className='text-success'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))}{item.updatedAt.slice(13, 19)}</span></td>
                      <td data-title="Statut">
                        {
                          item.status === "processing" ? 
                          <span className="creditation-btn"><a href={`/p-buy-confirmation/${item.ID}`} style={{fontSize:'11px'}}>...</a></span> :
                          item.status === "successful" ?
                          <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span> :
                          item.status === "failed" ?
                          <span className="text-danger" style={{fontSize:'11px'}}> <SmsFailedIcon/> </span> : null
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table> :
            Trans ? 
            <table className='__op-responsive-table'>
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Transfert</th>
                  <th scope="col">Bénéficiaire</th>
                  <th scope="col">Montant</th>
                  <th scope="col">Par</th>
                  <th scope="col">Date</th>
                  <th scope="col">Statut</th>
                </tr>
              </thead>
              <tbody>
                {!TransData ? <div className="d-flex justify-content-center"><div className="nkab-spinner "></div></div> : TransData.map((item) => (
                    <tr key={item.ID}>
                      <th scope="row"><span className='text-success'>{item.email}</span></th>
                      <td data-title="Transfert"><img className='images-drapeau mr-2' src={item.changeImage} alt="" /></td>
                      <td data-title="Bénéficiaire"><span className='text-success'>{item.phone}</span></td>
                      <td data-title="Montant"><span className='text-success'>{item.montant - (item.montant * 0.04)} XOF</span></td>
                      <td data-title="Par">{item.fullName}</td>
                      <td data-title="Date"><span className='text-success'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))}{item.updatedAt.slice(13, 19)}</span></td>
                      <td data-title="Statut">
                          {
                            item.changeName === "Wave" && item.status === "processing" ? 
                            <span className="creditation-btn"><a href={`/p-trans-confirmation/${item.ID}`} style={{fontSize:'11px'}}>...</a></span> :
                            item.changeName === "Wave" && item.status === "successful" ? 
                            <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span>:
                            item.status === "successful" ? 
                            <span className="text-success" style={{fontSize:'11px'}}> <CheckCircleIcon/> </span>:
                            item.status === "failed" ? 
                            <span className="text-danger" style={{fontSize:'11px'}}> <SmsFailedIcon/> </span>:null
                          }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table> : null
          }
        </div>
    </div>
  )
}

export default OperationPro
