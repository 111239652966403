import React, { useEffect, useState, useRef} from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import ProfileScreen from './screens/ProfileScreen'
import NavbarOn from './components/NavbarOn'
import Footer from './components/Footer'
import ContactScreen from './screens/ContactScreen'
import AboutScreen from './screens/AboutScreen'
import AdminScreen from './screens/AdminScreen'
import NewServiceScreen from './screens/NewServiceScreen'
import OperationScreen from './screens/OperationScreen'
import NewOperatorScreen from './screens/NewOperatorScreen'
import HistoryDetails from './screens/historyDetails'
import UserHistoryScreen from './screens/UserHistoryScreen'
import InfoScreen from './screens/InfoScreen'
import WavePaymentScreen from './screens/WavePaymentScreen'
import FinalBscPaymentScreen from './screens/FinalBscPaymentScreen'
import FinalTetherPaymentScreen from './screens/FinalTetherPaymentScreen'
import NextVerificationScreen from './screens/NextVerificationScreen'
import OurCondition from './screens/OurCondition'
import OurPolitical from './screens/OurPolitical'
import ActivationEmail from './screens/ActivationEmail'
import ForgotPassword from './screens/ForgotPassword'
import ResetPassword from './screens/ResetPassword'
import EditUser from './screens/EditUser'
import FinalWavePaymentScreen from './screens/FinalWavePaymentScreen'
import EditUserWave from './screens/EditUserWave'
import Withdrawal from './screens/WithdrawalScreen'
import SendAfterSuccessByAdmin from './screens/SendAfterSuccessByAdmin'
import FilesScreen from './screens/FilesScreen'
import LargeFileSreen from './screens/LargeFileSreen'
import homeScreen from './screens/HomeScreen'
import BuyAndSellScreen from './screens/BuyAndSellScreen'
import SendingMoneyScreen from './screens/SendingMoneyScreen'
import SendingByCountry from './screens/SendingByCountry'
import SendUsdtOrBscAfterSuccessByAdmin from './screens/SendUsdtOrBscAfterSuccessByAdmin'
import UnActivateUsdtOrBscAfterSuccessByAdmin from './screens/UnActivateUsdtOrBscAfterSuccessByAdmin'
import NextVerificationScreen5 from './screens/NextVerificationScreen5'
import SendsellAfterSuccessByAdmin from './screens/SendsellAfterSuccessByAdmin'
import Demo from './screens/Demo'
import OperationConfirmationScreen from './screens/OperationConfirmationScreen'
import OperationWaveConfirmationScreen from './screens/OperationWaveConfirmationScreen'
import UserDetailsScreen from './screens/UserDetailsScreen'
import FinalTronPaymentScreen from './screens/FinalTronPaymentScreen'
import ViewMore from './screens/ViewMore'
import AdminStat from './screens/AdminStat'
import CmssScreen from './screens/CmssScreen'
import AdminSendingInfo from './screens/AdminSendingInfo'
import NkabPro from './screens/NkabPro'
import OrderScreen from './screens/OrderScreen'
import OperationPro from './screens/OperationPro'
import OperationProConfirmation from './screens/OperationProConfirmation'
import OperationProConfirmation2 from './screens/OperationProConfirmation2'
import OperationProConfirmation3 from './screens/OperationProConfirmation3'
import SignIn from './components/singin/SingIn'
import SignUp from './components/singup/SingUp'
import Consultation from './components/consultation/Consultation'
import ForAllTest from './screens/ForAllTest'
import AdminStatBuyMoment from './screens/AdminStatBuyMoment'
import AdminStatSellMoment from './screens/AdminStatSellMoment'
import ListePreium from './screens/ListePreium'
import LoadingBar from 'react-top-loading-bar';

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];



function App() {

  var    _ProWallet     = localStorage.getItem('_ProWallet')
  const  transActivated = localStorage.getItem('transActivated')
  const  Logged         = localStorage.getItem('userStatus')

  const [Balanced,        setBalanced           ] = useState(false)
  const [animate_dnone,   setAnimate_dnone      ] = useState()
  const [web_page,        setWeb_page           ] = useState()

  const STATUS  = { STARTED:  'Traitement en cours...', }
  const STATUS2 = { STARTED2: 'Traitement en cours...', }
  const STATUS3 = { STARTED3: 'Traitement en cours...', }

  const INITIAL_COUNT1 = 2
  const INITIAL_COUNT2 = 300
  const INITIAL_COUNT3 = 0
  const [secondsRemaining1, setSecondsRemaining1] = useState(INITIAL_COUNT1)
  const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
  const [secondsRemaining3, setSecondsRemaining3] = useState(INITIAL_COUNT3)
  const [status1, setStatus1] = useState(STATUS.STOPPED)
  const [status2, setStatus2] = useState(STATUS2.STOPPED2)
  const [status3, setStatus3] = useState(STATUS3.STOPPED3)

  const LineLink = true
  // const LineLink = false
  
  useEffect(()=>{
    setStatus1(STATUS.STARTED)
  },[STATUS.STARTED])
  
    useEffect(()=>{
      setStatus2(STATUS2.STARTED2)
    },[STATUS2.STARTED2])
  
    useEffect(()=>{
      setStatus3(STATUS3.STARTED3)
    },[STATUS3.STARTED3])
  
  useInterval(
    () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)
  
useInterval(
  () => {
  if (secondsRemaining2 > 0) {
      setSecondsRemaining2(secondsRemaining2 - 1)
  } else {
      setStatus2(STATUS2.STOPPED2)
  }
  },

  status2 === STATUS2.STARTED2 ? 1000 : null,
  // passing null stops the interval
)
  
useInterval(
  () => {
  if (secondsRemaining3 === 0 || secondsRemaining3 < 100) {
      setSecondsRemaining3(secondsRemaining3 + 1)
  } else {
      setStatus3(STATUS3.STOPPED3)
  }
  },

  status3 === STATUS3.STARTED3 ? 70 : null,
  // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining2 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining2])

  useEffect(()=>{

    if(transActivated==="" || !transActivated){
      localStorage.setItem("transActivated", "true")
    }

    if(_ProWallet==="" || !_ProWallet){
      localStorage.setItem("_ProWallet", "false")
    }
    
    if(_ProWallet === "false" || Logged === "Logout"){
      setBalanced(true)
    }
    
    if(_ProWallet === "true"){
      setBalanced(false)
    }

  },[_ProWallet, transActivated, Logged])
  
    /// FONCTION DE DECONNECTION /////////////
    let timer;  
    // this function sets the timer that logs out the user after 10mn
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
        // clears any pending timer.
        resetTimer();
        // Listener clean up. Removes the existing event listener from the window
        Object.values(events).forEach((item) => {
            window.removeEventListener(item, resetTimer);
        });
        // logs out user
        logoutAction();
        }, 600000); // 300000ms = 300secs = 5mn You can change the time.
    };
    
    // this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };
    
    // when component mounts, it adds an event listeners to the window
    // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
    // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
    useEffect(() => {
        Object.values(events).forEach((item) => {
          window.addEventListener(item, () => {
              resetTimer();
              handleLogoutTimer();
          });
        });
    }, []);
    
    // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
    const logoutAction = () => {

      localStorage.clear();

      if(_ProWallet === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", true)
        localStorage.setItem("userStatus", "Logout")
        localStorage.setItem("isMarchandActive", true)

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
      }
      if(_ProWallet === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", false)
        localStorage.setItem("userStatus", "Logout")
        localStorage.setItem("isMarchandActive", true)
      }
      if(transActivated === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("transActivated", true)
        localStorage.setItem("isMarchandActive", true)
      }
      if(transActivated === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("transActivated", false)
        localStorage.setItem("isMarchandActive", true)
      }
      localStorage.setItem("isRActive",false)

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    };  
  
   useEffect(() => {

    if(transActivated === "false"){
      setAnimate_dnone('animate-dnone')
      setWeb_page('web-page')
    }

    if(transActivated === "true"){
  
      if (secondsRemaining1 > 0) {
          setAnimate_dnone('animate-dblock');
          setWeb_page('web-page-dnone');
      
        }
  
      if (secondsRemaining1 === 0) {
        setAnimate_dnone('animate-dnone')
        setWeb_page('web-page')
        localStorage.setItem("transActivated", false)
      }
    }
  
  }, [animate_dnone, web_page, secondsRemaining1, transActivated])


  return (
    <BrowserRouter>
      <div>
        <LoadingBar
          // color='#0a146e'
          className='nkab-progress-loadin-bar'
          height={3}
          progress={secondsRemaining3}
        />
      </div>
      <div className={`${animate_dnone}`}>
        <div className="animation-main">
          <div className="animation01">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="animation02">
            <div></div>
            <div></div>
          </div>
          <div className="animation03">
            <div className="circle">
              <div className="circle_element01"></div>
            </div>
            <div className="circle">
              <div className="circle_element02"></div>
            </div>
            <div className="circle">
              <div className="circle_element03"></div>
            </div>
            <div className="animation04">
              <div className="line_wrapper line_wrapper01">
                <span className="line line01"></span>
              </div>
              <div className="rotate45">
                <div className="line_wrapper line_wrapper02">
                  <span className="line line02"></span>
                </div>
              </div>
              <div className="line_wrapper line_wrapper03">
                <span className="line line03"></span>
              </div>
              <div className="rotate135">
                <div className="line_wrapper line_wrapper04">
                  <span className="line line04"></span>
                </div>
              </div>
              <div className="line_wrapper line_wrapper05">
                <span className="line line05"></span>
              </div>
              <div className="rotate-135">
                <div className="line_wrapper line_wrapper06">
                  <span className="line line06"></span>
                </div>
              </div>
              <div className="line_wrapper line_wrapper07">
                <span className="line line07"></span>
              </div>
              <div className="rotate-45">
                <div className="line_wrapper line_wrapper08">
                  <span className="line line08"></span>
                </div>
              </div>
            </div>
            <div className="animation05">
              <div className="double_wrapper02 green02">
                <div className="double_wrapper01 green01">
                  <div className="double_block green00"></div>
                </div>
              </div>
              <div className="double_wrapper02 navy02">
                <div className="double_wrapper01 navy01">
                  <div className="double_block navy00"></div>
                </div>
              </div>
              <div className="double_wrapper02 yellow02">
                <div className="double_wrapper01 yellow01">
                  <div className="double_block yellow00"></div>
                </div>
              </div>
              <div className="double_wrapper02 blue02">
                <div className="double_wrapper01 blue01">
                  <div className="double_block blue00"></div>
                </div>
              </div>
              <div className="double_wrapper02 red02">
                <div className="double_wrapper01 red01">
                  <div className="double_block red00"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="animation06">
            <div className="rhombus05">
              <div className="rhombus04">
                <div className="rhombus03">
                  <div className="rhombus02">
                    <div className="rhombus01"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="animation07">
            <div className="circle">
              <div className="circle_element01"></div>
            </div>
            <div className="line_wrapper line_wrapper01">
              <span className="line line01"></span>
            </div>
            <div className="rotate60">
              <div className="line_wrapper line_wrapper02">
                <span className="line line02"></span>
              </div>
            </div>
            <div className="rotate120">
              <div className="line_wrapper line_wrapper03">
                <span className="line line03"></span>
              </div>
            </div>
            <div className="line_wrapper line_wrapper04">
              <span className="line line04"></span>
            </div>
            <div className="rotate-120">
              <div className="line_wrapper line_wrapper05">
                <span className="line line05"></span>
              </div>
            </div>
            <div className="rotate-60">
              <div className="line_wrapper line_wrapper06">
                <span className="line line06"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${web_page}`} >
        <div className={`${themeMode === "true" ? "grid-container container-t" : "grid-container"}`}>
          <main>
            {
              Balanced || _ProWallet === "false" ? <NavbarOn themeMode={themeMode} /> : null
            }
            <Route path="/dashboard"                                component={AdminScreen} />
            <Route path="/stat"                                     component={AdminStat} />
            <Route path="/stat-buy/:id"                             component={AdminStatBuyMoment} />
            <Route path="/stat-sell/:id"                            component={AdminStatSellMoment} />
            <Route path="/operation"                                component={OperationScreen} />
            <Route path="/operation-pro"                            component={OperationPro} />
            <Route path="/cmss"                                     component={CmssScreen} />
            <Route path="/newservice"                               component={NewServiceScreen} />
            <Route path="/newoperator"                              component={NewOperatorScreen} />
            <Route path="/contact"                                  component={ContactScreen} />
            <Route path="/about"                                    component={AboutScreen} />
            <Route path="/reset_password/:token"                    component={ResetPassword} />
            <Route path="/payment-by-wave/cheked/:id"               component={WavePaymentScreen} />
            <Route path="/final-transaction/loading/:id"            component={FinalWavePaymentScreen} />
            <Route path="/payment-by-bsc/cheked/:id"                component={FinalBscPaymentScreen} />
            <Route path="/payment-away/cheked/:id"                  component={FinalTetherPaymentScreen} />
            <Route path="/payment-by-tron/cheked/:id"               component={FinalTronPaymentScreen} />
            <Route path="/redirect=true/nk-next-verification"       component={NextVerificationScreen} />
            <Route path="/redirect=true/nk-next-verification5/:id"  component={NextVerificationScreen5} />
            <Route path="/nkab-wallet"                              component={Withdrawal} />
            <Route path="/nkab-demo"                                component={Demo} />
            <Route path="/info-details"                             component={InfoScreen} />
            <Route path="/activate/:activation_token"               component={ActivationEmail} />
            <Route path="/forgot_password"                          component={ForgotPassword} />
            <Route path="/order/:id"                                component={OrderScreen} />
            <Route path="/orderhistory"                             component={UserHistoryScreen} />
            <Route path="/history-details/:id"                      component={HistoryDetails} />
            <Route path="/profile"                                  component={ProfileScreen} />
            <Route path="/edit_user/:id"                            component={EditUser} />
            <Route path="/view-more/:id"                            component={ViewMore} />
            <Route path="/edit_user_wave/:id"                       component={EditUserWave} />
            <Route path="/sell-confirmation/:id"                    component={SendAfterSuccessByAdmin} />
            <Route path="/sending-activated-sell/:id"               component={SendsellAfterSuccessByAdmin} />
            <Route path="/buy-confirmation/:id"                     component={OperationConfirmationScreen} />
            <Route path="/p-recharge-confirmation/:id"              component={OperationProConfirmation} />
            <Route path="/p-buy-confirmation/:id"                   component={OperationProConfirmation2} />
            <Route path="/p-trans-confirmation/:id"                 component={OperationProConfirmation3} />
            <Route path="/buy-wave-confirmation/:id"                component={OperationWaveConfirmationScreen} />
            <Route path="/usdt-or-bsc-activated-by/:id"             component={SendUsdtOrBscAfterSuccessByAdmin} />
            <Route path="/usdt-or-bsc-unactivated-by/:id"           component={UnActivateUsdtOrBscAfterSuccessByAdmin} />
            <Route path="/files/:id"                                component={FilesScreen} />
            <Route path="/files/large-file/:id"                     component={LargeFileSreen} />
            <Route path="/user/details/:id"                         component={UserDetailsScreen} />
            <Route path="/our-condition"                            component={OurCondition} />
            <Route path="/our-political"                            component={OurPolitical} />
            <Route path="/"                                         component={homeScreen} exact/>
            <Route path="/home"                                     component={homeScreen} />
            <Route path="/sell-or-buy-crypto"                       component={BuyAndSellScreen} />
            <Route path="/sending-money"                            component={SendingMoneyScreen} />
            <Route path="/sending-by-country"                       component={SendingByCountry} />
            <Route path="/adsinfo"                                  component={AdminSendingInfo} />
            <Route path="/nkab-pro"                                 component={NkabPro} />
            <Route path="/consultation"                             component={Consultation} />
            <Route path="/login"                                    component={SignIn} />
            <Route path="/singup"                                   component={SignUp} />
            <Route path="/singup-sp/:id"                            component={SignUp} />
            <Route path="/test"                                     component={ForAllTest} />
            <Route path="/liste-preium"                             component={ListePreium} />
            {
              Balanced || _ProWallet === "false" ? <Footer /> : null
            }
          </main>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default App
