import React, { useEffect, useState } from 'react'

const BottomNavBar = () => {

    const Logged              = localStorage.getItem('userStatus')
    var   _ProWallet          = localStorage.getItem('_ProWallet')

    const LineLink = true
    // const LineLink = false

    const [isMenuVisible, setMenuVisibility] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  
    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset;
        const isVisible = prevScrollPos > currentScrollPos;
    
        setMenuVisibility(isVisible);
        setPrevScrollPos(currentScrollPos);
    };
  
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [prevScrollPos]);

    function handleLogout(){
  
      localStorage.clear();
  
      if(_ProWallet === "true"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", true)
        localStorage.setItem("userStatus", "Logout")
  
        if(LineLink){
          window.location.replace("https://nkab-exchange.net/login");
        } else {
           window.location.replace("http://localhost:3000/login");
        }
      }
      if(_ProWallet === "false"){
        localStorage.setItem("isRActive",false)
        localStorage.setItem("_ProWallet", false)
        localStorage.setItem("userStatus", "Logout")
      }
      
      localStorage.setItem("isRActive",false)
  
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
  return (

    <div className='nkp_diplay_container'>
        <div id="nkp_bottom_navbar" className={isMenuVisible ? 'visible' : 'hidden'}>
            <div className='nkab_ul'>
                <div className="nkab_li">
                    <a href="/home">
                        <span className="nkp_bottom_span">
                          <img src="../../images/nkabico/t_ico_home.png" width="25" alt="nkab ico" />
                        </span>
                        <span className='nkp_bottom_navbar_title'>Home</span>
                    </a>
                </div>
                <div className="nkab_li">
                    <a href="/sell-or-buy-crypto">
                        <span className="nkp_bottom_span">
                          <img src="../../images/nkabico/t_ico_transfert.png" width="25" alt="nkab ico" />
                        </span> 
                        <span className='nkp_bottom_navbar_title'>Exchange</span>
                    </a>
                </div>
                <div className="nkab_li">
                    <a href="#logout" onClick={handleLogout}>
                        <span className="nkp_bottom_span">
                          <img src="../../images/nkabico/t_ico_connexion.png" width="25" alt="nkab ico" />
                        </span>
                        <span className='nkp_bottom_navbar_title'>{Logged === 'Logged'?"Logout":"login"}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BottomNavBar