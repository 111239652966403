import React from 'react'

const SupportCommunity = () => {
    
  return (
    <div className='text-center'>
        <div className='pt-3 pb-2 mt-3 community-support'>
            <div> <span className='bolder' style={{color:"#000"}}>Rejoignez notre communauté</span> </div>
            <div className='pt-2 pb-3'>
                <div>
                    <div className='text-center nkab-community telegram-bg pointer mb-3'>
                        <a href="https://t.me/+wTl8DCgNOwI5YWI0" className="icoFacebook" title="Telegram">
                        <span><strong className='telegram-color'> Telegram </strong></span>
                        <span><i className="fa fa-telegram telegram-color" aria-hidden="true"></i></span>
                        </a>
                    </div>
                    <div>
                        <a href="https://play.google.com/store/apps/details?id=com.scdigital.nkabexchange&fbclid=IwAR2Jv1Yh" className="playstore nkab" title="Facebook">
                        <img src="../../images/playstore.png" alt="" width="155" height="50" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportCommunity