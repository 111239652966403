import React from 'react'

const DomLoading = () => {
  return (
    <div className="my_dom_main-item">
        
        <div className="my_dom_animated-background">
            <div className="my_dom_background-masker my_dom_btn-divide-left"></div>
        </div>
        
        <div className="css-dom"></div>
    </div>
  )
}

export default DomLoading
