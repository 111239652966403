import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import LoadingBox from '../components/LoadingBox'


const AdminSendingInfo = (props) => {

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }



  const [GetUser,                    setGetUser                 ] = useState([])
  const [Text1,                      setText1                   ] = useState('')
  const [Text2,                      setText2                   ] = useState('')
  const [NkabStart,                  setNkabStart               ] = useState(false)
  const [NkabStart2,                 setNkabStart2              ] = useState(false)
  const [loading,                    setLoading                 ] = useState(false)
  const [Selected,                   setSelected                ] = useState('Tous')
  const [SelectedLength,             setSelectedLength          ] = useState([])
  const [sliceNbr1,                  setSliceNbr1               ] = useState(0)
  const [sliceNbr2,                  setSliceNbr2               ] = useState(50)
  const [Filted,                     setFilted                  ] = useState(false)
  const [CICountryChecked,           setCICountryChecked        ] = useState('')
  const [SENCountryChecked,          setSENCountryChecked       ] = useState('')
  const [TOGOCountryChecked,         setTOGOCountryChecked      ] = useState('')
  const [MALICountryChecked,         setMALICountryChecked      ] = useState('')
  const [NIGERCountryChecked,        setNIGERCountryChecked     ] = useState('')
  const [BURKCountryChecked,         setBURKCountryChecked      ] = useState('')
  const [GUINEECountryChecked,       setGUINEECountryChecked    ] = useState('')
  const [BENINCountryChecked,        setBENINCountryChecked     ] = useState('')
  const [CAMCountryChecked,          setCAMCountryChecked       ] = useState('')
  const [CISelectedStopReq,          setCISelectedStopReq       ] = useState(false)
  const [SENSelectedStopReq,         setSENSelectedStopReq      ] = useState(false)
  const [TOGOSelectedStopReq,        setTOGOSelectedStopReq     ] = useState(false)
  const [MALISelectedStopReq,        setMALISelectedStopReq     ] = useState(false)
  const [NIGERSelectedStopReq,       setNIGERSelectedStopReq    ] = useState(false)
  const [BURKSelectedStopReq,        setBURKSelectedStopReq     ] = useState(false)
  const [GUINEESelectedStopReq,      setGUINEESelectedStopReq   ] = useState(false)
  const [BENINSelectedStopReq,       setBENINSelectedStopReq    ] = useState(false)
  const [CAMSelectedStopReq,         setCAMSelectedStopReq      ] = useState(false)
  const [DataResponse,               setDataResponse            ] = useState('')
  const [IDgetted,                   setIDgetted                ] = useState({ ID: [] })
  const [BTNactivated,               setBTNactivated            ] = useState(false)
  const [NkabSearchResult,           setNkabSearchResult        ] = useState()
  const [NkabSearch,                 setNkabSearch              ] = useState('')
  var   [i,                          setI                       ] = useState(0)
  
  const IDCounted = IDgetted.ID.length



  const SliceNbr1Handler = (e) => {
    const { value } = e.target
    setSliceNbr1(value)
    setFilted(true)
    setCISelectedStopReq(false)
  }

  const SliceNbr2Handler = (e) => {
    const { value } = e.target
    setSliceNbr2(value)
    setFilted(true)
    setCISelectedStopReq(false)
  }

  useEffect(() => {

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        setGetUser(data)
      } catch (error) { }
    }
    fetchDada12()

  }, [])

  const handleSubmit = () => {
    setNkabStart(true)
  }

  const handleSubmit2 = () => {
    setNkabStart2(true)
  }

  const CISelectedHandler = () => {

    setCICountryChecked('nkab-checked-hover')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Côte d'Ivoire")

  }

  const BURKSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('nkab-checked-hover')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Burkina Fasso")
  }

  const GUINEESelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('nkab-checked-hover')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Guinée")
  }

  const MALISelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('nkab-checked-hover')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Mali")
  }

  const SENSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('nkab-checked-hover')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Sénégal")
  }

  const TOGOSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('nkab-checked-hover')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Togo")
  }

  const NIGERSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('nkab-checked-hover')
    setCAMCountryChecked('')

    setSelected("Niger")
  }
  const CAMSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('nkab-checked-hover')

    setSelected("Cameroun")
  }
  const BENINSelectedHandler = () => {
    setCICountryChecked('')
    setSENCountryChecked('')
    setMALICountryChecked('')
    setTOGOCountryChecked('')
    setBENINCountryChecked('nkab-checked-hover')
    setGUINEECountryChecked('')
    setBURKCountryChecked('')
    setNIGERCountryChecked('')
    setCAMCountryChecked('')

    setSelected("Benin")
  }

  useEffect(() => {

    const fetchDada12 = async () => {
      if (Selected === "Côte d'Ivoire" && !CISelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Côte d'Ivoire"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setCISelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, CISelectedStopReq, NkabStart, GetUser, Selected, Text1, Text2, Filted])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Burkina Fasso" && !BURKSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Burkina Fasso"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setBURKSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, BURKSelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Guinée" && !GUINEESelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Guinée"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setGUINEESelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, GUINEESelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Mali" && !MALISelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Mali"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setMALISelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, GUINEESelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2, MALISelectedStopReq])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Sénégal" && !SENSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Sénégal"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setSENSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, GUINEESelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2, SENSelectedStopReq])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Togo" && !TOGOSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Togo"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setTOGOSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, TOGOSelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Niger" && !NIGERSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Niger"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setNIGERSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, NIGERSelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Cameroun" && !CAMSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Cameroun"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setCAMSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, CAMSelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])


  useEffect(() => {

    const fetchDada12 = async () => {

      if (Selected === "Benin" && !BENINSelectedStopReq) {
        try {
          const CountryData = GetUser.filter((obj) => {
            return obj.countryName === "Benin"
          })

          if (CountryData && Filted) {

            var items = CountryData.slice(sliceNbr1, sliceNbr2)

            setSelectedLength(items.length)

            if (NkabStart && items[i]) {
              setLoading(true)

              const email = items[i].email
              axios
                .post('/api/nkab-info-pub',
                  {
                    email,
                    txt1: Text1,
                    txt2: Text2,
                    img1: "nkab-baniere1",
                    img2: "nkab-baniere2"
                  })
                .then(
                  (response) => {
                    if (response.data.message === "Message pub envoyé avec succès! ") {
                      i++
                      setI(i++)
                      if (i === (items.length - 1)) {
                        setNkabStart(false)
                        setLoading(false)
                        setDataResponse("Message pub envoyé avec succès!")
                        setBTNactivated(false)
                        setText1('')
                        setText2('')
                        setBENINSelectedStopReq(true)
                      }
                    }
                  },
                  (error) => {
                    console.log(error)
                  },
                )
            }
          } else {
            setSelectedLength(CountryData.length)
          }
        } catch (error) { }
      }
    }
    fetchDada12()

  }, [i, sliceNbr1, sliceNbr2, BENINSelectedStopReq, NkabStart, Filted, GetUser, Selected, Text1, Text2])



  const ALLSelectedHandler = () => {

    if (Filted) {
      const _data = GetUser
      const items = _data.slice(sliceNbr1, sliceNbr2)
      setGetUser(items)
      setSelectedLength(items.length)
    } else {
      setSelectedLength(GetUser.length)
    }
    setSelected("Tous")
  }

  const handleSearchChange2 = (e) => {
    const { value } = e.target
    if (GetUser.length > 0 && value) {
      const SearchUser = GetUser.filter((obj) => {
        return obj.email.includes(value)
      })

      setNkabSearchResult(SearchUser)
    }

  }

  const handleSearchChange3 = (e) => {
    const { value } = e.target
    if (GetUser.length > 0 && value) {
      const SearchUser = GetUser.filter((obj) => {
        return obj.telNumber.includes(value)
      })

      setNkabSearchResult(SearchUser)
    }

  }

  useEffect(() => {
    if (NkabSearchResult) {
      setNkabSearch(NkabSearchResult)
    }
  }, [NkabSearchResult, NkabSearch])

  const handleChange = ({ target: { value, checked } }) => {

    var { ID } = IDgetted

    if (checked) {
      setIDgetted({ ID: [...ID, value] })
    } else {
      setIDgetted({
        ID: ID.filter((e) => e !== value),
        response: ID.filter((e) => e !== value),
      })
    }
  }

  const ActivatedHandler = () => {
    setBTNactivated(true)
  }

  const UnactivatedHandler = () => {
    setBTNactivated(false)
  }

  useEffect(() => {
    if (GetUser.length > 0 && IDgetted.ID.length > 0 && BTNactivated && NkabStart2) {

      const DataGetted = GetUser.filter((items) => {
        return items.ID === Number(IDgetted.ID[i])
      })

      if (DataGetted[0]) {
        setLoading(true)

        axios
          .post('/api/nkab-info-pub',
            {
              email: DataGetted[0].email,
              txt1: Text1,
              txt2: Text2,
              img1: "nkab-baniere1",
              img2: "nkab-baniere2"
            })
          .then(
            (response) => {
              if (response.data.message === "Message pub envoyé avec succès! ") {
                i++
                setI(i++)

                if (i === IDCounted) {
                  setNkabStart2(false)
                  setLoading(false)
                  setBTNactivated(false)
                  setText1('')
                  setText2('')
                }
              }
            },
            (error) => {
              console.log(error)
            },
          )
      }

    }
  }, [i, BTNactivated, GetUser, IDCounted, IDgetted, NkabStart2, Text1, Text2])



  useEffect(() => {

    if (GetUser && Selected === "Tous" && NkabStart) {

      const GetCoubtryUser = GetUser.filter((items) => {
        return items.ID === Number(i)
      })

      if (GetCoubtryUser[0] && NkabStart) {
        setLoading(true)

        const email = GetCoubtryUser[0].email
        axios
          .post('/api/nkab-info-pub',
            {
              email,
              txt1: Text1,
              txt2: Text2,
              img1: "nkab-baniere1",
              img2: "nkab-baniere2"
            })
          .then(
            (response) => {
              if (response.data.message === "Message pub envoyé avec succès! ") {
                i++
                setI(i++)
                if (i === GetCoubtryUser.length) {
                  setNkabStart(false)
                  setLoading(false)
                  setBTNactivated(false)
                  setText1('')
                  setText2('')
                }
              }
            },
            (error) => {
              console.log(error)
            },
          )
      }

    }
  }, [i, GetUser, NkabStart, Selected, Text1, Text2])

  const loadMsg = "Veuillez patienter un moment..."

  return (
    <div className="container">
      <div className="row pt-5">
        <div className="col-md-3"></div>

        <div className="col-md-5 forgot-password">
          <div className="card">
            <div className="card-body">
              <div className="text-center">
                <h1> <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ fontSize: 40, color: `${'#7a9fc7'}` }} /> </h1>
                <h2 className="text-center">INFO EMAIL</h2>
                <h5>Option de ciblage {'=>'} {!BTNactivated ? <span className='pointer nkab-ciblage-btn text-danger' onClick={ActivatedHandler}>Désactivée</span> : <span className='pointer nkab-ciblage-btn text-success' onClick={UnactivatedHandler}>Activée</span>} </h5>
                {!BTNactivated ? <h5>Option d'envoi par tri : <strong className='text-danger'>{Selected}</strong></h5> : null}<br />
                {!BTNactivated ? <>
                  <div className="alert alert-danger" role="alert"> Vous devez sélectionner un pays avant d'effectuer un tri! </div>
                  <div className='d-flex justify-content-around mb-4'>
                    <div><img src="../../images/ci_logo.png" width="25" height="25" alt="Côte d'Ivoire" className={`${CICountryChecked} pointer nkab-hover`} onClick={CISelectedHandler} /></div>
                    <div><img src="../../images/burk_logo.png" width="25" height="25" alt="Burkina Fasso" className={`${BURKCountryChecked} pointer nkab-hover`} onClick={BURKSelectedHandler} /></div>
                    <div><img src="../../images/guinee_logo.png" width="25" height="25" alt="Guinée" className={`${GUINEECountryChecked} pointer nkab-hover`} onClick={GUINEESelectedHandler} /></div>
                    <div><img src="../../images/mali_logo.png" width="25" height="25" alt="Mali" className={`${MALICountryChecked} pointer nkab-hover`} onClick={MALISelectedHandler} /></div>
                    <div><img src="../../images/sen_logo.png" width="25" height="25" alt="Sénégal" className={`${SENCountryChecked} pointer nkab-hover`} onClick={SENSelectedHandler} /></div>
                    <div><img src="../../images/togo_logo.png" width="25" height="25" alt="Togo" className={`${TOGOCountryChecked} pointer nkab-hover`} onClick={TOGOSelectedHandler} /></div>
                    <div><img src="../../images/niger_logo.png" width="25" height="25" alt="Niger" className={`${NIGERCountryChecked} pointer nkab-hover`} onClick={NIGERSelectedHandler} /></div>
                    <div><img src="../../images/cam_logo.png" width="25" height="25" alt="Cameroun" className={`${CAMCountryChecked} pointer nkab-hover`} onClick={CAMSelectedHandler} /></div>
                    <div><img src="../../images/benin_logo.png" width="25" height="25" alt="Benin" className={`${BENINCountryChecked} pointer nkab-hover`} onClick={BENINSelectedHandler} /></div>
                    <div><strong className='pointer p-2' style={{ backgroundColor: "grey", borderRadius: "50px" }} onClick={ALLSelectedHandler}>Tous</strong></div>
                  </div>
                  <h5 className="text-center">[{sliceNbr1} .. {sliceNbr2}]</h5>

                  {
                    CICountryChecked || BURKCountryChecked || GUINEECountryChecked || MALICountryChecked || SENCountryChecked || TOGOCountryChecked || NIGERCountryChecked || CAMCountryChecked || BENINCountryChecked === 'nkab-checked-hover' ?
                      <div className='d-flex justify-content-center mb-3'>
                        <input
                          style={{ width: "100px", height: "30px" }}
                          className="mr-2 ml-2"
                          type="number"
                          name="number1"
                          placeholder='int 1'
                          onChange={SliceNbr1Handler}
                        />
                        <input
                          style={{ width: "100px", height: "30px" }}
                          className="mr-2 ml-2"
                          type="number"
                          name="number2"
                          placeholder='int 2'
                          onChange={SliceNbr2Handler}
                        />
                      </div> :
                      <div className='d-flex justify-content-center mb-3'>
                        <input
                          style={{ width: "100px", height: "30px" }}
                          className="mr-2 ml-2"
                          type="number"
                          name="number1"
                          placeholder='int 1'
                          disabled
                        />
                        <input
                          style={{ width: "100px", height: "30px" }}
                          className="mr-2 ml-2"
                          type="number"
                          name="number2"
                          placeholder='int 2'
                          disabled
                        />
                      </div>
                  }
                </> :
                  <div className="row">
                    <div className='row'>
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              name='search2'
                              className="form-control"
                              placeholder='Recherche par email...'
                              onChange={handleSearchChange2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-2">
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              type="search"
                              name='search3'
                              className="form-control"
                              placeholder='Recherche par téléphone...'
                              onChange={handleSearchChange3}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mailing-body-table">
                      <table className='mailing-responsive-table'>
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>C.P</th>
                            <th>Téléphone</th>
                            <th>Cocher</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            NkabSearch.length === 0 ? GetUser.map((userSearch) => (
                              <tr key={userSearch.ID} style={{ backgroundColor: "#010814" }}>
                                <td>{userSearch.email}</td>
                                <td>({userSearch.country})</td>
                                <td>{userSearch.telNumber}</td>
                                <td className='nkab-notif-trash'>
                                  <input
                                    type="checkbox"
                                    name="email"
                                    value={`${userSearch.ID}`}
                                    onChange={handleChange}
                                  />
                                </td>
                              </tr>
                            )) :
                              !NkabSearch ? null : NkabSearch.length > 0 ? NkabSearch.map((userSearch) => (
                                <tr key={userSearch.ID} style={{ backgroundColor: "#010814" }}>
                                  <td>{userSearch.email}</td>
                                  <td>({userSearch.country})</td>
                                  <td>{userSearch.telNumber}</td>
                                  <td className='nkab-notif-trash'>
                                    <input
                                      type="checkbox"
                                      name="email"
                                      value={`${userSearch.ID}`}
                                      onChange={handleChange}
                                    />
                                  </td>
                                </tr>
                              )) : null
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>}<br />
                <div className="panel-body">
                  <div className="form-group">
                    <div className="md-form amber-textarea active-amber-textarea-2 mt-2">
                      <textarea id="form24" className="temoignage-textarea" placeholder="OBJET DU MESSAGE" rows="1" onChange={(e) => setText1(e.target.value)} required />
                    </div>
                    <div className="md-form amber-textarea active-amber-textarea-2 mt-2">
                      <textarea id="form24" className="temoignage-textarea" placeholder='Saisissez le contenu message ici...' rows="6" onChange={(e) => setText2(e.target.value)} required />
                    </div>
                  </div>
                  <div className="form-group">
                    {
                      loading === true ?
                        <div className="input-group">
                          <span className="w-100 secondary-span2 btn-lg">
                            <LoadingBox loadMsg={loadMsg} /> {i === 0 ? null : i}
                          </span>
                        </div> :
                        <>
                          {
                            !BTNactivated ? <>
                              {
                                DataResponse === "Message pub envoyé avec succès!" ?
                                  <input
                                    style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}`, }}
                                    name="recover-submit"
                                    className="btn btn-lg btn-primary btn-block bolder text-success"
                                    value={`${DataResponse}`}
                                    type="submit"
                                  /> :
                                  <input
                                    style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}`, }}
                                    name="recover-submit"
                                    className="btn btn-lg btn-primary btn-block bolder"
                                    value={`J'envoie une info vers ${SelectedLength} clients`}
                                    type="submit"
                                    onClick={handleSubmit}
                                  />
                              }
                            </> :
                              <input
                                style={{ backgroundColor: `${'#c7b301'}`, border: `${'none'}`, }}
                                name="recover-submit"
                                className="btn btn-lg btn-primary btn-block bolder"
                                value={`J'envoie une info à ${IDCounted} client(s)`}
                                type="submit"
                                onClick={handleSubmit2}
                              />
                          }
                        </>
                    }
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3"></div>
      </div>
    </div>
  )
}

export default AdminSendingInfo