import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  showErrMessage,
  showSuccessMessage,
} from '../components/utils/notification/Notification'
import { isEmail } from '../components/utils/validation/Validation'
import { Avatar, Box, Button, Container, createTheme, CssBaseline, Grid, Link, TextField, ThemeProvider, Typography } from '@mui/material'

const defaultTheme = createTheme();

const initialState = {
  email: '',
  err: '',
  success: '',
}

export default function ForgotPassword() {

  const [data,            setData         ] = useState(initialState)
  const [GetUser,         setGetUser      ] = useState([])
  const [MainEmail,       setMainEmail    ] = useState()
  const [IdValided,       setIdValided    ] = useState('')
  const [EmailValided,    setEmailValided ] = useState('')
  const [Success,         setSuccess      ] = useState('')
  const { email,           err            } = data


  const [NkabSpinner,  setNkabSpinner] = useState(false)

  const STATUS = { STARTED: 'Traitement en cours...', }
  
  const INITIAL_COUNT1 = 300

  const [secondsRemaining1, setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
  const [status1, setStatus1 ] = useState(STATUS.STOPPED)
  
  useEffect(()=>{
    setStatus1(STATUS.STARTED)
  },[STATUS.STARTED])

  useInterval(
    () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
  
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}
  var [themeMode, setThemeMode] = useState()
  
  let ThemeMode = localStorage.getItem("ThemeMode")
  
  useEffect(()=>{
    
    if(secondsRemaining1 > 0){
      setThemeMode(ThemeMode)
    }
  
  },[ThemeMode, secondsRemaining1])


  useEffect(()=>{

    const fetchDada12 = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        setGetUser(data)
      } catch (error) {}
    }
    fetchDada12()

  },[])



  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setData({ ...data, [name]: value, err: '', success: '' })
  }

  useEffect(()=>{

    if(email && GetUser){
      const SearchUnicEmail = GetUser.filter((obj)=>{
        return obj.email.includes(email)
      })
      setMainEmail(SearchUnicEmail)
    }

  },[email, GetUser])

  useEffect(()=>{

    if(MainEmail){
      if(MainEmail[0]){
        setIdValided(MainEmail[0].ID)
        setEmailValided(MainEmail[0].email)
      }
    }
    
  },[MainEmail])

  const handleForgotPassword = async () => {
    if (!isEmail(email))
      return setData({ ...data, err: 'Invalid emails', success: '' })

    try {
      const res = await axios.post(
        '/api/forgot_password',
        { email },
      )
      setSuccess(res.data.message)
      localStorage.setItem('email', email)
    } catch (err) {
      console.log(err)
    }
  }

  const handleLogin = async (event) => {
    
    setNkabSpinner(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget);

    try {
      const res = await axios.post('/api/login', {
        email: (data.get('email')).toLowerCase(),
        password: data.get('password'),
      })
      if (res) {

        localStorage.setItem('userID',        res.data.user.ID)
        localStorage.setItem('userName',      res.data.user.name)
        localStorage.setItem('userLastName',  res.data.user.lastName)
        localStorage.setItem('userEmail',     res.data.user.email)
        localStorage.setItem('userPhone',     res.data.user.telNumber)
        localStorage.setItem('userStatus',    res.data.msg)
        localStorage.setItem('token',         res.data.token)
        localStorage.setItem('SenderCountry', res.data.user.countryName)
        localStorage.setItem('Country',       res.data.user.country)
        localStorage.setItem('role',          res.data.user.role)
        localStorage.setItem('firstLogin',    true)
        localStorage.setItem("isRActive",     false)
        localStorage.setItem("isSeen",        false)
        localStorage.setItem("isMarchandActive", true)

      }
    } catch (err) {
      setNkabSpinner(false)
     }
  }

  return (
    <div className='pt-5 mb-5 pb-5'>
      <div className='row'>
        <div className='col-md-2'></div>
        <div className='col-md-5 d-flex justify-content-around'>
          <div className='d-flex justify-content-center'>
            <img className='login-currency-img' src="../images/interrogation2.png" width="700" alt="login nkab" />
          </div>
        </div>
        <div className='col-md-3 final-seesion d-flex justify-content-around'>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" className='css-binzgt'>
              <CssBaseline />
              <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                <Avatar sx={{ m: 1, bgcolor: '#c7b301' }}> <LockOutlinedIcon /> </Avatar>
                <Typography sx={{fontSize: "17px", color:`${themeMode === "true" ? "#90d9ff" : ""}`}}> 
                  <h3 className="text-center">Mot de passe oublié ?</h3>
                  <p>Renseignez votre adresse de vérification.</p>
                </Typography>
                {err && showErrMessage(err)}
                {Success && showSuccessMessage(Success)}
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Saissez votre email déjà enregistré"
                    name="email"
                    autoComplete="email"
                    onChange={handleChangeInput}
                    autoFocus
                  />
                  {
                    !IdValided && !EmailValided && !email ?
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled
                    >
                        { !NkabSpinner?"Envoyez la vérification":<div className="nkab-spinner"></div> }
                      
                    </Button>:
                    IdValided && (EmailValided === !email) ?
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2}}
                      disabled
                    >
                        { !NkabSpinner?"Envoyez la vérification":<div className="nkab-spinner"></div> }
                      
                    </Button>:
                    IdValided && (EmailValided === email) ?
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                      onClick={handleForgotPassword}
                    >
                        { !NkabSpinner?"Envoyez la vérification":<div className="nkab-spinner"></div> }
                      
                    </Button>:
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled
                  >
                    Envoyez la vérification
                  </Button>
                  }
                  <Grid container>
                    <Grid item xs> <Link href="/login" variant="body2" sx={{color:"#c7b301"}}> Se connecter </Link> </Grid>
                    <Grid item> <Link href="/singup" variant="body2" sx={{color:"#c7b301"}}> {"Pas de compte ? S'inscrire"} </Link> </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </div>
        <div className='col-md-2'></div>
      </div>
    </div>
  )
}
