import React from 'react'

const PaymentWays = ({themeMode}) => {
  return (
    <div>
        <div className="_our-header">
            <h1 className="header__title" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Avec vous partout où vous vous trouvez</h1>
            <h2 className="header__subtitle" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Nos monnaies numériques | Les pays où nous sommes représentés | Nos moyens de paiement</h2>
          </div>

          <div className="_our-cards">

            <div className="_our-card [ is-collapsed ]">
              <div className={`${themeMode === "true" ? "card__inner card__inner-t [ js-expander ]" : "card__inner [ js-expander ]"}`}>
                <div><div className='card_subtitle' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Nos monnaies numériques</div></div>
                <div style={{ paddingBottom: '5px' }}>
                  <span><img src="../../images/btc.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/usdt.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/bnb.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/doge.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/ltc.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/pm.png" alt="" className="card__inner-img" /> </span>
                </div>
                <div>
                  <span><img src="../../images/payeer.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/shiba.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/solana.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/tron.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/chainlink.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/ripple.png" alt="" className="card__inner-img" /> </span>
                </div>
                <div>
                  <span><img src="../../images/polygonmatic.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/pokadot.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/cardano.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/busd.png" alt="" className="card__inner-img" /> </span>
                </div>
              </div>
            </div>

            <div className=" _our-card [ is-collapsed ] ">
              <div className={`${themeMode === "true" ? "card__inner card__inner-t [ js-expander ]" : "card__inner [ js-expander ]"}`}>
                <div><div className='card_subtitle' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Les pays où nous intervenons</div></div>
                <div style={{ paddingBottom: '5px' }}>
                  <span><img src="../../images/ci_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/sen_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/mali_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/benin_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/togo_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/guinee_logo.png" alt="" className="card__inner-img" /> </span>
                </div>
                <div>
                  <span><img src="../../images/cam_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/burk_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/niger_logo.png" alt="" className="card__inner-img" /> </span>
                </div>
              </div>
            </div>

            <div className=" _our-card [ is-collapsed ] ">
              <div className={`${themeMode === "true" ? "card__inner card__inner-t [ js-expander ]" : "card__inner [ js-expander ]"}`}>
                <div><div className='card_subtitle' style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>Nos moyens de paiement disponibles</div></div>
                <div style={{ paddingBottom: '5px' }}>
                  <span><img src="../../images/om.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/momo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/flooz.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/wave.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/emoney.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/fm.png" alt="" className="card__inner-img" /> </span>
                </div>
                <div>
                  <span><img src="../../images/tm.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/visamastercard_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/wari_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/wu_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/ria_logo.png" alt="" className="card__inner-img" /> </span>
                  <span><img src="../../images/mg_logo.png" alt="" className="card__inner-img" /> </span>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default PaymentWays