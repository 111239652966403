import React, { useEffect, useRef, useState } from 'react'
import ImageSlider from './ImageSlider';


const images = [
  '../../images/cryptocurrency_all5.png?text=Image+1',
  '../../images/cryptocurrency_btc.jpg?text=Image+2',
  '../../images/cryptocurrency_btc2.jpg?text=Image+3',
  '../../images/cryptocurrency_btc3.jpg?text=Image+4',
  '../../images/cryptocurrency_promo.jpg?text=Image+5',
  '../../images/cryptocurrency_btc5.jpg?text=Image+6',
  '../../images/cryptocurrency_btc4.jpg?text=Image+7',
  '../../images/cryptocurrency_all.png?text=Image+8',
];

const HomeVariations = ({RInterface, RI, BInterface, BI, TInterface, TI, themeMode}) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change l'image toutes les 3 secondes

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.style.transition = 'transform 0.5s ease-in-out';
      slideRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
    }
  }, [currentIndex]);

    const STATUS = { STARTED: 'Traitement en cours...', }
    
      const INITIAL_COUNT1 = 300
    
      const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
      const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
    
      useEffect(()=>{
        setStatus1(STATUS.STARTED)
      },[STATUS.STARTED])
  
    useInterval(
      () => {
      if (secondsRemaining1 > 0) {
          setSecondsRemaining1(secondsRemaining1 - 1)
      } else {
          setStatus1(STATUS.STOPPED)
      }
      },
  
      status1 === STATUS.STARTED ? 1000 : null,
      // passing null stops the interval
  )
  
  function useInterval(callback, delay) {
      const savedCallback = useRef()
  
      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }


  return (

    <div className='mt-3 mb-5 nkab-pro-home-img'>
      <div className='text-center'>
        {
          RInterface || RI === "RInterface" ? <div>
            <h1 style={{fontSize:"35px"}}><strong style={{color:"#78bee4"}}>Achetez des cryptos après </strong><strong className='color-or'>rechargement</strong>!</h1>
          </div>:BInterface || BI === "BInterface" ? <div>
            <h1 style={{fontSize:"35px"}}><strong style={{color:"#78bee4"}}>Acheter des</strong> <strong className='color-or'>cryptos</strong>!</h1>
          </div>:TInterface || TI === "TInterface" ? <div>
            <h1 style={{fontSize:"35px"}}><strong style={{color:"#78bee4"}}>Transférez de l'argent à</strong> <strong className='color-or'>vos proches</strong> <strong style={{color:"#78bee4"}}>dès maintenant!</strong></h1>
          </div>:null
        }
      </div>
      <ImageSlider images={images}/>
    </div>
  )
}

export default HomeVariations