import axios from 'axios'
import React, { useEffect, useState } from 'react'

const OpInterfaceComponent = ({themeMode}) => {

  const userID                 = localStorage.getItem('userID')
  const [OpList, setOpList] = useState([])

  useEffect(() => {

    const fetchDada = async () => {
      try {
        const { data } = await axios.get(
          '/api/v1/all-p-op',
        )

        if(data && userID){
          const dataGetted = data.filter((obj) => {
            return obj.userId === Number(userID)
          })

          if(dataGetted){
            const _dataGetted = dataGetted
            const items = _dataGetted.slice(0, 30)
            setOpList(items)
          }
        }

      } catch (error) {
        console.log(error)
      }
    }

    fetchDada()
  }, [userID, OpList])


  return (
    <div>

<div className="__op-body-table">
        <table className='__op-responsive-table'>
          <thead style={{ fontSize: `${'11px'}` }}>
            <tr>
              <th scope="col">Identifiant</th>
              <th scope="col">Contact</th>
              <th scope="col">Opération</th>
              <th scope="col">Type</th>
              <th scope="col">Montant</th>
              <th scope="col">Date</th>
              <th scope="col">Statut</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: `${'11px'}` }}>
            {!OpList ? null : (
              OpList.map((item) => (
                <tr key={item.ID}>
                  <th scope="row"> {item.operationUId} </th>
                  <td data-title="Contact"> {item.phone} </td>
                  <td data-title="Opération"> {item.options} </td>
                  <td data-title="Coin"> {item.moneyName}</td>
                  <td data-title="Montant"> {`${Number(item.montant)} XOF`} </td>
                  <td data-title="Date">{item.updatedAt.slice(0, 10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</td>
                  <td data-title="Statut" style={{ fontSize: `${'12px'}` }}>
                    {item.status === 'failed' ? <span className='text-danger'>échouée</span> : 
                    item.status === 'successful' ? <span className='text-success'>validée</span> : null}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OpInterfaceComponent