import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Avatar, Button } from '@mui/material'


const Comments = () => {

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)

    const [Asking, setAsking] = useState(false)
    const [SeeResponse, setSeeResponse] = useState(false)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])

    const userID = localStorage.getItem('userID')
    const userName = localStorage.getItem('userName')
    const userLastName = localStorage.getItem('userLastName')
  
    const [witnessText,                   setWitnessText       ] = useState('')
    const [MessageID,                     setMessageID         ] = useState()
    const [GetUser,                       setGetUser           ] = useState([])
    const [User,                          setUser              ] = useState([])
    const [UserStopReq,                   setUserStopReq       ] = useState(false)

    const [ownWitness,                    setOwnWitness        ] = useState([])
    const [ownWitnessStopReq,             setOwnWitnessStopReq ] = useState(false)

    //Cette plateforme est simple d'utilisation. Bravo à toute l'équipe NKAB.
    const [CommentActive,                 setCommentActive     ] = useState(true)
    const [UserRole,                      setUserRole          ] = useState(0)
    
    useEffect(()=>{
  
      const fetchDada12 = async () => {
        if(!UserStopReq){
            try {
              const { data } = await axios.get(
                '/api/v1/get-users',
              )
              setGetUser(data)
              if(GetUser.length > 0){
                setUserStopReq(true)
              }
            } catch (error) {}
        }
      }
      fetchDada12()
  
    },[GetUser, UserStopReq])

    
  
    useEffect(()=>{
  
      if(GetUser && userID){
        const userGetted = GetUser.filter((obj) => {
          return obj.ID === Number(userID)
        })
        setUser(userGetted[0])
      }
  
    },[GetUser, userID, User])


    const handleWitnessText = (e) => {
      const { value } = e.target
      setWitnessText(value)
    }

    const handleSubmit = () => {
      if(CommentActive && userID && userName && userLastName && witnessText !== ''){
        axios
          .post('/api/v1/create-witnesses',
          {
            posterId:Number(userID),
            posterLastName:userLastName,
            posterName:userName,
            witnessText,
            wTxtResponse : 0,
            createdAt: new Date(),
            updatedAt: new Date(),
          })
          .then(
            (response) => {
              if(response){
                setWitnessText('')
                setOwnWitnessStopReq(false)
                setCommentActive(false)
                setWitnessText('')
              }
            },
            (error) => {},
          )
      }
    }
    
    useEffect(()=>{

      const fetchWitnessDada = async () => {
        if(!ownWitnessStopReq){
            try {
              const { data } = await axios.get(
                '/api/v1/get-witnesses',
              )
              if(data){
                const _data = data
                const items = _data.slice(0, 20)
                if(items.length > 0){
                  setOwnWitness(items)
                  setWitnessText('')
                  setOwnWitnessStopReq(true)
                }
              }
            } catch (error) {}
        }
      }

      fetchWitnessDada()

    },[ownWitness, ownWitnessStopReq])

  const askingHanlder1 = (index) =>{
    setMessageID(index)
    setAsking(true)
  }


  const askingHanlder = async (id) =>{

    if(id && CommentActive && userID && userName && userLastName && witnessText !== '' && MessageID){
    
      setAsking(false)

      axios
        .post('/api/v1/create-witnesses',
        {
          posterId:MessageID,
          posterLastName:userLastName,
          posterName:`${userName} a répondu à @${id}`,
          witnessText,
          wTxtResponse : 1,
          createdAt: new Date(),
          updatedAt: new Date(),
        })
        .then(
          (response) => {
            if(response){
              setWitnessText('')
              setOwnWitnessStopReq(false)
              setCommentActive(false)
              setWitnessText('')
            }
          },
          (error) => {},
        )
    }

  }

  const seeRespoonse = () => {
    setSeeResponse(true)
  }

  useEffect(()=>{
    if(User){
      setUserRole(User.role)
    }
  },[User])

  const [StartWeet, setStartWeet] = useState(false)

  function startChatHandler(){
    window.location.replace("https://wa.me/message/BNJDHCQNNP52J1")
  }

  function startWeetHandler(){
    setStartWeet(true)
  }


  return (
    <div className='row'>
        {
          !Asking?
          <div className={`${themeMode === "true" ? "comment_root comment_root-t" : "comment_root"}`}>
            {
              !StartWeet?<>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, bgcolor: '#c7b301' }}
                onClick={startChatHandler}
              ><strong style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}>J'ai une préoccupation</strong></Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, bgcolor: '#c7b301' }}
                onClick={startWeetHandler}
              ><strong style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}>Je veux témoigner</strong></Button>
              </>:
              <div className="comment_entry">
                <textarea spellcheck="false" placeholder='Entrez votre témoignage...' id="comment_msg" onChange={handleWitnessText}>{witnessText}</textarea>
                <button className="comment_send" onClick={handleSubmit}><FontAwesomeIcon icon={faPaperPlane} /></button>
              </div>
            }
          </div>:null
        }

        <div className='nkab-comments-content'>
            {
            ownWitness.length === 0 ? 
            <>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#93b6cc" highlightColor="#0a146e">
              <p><Skeleton count={3} /></p>
            </SkeletonTheme>
            </>:
            ownWitness.map((item, index) => 
                (
                  <>
                  {
                    item.wTxtResponse === 1 ? null:
                    <div className={`${themeMode === "true" ? "nkab-comments-content-items nkab-comments-content-items-t" : "nkab-comments-content-items"}`} key={index}>
                        <div className='d-flex'>
                          <Avatar className='nkab-comments-item-avatar'/>
                          <div>
                            <span><strong style={{fontSize:'12px', color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>NKAB Exchange</strong></span>
                            <span className='p-2'style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>.</span>
                            <span style={{fontSize:'10px', fontStyle:'italic', color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{item.updatedAt.slice(0,10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</span><br/>
                            <h5 style={{fontSize:'12px', fontStyle:'italic', color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>@{item.posterName}</h5>
                          </div>
                        </div>
                        <hr style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}/>
                        <div className='p-2'>
                          <p style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>{item.witnessText}</p>
                          {
                            ownWitness.map((item2, index2) =>(
                              <div key={index2}>
                                {
                                  item.ID === Number(item2.posterId)?<>
                                  {
                                    SeeResponse?
                                    <><h5 style={{fontSize:'12px', fontStyle:'italic', color:`${themeMode === "true" ? "#c7b301" : "#0a146e"}`}}>@{item2.posterName}</h5>
                                    <p style={{color:`${themeMode === "true" ? "#c7b301" : ""}`}}>{item2.witnessText}</p></>:
                                    <p style={{color:`${themeMode === "true" ? "#c7b301" : ""}`}}>{index2+1} réponse(s) <span onClick={seeRespoonse} className='nkab-witness-asker' style={{color:`${themeMode === "true" ? "#90d9ff" : "#0a146e"}`}}>voir</span></p>
                                  }
                                  </>:null
                                }
                              </div>
                            ))
                          }
                          
                          {
                            !Asking && UserRole === 1 ?
                            <div className='d-flex justify-content-between'>
                              <div></div>
                              <div><span className='nkab-witness-asker' onClick={()=>askingHanlder1(item.ID)}>Répondre</span></div>
                            </div>
                            :null
                          }
                          
                          {
                            !Asking?null:
                            Asking && MessageID === item.ID ?
                            <div className="comment_entry">
                              <textarea spellcheck="false" placeholder='Entrez votre témoignage...' id="comment_msg" onChange={handleWitnessText}>{witnessText}</textarea>
                              <button className="comment_send" onClick={() => askingHanlder(item.posterName)}><FontAwesomeIcon icon={faPaperPlane} /></button>
                            </div>:null
                          }
                        </div>
                    </div>
                  }
                  </>
                  )
                )
            }
        </div>
    </div>
  )
}

export default Comments