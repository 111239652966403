import React, { useEffect, useRef, useState } from 'react'
import { faArrowRight, faHandshake, faUserFriends} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import PreviewIcon from '@mui/icons-material/Preview';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Button from '@mui/material/Button';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReadMoreIcon from '@mui/icons-material/ReadMore';

// Initialise pdfmake avec les polices de caractères
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const AdminStat = (props) => {

  var _ProWallet = localStorage.getItem('_ProWallet')

  if (_ProWallet === 'true') {
    props.history.push('/nkab-pro')
  }

  const Logged = localStorage.getItem('userStatus')
  const role = localStorage.getItem('role')

  if (Logged === 'Logout') {
    props.history.push('/login')
  }

  if (Number(role) !== 1) {
    props.history.push('/')
  }

  const STATUS2 = {
    STARTED2: 'Traitement en cours...',
    }

  const INITIAL_COUNT2 = 300
  const [secondsRemaining2, setSecondsRemaining2] = useState(INITIAL_COUNT2)
  const [status2, setStatus2] = useState(STATUS2.STOPPED2)

  useEffect(()=>{
    setStatus2(STATUS2.STARTED2)
  },[STATUS2.STARTED2])

  useInterval(
    () => {
    if (secondsRemaining2 > 0) {
        setSecondsRemaining2(secondsRemaining2 - 1)
    } else {
        setStatus2(STATUS2.STOPPED2)
    }
    },
  
    status2 === STATUS2.STARTED2 ? 1000 : null,
    // passing null stops the interval
  )

  function useInterval(callback, delay) {
      const savedCallback = useRef()
  
      // Remember the latest callback.
      useEffect(() => {
      savedCallback.current = callback
      }, [callback])
  
  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
  }

  const [GetUser, setGetUser] = useState([])

  const [Operation, setOperation] = useState([])

  const [GlobalBuy, setGlobalBuy] = useState([])
  const [GlobalSell, setGlobalSell] = useState([])

  const [GlobalBuyWave, setGlobalBuyWave] = useState([])
  const [GlobalSellWave, setGlobalSellWave] = useState([])

  const [OrdersBuy, setOrdersBuy] = useState([])
  const [OrdersSell, setOrdersSell] = useState([])

  const [OrdersBuyWave, setOrdersBuyWave] = useState([])
  const [OrdersSellWave, setOrdersSellWave] = useState([])

  const [_24TotalBuy, set_24TotalBuy] = useState([])
  const [_24TotalSell, set_24TotalSell] = useState([])

  const [_24TotalBuyWave, set_24TotalBuyWave] = useState([])
  const [_24TotalSellWave, set_24TotalSellWave] = useState([])

  const [_24TotalRateBuy, set_24TotalRateBuy] = useState([])
  const [_24TotalRateSell, set_24TotalRateSell] = useState([])

  const [_24TotalRateBuyWave, set_24TotalRateBuyWave] = useState([])
  const [_24TotalRateSellWave, set_24TotalRateSellWave] = useState([])

  const [USDTBuyLength, setUSDTBuyLength] = useState([])
  const [BNBBuyLength, setBNBBuyLength] = useState([])
  const [BTCBuyLength, setBTCBuyLength] = useState([])
  const [TRONBuyLength, setTRONBuyLength] = useState([]) 
  const [LTCBuyLength, setLTCBuyLength] = useState([])
  const [DOGEBuyLength, setDOGEBuyLength] = useState([])
  const [PAYEERBuyLength, setPAYEERBuyLength] = useState([])
  const [PMBuyLength, setPMBuyLength] = useState([])
  const [BUSDBuyLength, setBUSDBuyLength] = useState([])
  const [SHIBABuyLength, setSHIBABuyLength] = useState([])
  const [MATICBuyLength, setMATICBuyLength] = useState([])
  const [ETHBuyLength, setETHBuyLength] = useState([])
  const [POLBuyLength, setPOLBuyLength] = useState([])
  const [LINKBuyLength, setLINKBuyLength] = useState([])
  const [ADABuyLength, setADABuyLength] = useState([])
  const [SOLBuyLength, setSOLBuyLength] = useState([])

  const [USDTSellLength, setUSDTSellLength] = useState([])
  const [BNBSellLength, setBNBSellLength] = useState([])
  const [BTCSellLength, setBTCSellLength] = useState([])
  const [TRONSellLength, setTRONSellLength] = useState([]) 
  const [LTCSellLength, setLTCSellLength] = useState([])
  const [DOGESellLength, setDOGESellLength] = useState([])
  const [PAYEERSellLength, setPAYEERSellLength] = useState([])
  const [PMSellLength, setPMSellLength] = useState([])
  const [BUSDSellLength, setBUSDSellLength] = useState([])
  const [SHIBASellLength, setSHIBASellLength] = useState([])
  const [MATICSellLength, setMATICSellLength] = useState([])
  const [ETHSellLength, setETHSellLength] = useState([])
  const [POLSellLength, setPOLSellLength] = useState([])
  const [LINKSellLength, setLINKSellLength] = useState([])
  const [ADASellLength, setADASellLength] = useState([])
  const [SOLSellLength, setSOLSellLength] = useState([])

  const [TimeFilter, setTimeFilter] = useState(24)
  const [TimeStatus, setTimeStatus] = useState("24H")
  const [StopOperation, setStopOperation] = useState(false)
  const [UserFounded, setUserFounded] = useState(false)

  const [GlobalValidated, setGlobalValidated] = useState([])
  const [OnlyBuyFiltered, setOnlyBuyFiltered] = useState([])
  const [OnlyBuyWaveFiltered, setOnlyBuyWaveFiltered] = useState([])
  const [OnlyBuyFilteredData, setOnlyBuyFilteredData] = useState([])
  const [OnlySellFiltered, setOnlySellFiltered] = useState([])
  const [OnlyBuyWaveFilteredData, setOnlyBuyWaveFilteredData] = useState([])
  const [OnlySellWaveFiltered, setOnlySellWaveFiltered] = useState([])
  const [OnlySellFilteredData, setOnlySellFilteredData] = useState([])
  const [OnlySellWaveFilteredData, setOnlySellWaveFilteredData] = useState([])
  const [TotalBuyMoney, setTotalBuyMoney] = useState([])
  const [TotalBuyWaveMoney, setTotalBuyWaveMoney] = useState([])
  const [TotalSellMoney, setTotalSellMoney] = useState([])
  const [TotalSellWaveMoney, setTotalSellWaveMoney] = useState([])

  const [SeeOperation, setSeeOperation] = useState(false)
  const [See2Operation, setSee2Operation] = useState(false)
  const [See4Operation, setSee4Operation] = useState(false)
  const [See6Operation, setSee6Operation] = useState(false)
  const [See8Operation, setSee8Operation] = useState(false)
  const [See10Operation, setSee10Operation] = useState(false)
  const [SeeBuying, setSeeBuying] = useState(false)
  const [SeeSelling, setSeeSelling] = useState(false)

  const [UserStopReq, setUserStopReq] = useState(false)
  const [UnicUser, setUnicUser] = useState([])
  const [LastData, setLastData] = useState([])
  const [OperationDetail, setOperationDetail] = useState([])
  const [FirstDetails, setFirstDetails] = useState(false)

  const methodpayment = OperationDetail.PaymentMethod
  const op = OperationDetail.operatorAction
  const qt = (Number(OperationDetail.converted)*1).toFixed(5)
  const opname = OperationDetail.serviceSymbol
  const opaddress = OperationDetail.adr_Coin
  const opid = OperationDetail.OrderId
  const name = OperationDetail.SenderName
  const validated = OperationDetail.isAdminCompleted
  const phone = OperationDetail.SenderPhone
  const taux = OperationDetail.rate


  const handleChange = (e) => {
    setMyFilter(MyFilter)
    setStopOperation(false)
    setTimeFilter(e.target.value)
    setTimeStatus(`${TimeFilter}H`)
  };

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [MyFilter, setMyFilter] = useState(false)

  const handleStartDateChange = (event) => {
    setMyFilter(MyFilter)
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setMyFilter(MyFilter)
    setEndDate(event.target.value);
  };

  const handleStartTimeChange = (event) => {
    setMyFilter(MyFilter)
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setMyFilter(MyFilter)
    setEndTime(event.target.value);
  };


  useEffect(() => {
    if(!UserFounded){
      if(secondsRemaining2 > 0){
        const fetchDada12 = async () => {
          try {
            const { data } = await axios.get(
              '/api/v1/get-users'
            )
            if(data.length > 0){
              setGetUser(data)
              if(GetUser.length > 0){
                setUserFounded(true)
              }
            }
          } catch (error) { }
        }
        fetchDada12()
      }
    }

  }, [secondsRemaining2, GetUser, UserFounded])

  useEffect(() => {

    const fetchData = async () => {

      if(!StopOperation){
        try {
          const { data } = await axios.get(
            '/api/v1/operations',
          )
          if(data.length > 0){
            setOperation(data)
            setStopOperation(true)
          }
          
        } catch (error) {}
      }
    }

    fetchData()

  }, [Operation, StopOperation])

    
  const RegisteringTo24Hours = (GetUser) => {
      let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
      let dayGetUser = GetUser.filter((item) => (new Date(item.createdAt)).getTime() >= date);
      return dayGetUser;
    }

  const _24hRegisteringNbr = RegisteringTo24Hours(GetUser).length

  const OrderTo24Hours = (Operation) => {
      let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
      let dayOperation = Operation.filter((item) => (new Date(item.createdAt)).getTime() >= date);
      return dayOperation;
    }

  const _24hOrderNbr = OrderTo24Hours(Operation).length

  const [AllOperationSuccess, setAllOperationSuccess] = useState([])
  const [AllOperationFailed, setAllOperationFailed] = useState([])
  const [AllOperationPinding, setAllOperationPinding] = useState([])

  function handleFilter(){
    setMyFilter(!MyFilter)
  }

  useEffect(() => {

    ///////////////// Global Validated ///////////////////
    if(Operation.length > 1 && TimeFilter){

      const GlobalValidatedFiltered = Operation.filter((obj) => {
        return obj.isAdminCompleted === 'validé'
      })

      const GlobalFailedFiltered = Operation.filter((obj) => {
        return obj.isAdminCompleted === 'annulé'
      })

      const GlobalPindingFiltered = Operation.filter((obj) => {
        return obj.isAdminCompleted === 'non validé'
      })

      if(secondsRemaining2 > 0){

        if(GlobalFailedFiltered.length > 0){
          setAllOperationFailed(GlobalFailedFiltered)
        }

        if(GlobalPindingFiltered.length > 0){
          setAllOperationPinding(GlobalPindingFiltered)
        }


        if(GlobalValidatedFiltered.length > 0){
          
          if(startDate !== '' && MyFilter){

            const filtered = GlobalValidatedFiltered.filter(info => {
                const infoDate = new Date(info.createdAt.slice(0, 10));
                const start = new Date(startDate);
                const end = new Date(endDate);
                return infoDate >= start && infoDate <= end;
            });

            if(filtered.length > 0){
              const filtered2 = filtered.filter(info => {
                const infoTime = new Date(`${info.createdAt.slice(0, 10)}${info.createdAt.slice(10, 16)}`);
                const start = new Date(`${info.createdAt.slice(0, 10)}T${startTime}`);
                const end = new Date(`${info.createdAt.slice(0, 10)}T${endTime}`);
                return infoTime >= start && infoTime <= end;
              });

              if(filtered.length > 0 && filtered2.length === 0){
    
                const GlobalOnlyBuyFiltered = filtered.filter((obj) => {
                  return obj.operatorAction === 'buy'
                })
              
                const GlobalBuyWaveValidatedFiltered = GlobalOnlyBuyFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalBuyWaveValidatedFiltered.length > 0){
                
                    //Total Achat Wave
                    const TotalMoneyOnlyBuyWave = (GlobalBuyWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyWaveMoney(TotalMoneyOnlyBuyWave)
    
                    setOnlyBuyWaveFilteredData(GlobalBuyWaveValidatedFiltered)
                    setOnlyBuyWaveFiltered(GlobalBuyWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlyBuyFiltered.length > 0){
                
                    //Total Achat
                    const TotalMoneyOnlyBuy = (GlobalOnlyBuyFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyMoney(TotalMoneyOnlyBuy)
    
                    setOnlyBuyFilteredData(GlobalOnlyBuyFiltered)
                    setOnlyBuyFiltered(GlobalOnlyBuyFiltered.length)
                }
    
                const GlobalOnlySellFiltered = filtered.filter((obj) => {
                  return obj.operatorAction === 'sel'
                })
              
                const GlobalSellWaveValidatedFiltered = GlobalOnlySellFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalSellWaveValidatedFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySellWave = (GlobalSellWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellWaveMoney(TotalMoneyOnlySellWave)
    
                  setOnlySellWaveFilteredData(GlobalSellWaveValidatedFiltered)
                  setOnlySellWaveFiltered(GlobalSellWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlySellFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySell = (GlobalOnlySellFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellMoney(TotalMoneyOnlySell)
    
                  setOnlySellFilteredData(GlobalOnlySellFiltered)
                  setOnlySellFiltered(GlobalOnlySellFiltered.length)
                }
              }

              if(filtered.length > 0 && filtered2.length > 0){
    
                const GlobalOnlyBuyFiltered = filtered2.filter((obj) => {
                  return obj.operatorAction === 'buy'
                })
              
                const GlobalBuyWaveValidatedFiltered = GlobalOnlyBuyFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalBuyWaveValidatedFiltered.length > 0){
                
                    //Total Achat Wave
                    const TotalMoneyOnlyBuyWave = (GlobalBuyWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyWaveMoney(TotalMoneyOnlyBuyWave)
    
                    setOnlyBuyWaveFilteredData(GlobalBuyWaveValidatedFiltered)
                    setOnlyBuyWaveFiltered(GlobalBuyWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlyBuyFiltered.length > 0){
                
                    //Total Achat
                    const TotalMoneyOnlyBuy = (GlobalOnlyBuyFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyMoney(TotalMoneyOnlyBuy)
    
                    setOnlyBuyFilteredData(GlobalOnlyBuyFiltered)
                    setOnlyBuyFiltered(GlobalOnlyBuyFiltered.length)
                }
    
                const GlobalOnlySellFiltered = filtered2.filter((obj) => {
                  return obj.operatorAction === 'sel'
                })
              
                const GlobalSellWaveValidatedFiltered = GlobalOnlySellFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalSellWaveValidatedFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySellWave = (GlobalSellWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellWaveMoney(TotalMoneyOnlySellWave)
    
                  setOnlySellWaveFilteredData(GlobalSellWaveValidatedFiltered)
                  setOnlySellWaveFiltered(GlobalSellWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlySellFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySell = (GlobalOnlySellFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellMoney(TotalMoneyOnlySell)
    
                  setOnlySellFilteredData(GlobalOnlySellFiltered)
                  setOnlySellFiltered(GlobalOnlySellFiltered.length)
                }
              }
            }

          } else {
  
            const _data = GlobalValidatedFiltered
            setAllOperationSuccess(_data)
            const items = _data.slice(0, _24hOrderNbr)
            
            if(items.length > 0){

              setGlobalValidated(items)
    
              if(GlobalValidated.length > 0){
    
                const GlobalOnlyBuyFiltered = GlobalValidated.filter((obj) => {
                  return obj.operatorAction === 'buy'
                })
              
                const GlobalBuyWaveValidatedFiltered = GlobalOnlyBuyFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalBuyWaveValidatedFiltered.length > 0){
                
                    //Total Achat Wave
                    const TotalMoneyOnlyBuyWave = (GlobalBuyWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyWaveMoney(TotalMoneyOnlyBuyWave)
    
                    setOnlyBuyWaveFilteredData(GlobalBuyWaveValidatedFiltered)
                    setOnlyBuyWaveFiltered(GlobalBuyWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlyBuyFiltered.length > 0){
                
                    //Total Achat
                    const TotalMoneyOnlyBuy = (GlobalOnlyBuyFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
                    setTotalBuyMoney(TotalMoneyOnlyBuy)
    
                    setOnlyBuyFilteredData(GlobalOnlyBuyFiltered)
                    setOnlyBuyFiltered(GlobalOnlyBuyFiltered.length)
                }
    
                const GlobalOnlySellFiltered = GlobalValidated.filter((obj) => {
                  return obj.operatorAction === 'sel'
                })
              
                const GlobalSellWaveValidatedFiltered = GlobalOnlySellFiltered.filter((obj) => {
                  return obj.PaymentMethod === 'Wave'
                })
      
                if(GlobalSellWaveValidatedFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySellWave = (GlobalSellWaveValidatedFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellWaveMoney(TotalMoneyOnlySellWave)
    
                  setOnlySellWaveFilteredData(GlobalSellWaveValidatedFiltered)
                  setOnlySellWaveFiltered(GlobalSellWaveValidatedFiltered.length)
                }
      
                if(GlobalOnlySellFiltered.length > 0){
                
                  //Total Vente
                  const TotalMoneyOnlySell = (GlobalOnlySellFiltered.reduce((total, currentItem) =>  total = total + currentItem.SenderChange , 0 ));
    
                  setTotalSellMoney(TotalMoneyOnlySell)
    
                  setOnlySellFilteredData(GlobalOnlySellFiltered)
                  setOnlySellFiltered(GlobalOnlySellFiltered.length)
                }
              }
    
            }

          }
        }
      }


  
      ///////////////// Global Buy ///////////////////
      const GlobalBuyFiltered = GlobalValidatedFiltered.filter((obj) => {
        return obj.operatorAction === 'buy'
      })

      const GlobalBuyWaveFiltered = GlobalBuyFiltered.filter((obj) => {
        return obj.PaymentMethod === 'Wave'
      })

      if(startDate !== '' && GlobalBuyFiltered.length > 0 && MyFilter){

        const filtered = GlobalBuyFiltered.filter(info => {
            const infoDate = new Date(info.createdAt.slice(0, 10));
            const start = new Date(startDate);
            const end = new Date(endDate);
            return infoDate >= start && infoDate <= end;
        });

        if(filtered.length > 0){

          const filtered2 = filtered.filter(info => {
            const infoTime = new Date(`${info.createdAt.slice(0, 10)}${info.createdAt.slice(10, 16)}`);
            const start = new Date(`${info.createdAt.slice(0, 10)}T${startTime}`);
            const end = new Date(`${info.createdAt.slice(0, 10)}T${endTime}`);
            return infoTime >= start && infoTime <= end;
          });

          if(filtered2.length > 0){
  
            const _24TotalRateBuy = filtered2.reduce(
              (total, currentItem) => (total = total + currentItem.rate),
              0,
            )
  
            set_24TotalRateBuy(_24TotalRateBuy/filtered2.length)
    
            const _24TotalBuy = filtered2.reduce(
              (total, currentItem) => (total = total + currentItem.dollar),
              0,
            )
    
            set_24TotalBuy(_24TotalBuy)
  
          } else {
              const _24TotalRateBuy = filtered.reduce(
                (total, currentItem) => (total = total + currentItem.rate),
                0,
              )

              set_24TotalRateBuy(_24TotalRateBuy/filtered.length)
      
              const _24TotalBuy = filtered.reduce(
                (total, currentItem) => (total = total + currentItem.dollar),
                0,
              )
      
              set_24TotalBuy(_24TotalBuy)

            }
          }
      } else {
  
        setGlobalBuy(GlobalBuyFiltered)
        setGlobalBuyWave(GlobalBuyWaveFiltered)
        
          const GlobalBuyTo24Hours = (GlobalBuy) => {
              let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
              let dayGlobalBuy = GlobalBuy.filter((item) => (new Date(item.createdAt)).getTime() >= date);
              return dayGlobalBuy;
            }
          
            const GlobalBuyWaveTo24Hours = (GlobalBuyWave) => {
                let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
                let dayGlobalBuyWave = GlobalBuyWave.filter((item) => (new Date(item.createdAt)).getTime() >= date);
                return dayGlobalBuyWave;
              }
    
            setOrdersBuy(GlobalBuyTo24Hours(GlobalBuy).length)
            setOrdersBuyWave(GlobalBuyWaveTo24Hours(GlobalBuyWave).length)
    
            const _24TotalRateBuy = GlobalBuyTo24Hours(GlobalBuy).reduce(
              (total, currentItem) => (total = total + currentItem.rate),
              0,
            )
    
            set_24TotalRateBuy(_24TotalRateBuy/GlobalBuyTo24Hours(GlobalBuy).length)
    
            const _24TotalBuy = GlobalBuyTo24Hours(GlobalBuy).reduce(
              (total, currentItem) => (total = total + currentItem.dollar),
              0,
            )
    
            set_24TotalBuy(_24TotalBuy)


            if(GlobalBuyTo24Hours(GlobalBuy)){
                ///////////////// USDT ///////////////////
                const USDTBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Tether(USDT)'
                })
                if(USDTBuyFiltered.length >= 1){
                  setUSDTBuyLength(USDTBuyFiltered.length)
                }
        
                ///////////////// BNB ///////////////////
                const BNBBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Binance Smart Chain'
                })
                if(BNBBuyFiltered.length >= 1){
                  setBNBBuyLength(BNBBuyFiltered.length)
                }
                
                ///////////////// BTC ///////////////////
                const BTCBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Bitcoin'
                })
                if(BTCBuyFiltered.length >= 1){
                  setBTCBuyLength(BTCBuyFiltered.length)
                }
                
                ///////////////// TRON ///////////////////
                const TRONBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'TRON'
                })
                if(TRONBuyFiltered.length >= 1){
                  setTRONBuyLength(TRONBuyFiltered.length)
                }
                
                ///////////////// LTC ///////////////////
                const LTCBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Litecoin'
                })
                if(LTCBuyFiltered.length >= 1){
                  setLTCBuyLength(LTCBuyFiltered.length)
                }
                
                ///////////////// DOGE ///////////////////
                const DOGEBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Dogecoin'
                })
                if(DOGEBuyFiltered.length >= 1){
                  setDOGEBuyLength(DOGEBuyFiltered.length)
                }
                
                ///////////////// PAYEER ///////////////////
                const PAYEERBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Payeer'
                })
                if(PAYEERBuyFiltered.length >= 1){
                  setPAYEERBuyLength(PAYEERBuyFiltered.length)
                }
                
                ///////////////// PM ///////////////////
                const PMBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Perfect Money'
                })
                if(PMBuyFiltered.length >= 1){
                  setPMBuyLength(PMBuyFiltered.length)
                }
                
                ///////////////// BUSD ///////////////////
                const BUSDBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Binance USD'
                })
                if(BUSDBuyFiltered.length >= 1){
                  setBUSDBuyLength(BUSDBuyFiltered.length)
                }
                
                ///////////////// SHIBA ///////////////////
                const SHIBABuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Shiba Inu'
                })
                if(SHIBABuyFiltered.length >= 1){
                  setSHIBABuyLength(SHIBABuyFiltered.length)
                }
                
                ///////////////// MATIC ///////////////////
                const MATICBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Polygon'
                })
                if(MATICBuyFiltered.length >= 1){
                  setMATICBuyLength(MATICBuyFiltered.length)
                }
                
                ///////////////// ETH ///////////////////
                const ETHBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Ethereum'
                })
                if(ETHBuyFiltered.length >= 1){
                  setETHBuyLength(ETHBuyFiltered.length)
                }
                
                ///////////////// POL ///////////////////
                const POLBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Polkadot'
                })
                if(POLBuyFiltered.length >= 1){
                  setPOLBuyLength(POLBuyFiltered.length)
                }
                
                ///////////////// LINK ///////////////////
                const LINKBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Chainlink'
                })
                if(LINKBuyFiltered.length >= 1){
                  setLINKBuyLength(LINKBuyFiltered.length)
                }
                
                ///////////////// ADA ///////////////////
                const ADABuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Cardano'
                })
                if(ADABuyFiltered.length >= 1){
                  setADABuyLength(ADABuyFiltered.length)
                }
                
                ///////////////// SOL ///////////////////
                const SOLBuyFiltered = GlobalBuyTo24Hours(GlobalBuy).filter((obj) => {
                  return obj.serviceName === 'Solana'
                })
                if(SOLBuyFiltered.length >= 1){
                  setSOLBuyLength(SOLBuyFiltered.length)
                }
            }
      }
  
      ///////////////// Global Sell ///////////////////
      const GlobalSellFiltered = GlobalValidatedFiltered.filter((obj) => {
        return obj.operatorAction === 'sel'
      })

      const GlobalSellWaveFiltered = GlobalValidatedFiltered.filter((obj) => {
        return obj.PaymentMethod === 'Wave'
      })

      if(startDate !== '' && GlobalSellFiltered.length > 0 && MyFilter){

        const filtered = GlobalSellFiltered.filter(info => {
            const infoDate = new Date(info.createdAt.slice(0, 10));
            const start = new Date(startDate);
            const end = new Date(endDate);
            return infoDate >= start && infoDate <= end;
        });

        if(filtered.length > 0){

          const filtered2 = filtered.filter(info => {
            const infoTime = new Date(`${info.createdAt.slice(0, 10)}${info.createdAt.slice(10, 16)}`);
            const start = new Date(`${info.createdAt.slice(0, 10)}T${startTime}`);
            const end = new Date(`${info.createdAt.slice(0, 10)}T${endTime}`);
            return infoTime >= start && infoTime <= end;
          });
  
          if(filtered2.length > 0){
    
            const _24TotalRateSell = filtered2.reduce(
              (total, currentItem) => (total = total + currentItem.rate),
              0,
            )
  
            set_24TotalRateSell(_24TotalRateSell/filtered2.length)
    
            const _24TotalSell = filtered2.reduce(
              (total, currentItem) => (total = total + currentItem.dollar),
              0,
            )
    
            set_24TotalSell(_24TotalSell)
  
          } else {
    
            const _24TotalRateSell = filtered.reduce(
              (total, currentItem) => (total = total + currentItem.rate),
              0,
            )
  
            set_24TotalRateSell(_24TotalRateSell/filtered.length)
    
            const _24TotalSell = filtered.reduce(
              (total, currentItem) => (total = total + currentItem.dollar),
              0,
            )
    
            set_24TotalSell(_24TotalSell)
  
          }
        }

      } else {
  
        setGlobalSell(GlobalSellFiltered)
        setGlobalSellWave(GlobalSellWaveFiltered)
  
        
        const GlobalSellTo24Hours = (GlobalSell) => {
            let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
            let dayGlobalSell = GlobalSell.filter((item) => (new Date(item.createdAt)).getTime() >= date);
            return dayGlobalSell;
          }
  
        
          const GlobalSellWaveTo24Hours = (GlobalSellWave) => {
              let date = (new Date()).getTime() - TimeFilter * 60 * 60 * 1000;
              let dayGlobalSellWave = GlobalSellWave.filter((item) => (new Date(item.createdAt)).getTime() >= date);
              return dayGlobalSellWave;
            }
  
          setOrdersSell(GlobalSellTo24Hours(GlobalSell).length)
          setOrdersSellWave(GlobalSellWaveTo24Hours(GlobalSellWave).length)
  
          const _24TotalRateSell = GlobalSellTo24Hours(GlobalSell).reduce(
            (total, currentItem) => (total = total + currentItem.rate),
            0,
          )
  
          set_24TotalRateSell(_24TotalRateSell/GlobalSellTo24Hours(GlobalSell).length)
  
          const _24TotalSell = GlobalSellTo24Hours(GlobalSell).reduce(
            (total, currentItem) => (total = total + currentItem.dollar),
            0,
          )
  
          set_24TotalSell(_24TotalSell)
      
          if(GlobalSellTo24Hours(GlobalSell)){
              ///////////////// USDT ///////////////////
              const USDTSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Tether(USDT)'
              })
              if(USDTSellFiltered.length >= 1){
                setUSDTSellLength(USDTSellFiltered.length)
              }
      
              ///////////////// BNB ///////////////////
              const BNBSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Binance Smart Chain'
              })
              if(BNBSellFiltered.length >= 1){
                setBNBSellLength(BNBSellFiltered.length)
              }
              
              ///////////////// BTC ///////////////////
              const BTCSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Bitcoin'
              })
              if(BTCSellFiltered.length >= 1){
                setBTCSellLength(BTCSellFiltered.length)
              }
              
              ///////////////// TRON ///////////////////
              const TRONSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'TRON'
              })
              if(TRONSellFiltered.length >= 1){
                setTRONSellLength(TRONSellFiltered.length)
              }
              
              ///////////////// LTC ///////////////////
              const LTCSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Litecoin'
              })
              if(LTCSellFiltered.length >= 1){
                setLTCSellLength(LTCSellFiltered.length)
              }
              
              ///////////////// DOGE ///////////////////
              const DOGESellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Dogecoin'
              })
              if(DOGESellFiltered.length >= 1){
                setDOGESellLength(DOGESellFiltered.length)
              }
              
              ///////////////// PAYEER ///////////////////
              const PAYEERSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Payeer'
              })
              if(PAYEERSellFiltered.length >= 1){
                setPAYEERSellLength(PAYEERSellFiltered.length)
              }
              
              ///////////////// PM ///////////////////
              const PMSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Perfect Money'
              })
              if(PMSellFiltered.length >= 1){
                setPMSellLength(PMSellFiltered.length)
              }
              
              ///////////////// BUSD ///////////////////
              const BUSDSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Binance USD'
              })
              if(BUSDSellFiltered.length >= 1){
                setBUSDSellLength(BUSDSellFiltered.length)
              }
              
              ///////////////// SHIBA ///////////////////
              const SHIBASellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Shiba Inu'
              })
              if(SHIBASellFiltered.length >= 1){
                setSHIBASellLength(SHIBASellFiltered.length)
              }
              
              ///////////////// MATIC ///////////////////
              const MATICSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Polygon'
              })
              if(MATICSellFiltered.length >= 1){
                setMATICSellLength(MATICSellFiltered.length)
              }
              
              ///////////////// ETH ///////////////////
              const ETHSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Ethereum'
              })
              if(ETHSellFiltered.length >= 1){
                setETHSellLength(ETHSellFiltered.length)
              }
              
              ///////////////// POL ///////////////////
              const POLSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Polkadot'
              })
              if(POLSellFiltered.length >= 1){
                setPOLSellLength(POLSellFiltered.length)
              }
              
              ///////////////// LINK ///////////////////
              const LINKSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Chainlink'
              })
              if(LINKSellFiltered.length >= 1){
                setLINKSellLength(LINKSellFiltered.length)
              }
              
              ///////////////// ADA ///////////////////
              const ADASellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Cardano'
              })
              if(ADASellFiltered.length >= 1){
                setADASellLength(ADASellFiltered.length)
              }
              
              ///////////////// SOL ///////////////////
              const SOLSellFiltered = GlobalSellTo24Hours(GlobalSell).filter((obj) => {
                return obj.serviceName === 'Solana'
              })
              if(SOLSellFiltered.length >= 1){
                setSOLSellLength(SOLSellFiltered.length)
              }
          }
      }
    }

  }, [Operation, TimeFilter, TotalBuyMoney, TotalSellMoney, _24hOrderNbr, secondsRemaining2])

  const BenefTotal = ((((!_24TotalRateBuy?0:_24TotalRateBuy)-(!_24TotalRateSell?0:_24TotalRateSell))*(_24TotalBuy - _24TotalSell))*1).toFixed(0)
  const BenefWaveTotal = ((((!_24TotalRateBuyWave?0:_24TotalRateBuyWave)-(!_24TotalRateSellWave?0:_24TotalRateSellWave))*(_24TotalBuyWave - _24TotalSellWave))*1).toFixed(0)


  const OM      = "Orange Money"
  const MOMO    = "Mobile Money"
  const MOOV    = "Moov Money"
  const WAVE    = "Wave"
  const TM      = "TMoney"
  const FM      = "Free Money"
  const EM      = "EMoney"

  const om      = "../images/om.png"
  const momo    = "../images/momo.png"
  const flooz   = "../images/flooz.png"
  const wave    = "../images/wave.png"
  const tm      = "../images/tm.png"
  const fm      = "../images/fm.png"
  const em      = "../images/em.png"

  const TRON    = "TRON"
  const BTC     = "Bitcoin"
  const LTC     = "Litecoin"
  const USDT    = "Tether(USDT)"
  const DOGE    = "Dogecoin"
  const BUSD    = "Binance USD"
  const SHIB    = "Shiba Inu"
  const MATIC   = "Polygon"
  const ETH     = "Ethereum"
  const LINK    = "Chainlink"
  const POLKADOT    = "Polkadot"
  const CARDANO     = "Cardano"
  const SOLANA      = "Solana"
  const PAYEER      = "Payeer"
  const PM          = "Perfect Money"
  const BNB         = "Binance Smart Chain"
  
  const tron      = "../images/tron.png"
  const btc    = "../images/btc.png"
  const ltc   = "../images/ltc.png"
  const usdt   = "../images/usdt.png"
  const doge    = "../images/doge.png"
  const busd    = "../images/busd.png"
  const shiba    = "../images/shiba.png"
  const polygonmatic    = "../images/polygonmatic.png"
  const ethereum    = "../images/eth.png"
  const chainlink    = "../images/chainlink.png"
  const polkadot    = "../images/pokadot.png"
  const cardano    = "../images/cardano.png"
  const solana    = "../images/solana.png"
  const payeer      = "../images/payeer.png"
  const pm      = "../images/pm.png"
  const bnb      = "../images/bnb.png"

  function seeOperations(){
    setSee4Operation(false)
    setSee2Operation(false)
    setSeeOperation(true)
    setSeeBuying(false)
    setSeeSelling(false)
    setSee8Operation(false)
    setSee10Operation(false)
    setSee6Operation(false)
  }

  function see2Operations(){
    setSee4Operation(false)
    setSee2Operation(true)
    setSeeOperation(false)
    setSeeBuying(false)
    setSeeSelling(false)
    setSee8Operation(false)
    setSee10Operation(false)
    setSee6Operation(false)
  }

  function see4Operations(){
    setSee4Operation(true)
    setSee2Operation(false)
    setSeeOperation(false)
    setSeeBuying(false)
    setSeeSelling(false)
    setSee8Operation(false)
    setSee10Operation(false)
    setSee6Operation(false)
  }

  function see6Operations(){
    setSee6Operation(true)
    setSee10Operation(false)
    setSee8Operation(false)
    setSee4Operation(false)
    setSee2Operation(false)
    setSeeOperation(false)
    setSeeBuying(false)
    setSeeSelling(false)
  }

  function see8Operations(){
    setSee8Operation(true)
    setSee10Operation(false)
    setSee6Operation(false)
    setSee4Operation(false)
    setSee2Operation(false)
    setSeeOperation(false)
    setSeeBuying(false)
    setSeeSelling(false)
  }

  function see10Operations(){
    setSee10Operation(true)
    setSee8Operation(false)
    setSee6Operation(false)
    setSee4Operation(false)
    setSee2Operation(false)
    setSeeOperation(false)
    setSeeBuying(false)
    setSeeSelling(false)
  }

  const LineLink = true

  function seeBuyingList(id){
    if(id){

      if(LineLink){
        window.location.replace(`https://nkab-exchange.net/stat-buy/${id}`);
      } else {
         window.location.replace(`http://localhost:3000/stat-buy/${id}`);
      }
    }
  }

  function seeSellingList(id){
    if(id){

      if(LineLink){
        window.location.replace(`https://nkab-exchange.net/stat-sell/${id}`);
      } else {
         window.location.replace(`http://localhost:3000/stat-sell/${id}`);
      }
    }
  }

  const findMailWithTwoOrMoreOperations = (GlobalValidated) => {

  // Créer un objet pour stocker le nombre d'opérations par mail
  const operationCountByMail = {};

  // Parcourir toutes les opérations pour compter le nombre d'opérations par mail
  GlobalValidated.forEach((operation) => {

    const { SenderEmail } = operation;

    if (operationCountByMail[SenderEmail]) {
      operationCountByMail[SenderEmail]++;
    } else {
      operationCountByMail[SenderEmail] = 1;
    }

  });

  // Filtrer les mails qui ont effectué au moins deux opérations
  const mailsWithTwoOrMoreOperations = Object.keys(operationCountByMail).filter(
    (mail) => operationCountByMail[mail] >= 2
  );

  return mailsWithTwoOrMoreOperations;
};

const findMail2WithTwoOrMoreOperations = (GlobalValidated) => {

// Créer un objet pour stocker le nombre d'opérations par mail
const operationCountByMail = {};

// Parcourir toutes les opérations pour compter le nombre d'opérations par mail
GlobalValidated.forEach((operation) => {

  const { SenderEmail } = operation;

  if (operationCountByMail[SenderEmail]) {
    operationCountByMail[SenderEmail]++;
  } else {
    operationCountByMail[SenderEmail] = 1;
  }

});

// Filtrer les mails qui ont effectué au moins quatre opérations
const mails2WithTwoOrMoreOperations = Object.keys(operationCountByMail).filter(
  (mail) => operationCountByMail[mail] >= 4
);

return mails2WithTwoOrMoreOperations;
};

const findMail3WithTwoOrMoreOperations = (GlobalValidated) => {

// Créer un objet pour stocker le nombre d'opérations par mail
const operationCountByMail = {};

// Parcourir toutes les opérations pour compter le nombre d'opérations par mail
GlobalValidated.forEach((operation) => {

  const { SenderEmail } = operation;

  if (operationCountByMail[SenderEmail]) {
    operationCountByMail[SenderEmail]++;
  } else {
    operationCountByMail[SenderEmail] = 1;
  }

});

// Filtrer les mails qui ont effectué au moins quatre opérations
const mails3WithTwoOrMoreOperations = Object.keys(operationCountByMail).filter(
  (mail) => operationCountByMail[mail] >= 6
);

return mails3WithTwoOrMoreOperations;
};

const findMail4WithTwoOrMoreOperations = (GlobalValidated) => {

// Créer un objet pour stocker le nombre d'opérations par mail
const operationCountByMail = {};

// Parcourir toutes les opérations pour compter le nombre d'opérations par mail
GlobalValidated.forEach((operation) => {

  const { SenderEmail } = operation;

  if (operationCountByMail[SenderEmail]) {
    operationCountByMail[SenderEmail]++;
  } else {
    operationCountByMail[SenderEmail] = 1;
  }

});

// Filtrer les mails qui ont effectué au moins quatre opérations
const mails4WithTwoOrMoreOperations = Object.keys(operationCountByMail).filter(
  (mail) => operationCountByMail[mail] >= 8
);

return mails4WithTwoOrMoreOperations;
};

const findMail5WithTwoOrMoreOperations = (GlobalValidated) => {

// Créer un objet pour stocker le nombre d'opérations par mail
const operationCountByMail = {};

// Parcourir toutes les opérations pour compter le nombre d'opérations par mail
GlobalValidated.forEach((operation) => {

  const { SenderEmail } = operation;

  if (operationCountByMail[SenderEmail]) {
    operationCountByMail[SenderEmail]++;
  } else {
    operationCountByMail[SenderEmail] = 1;
  }

});

// Filtrer les mails qui ont effectué au moins quatre opérations
const mails5WithTwoOrMoreOperations = Object.keys(operationCountByMail).filter(
  (mail) => operationCountByMail[mail] >= 10
);

return mails5WithTwoOrMoreOperations;
};

const mails = findMailWithTwoOrMoreOperations(GlobalValidated);
const mails2 = findMail2WithTwoOrMoreOperations(GlobalValidated);
const mails3 = findMail3WithTwoOrMoreOperations(GlobalValidated);
const mails4 = findMail4WithTwoOrMoreOperations(GlobalValidated);
const mails5 = findMail5WithTwoOrMoreOperations(GlobalValidated);

useEffect(()=>{

  const fetchDada12 = async () => {
    try {
      if(!UserStopReq){
        const { data } = await axios.get(
          '/api/v1/get-users',
        )
        if(data.length > 0){
          setGetUser(data)
          setUserStopReq(true)
        }
      }
    } catch (error) {}
  }
  fetchDada12()

},[UserStopReq])

const notifHandler = async (item) =>{

  if(GetUser.length > 1 && item){
  
    const SearchUser = GetUser.filter((obj)=>{
      return obj.email === item
    })

    if(SearchUser.length > 0){
      setUnicUser(SearchUser[0])
    }

  }
  
}

const ci = "Côte d'Ivoire"
const sen = "Sénégal"
const burk = "Burkina Fasso"
const benin = "Benin"
const togo = "Togo"
const mali = "Mali"
const niger = "Niger"


useEffect(()=>{
  
  if(secondsRemaining2 > 0){
    if(SeeOperation){
      setLastData(GlobalValidated)
    }
    if(SeeBuying){
      setLastData(OnlyBuyFilteredData)
    }
    if(SeeSelling){
      setLastData(OnlySellFilteredData)
    }
  }
},[
  SeeOperation, 
  SeeBuying, 
  SeeSelling, 
  GlobalValidated, 
  OnlyBuyFilteredData, 
  OnlySellFilteredData,
  secondsRemaining2
])

// Tableau pour stocker les lignes du tableau
const tableBody = [];

// Ajoute chaque information de la liste au tableau
LastData.forEach((info, index) => {
  tableBody.push([index+1, info.SenderEmail, info.SenderPhone, `${info.operatorAction === "buy"?"Achat":"Vente"}`, info.serviceName, `${info.rate} XOF`, (info.converted*1).toFixed(5), info.PaymentMethod, `${info.SenderChange} XOF`, `${info.updatedAt.slice(0, 10)} à ${Number(info.updatedAt.slice(11,13)) +2}${info.updatedAt.slice(13, 19)}`]);
});

// Définit la structure du document PDF
const documentDefinition = {
  content: [
    {
      text: `NKAB Exchange | Liste des opérations validées en ${TimeStatus}`,
      style: 'header',
    },
    {
      table: {
        headerRows: 1,
        // widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'], '*'],
        body: [
          ['N°', 'Email', 'Contact', 'Opération', 'Crypto', 'Taux', 'Quantité', 'MP', 'Montant', 'Date'],
          ...tableBody
        ]
      },
      fontSize: 8,
    }
  ],
  styles: {
    header: {
      fontSize: 14,
      bold: true,
      margin: [0, 0, 0, 10]
    },
  }
};

const PdfDucument = () => {
  // Génère le document PDF
  const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
  pdfDocGenerator.open();
}
  
const detailOption = (id) => {

  setFirstDetails(!FirstDetails)

  if (id && Operation.length > 0) {

    const dataGetted = Operation.filter((obj) => {
      return obj.ID === Number(id)
    })

    setOperationDetail(dataGetted[0])
  }
}



  return (
    <div className='container pb-5' style={{backgroundColor:"#90d9ff"}}>
        <div className='pt-3'>
            <div className='stat-title p-3 mb-2 uppercase d-flex justify-content-between'>
                <span>Elements généraux</span>
                <span style={{fontSize:"10px"}} className={`${SeeOperation?"pointer pt-1":" pointer pt-1"}`} onClick={seeOperations}>Voir les pérations <FontAwesomeIcon icon={faArrowRight} /></span>

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-simple-select-label">Périodes</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={TimeFilter}
                    label="TimeFilter"
                    onChange={handleChange}
                  >
                    <MenuItem value={2}>En 2H</MenuItem>
                    <MenuItem value={4}>En 4H</MenuItem>
                    <MenuItem value={12}>En 12H</MenuItem>
                    <MenuItem value={24}>En 24H</MenuItem>
                    <MenuItem value={168}>En 7Jrs</MenuItem>
                    <MenuItem value={240}>En 10jrs</MenuItem>
                    <MenuItem value={480}>En 20jrs</MenuItem>
                    <MenuItem value={720}>En 1Mois</MenuItem>
                    <MenuItem value={2160}>En 3Mois</MenuItem>
                    <MenuItem value={4320}>En 6Mois</MenuItem>
                    <MenuItem value={8640}>En 1ans</MenuItem>
                  </Select>
                </FormControl>
            </div>
            <div className='stat-title p-3 mb-2 uppercase'>
              <div><p>Filtreur d'informations</p></div>
              <div className='d-flex justify-content-center'>
                <div className='mr-1'>
                  <form className="nkab-date-picker" noValidate onChange={handleStartDateChange}>
                    <TextField id="date" label="De la date" type="date" defaultValue="2024-01-01" className="nkab-date-text-field css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root css-1v4ccyo" InputLabelProps={{ shrink: true }} />
                  </form>
                </div>
                <div className='ml-1'>
                  <form className="nkab-date-picker" noValidate onChange={handleEndDateChange}>
                    <TextField id="date" label="A la date" type="date" defaultValue="2024-01-01" className="nkab-date-text-field css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root css-1v4ccyo" InputLabelProps={{ shrink: true }} />
                  </form>
                </div>
              </div><br/>
              <div className='d-flex justify-content-center'>
                <div className='mr-1'>
                  <form className="nkab-date-picker" noValidate onChange={handleStartTimeChange}>
                    <TextField id="time" label="De l'heure" type="time" defaultValue="07:30" className="nkab-date-text-field css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root css-1v4ccyo" InputLabelProps={{ shrink: true }} inputProps={{ step: 300 }} />
                  </form>
                </div>
                <div className='ml-1'>
                  <form className="nkab-date-picker" noValidate onChange={handleEndTimeChange}>
                    <TextField id="time" label="A l'heure" type="time" defaultValue="07:30" className="nkab-date-text-field css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root css-1v4ccyo" InputLabelProps={{ shrink: true }} inputProps={{ step: 300 }} />
                  </form>
                </div>
              </div><br/>
              <div className='d-flex justify-content-center'><span className='pointer nkab-schedule-send-icon-btn nkab-schedule-send-icon' onClick={handleFilter}>Filtrer</span></div>
            </div>
            
            <div className='row'>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                            Membres
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip">
                                    <g transform="translate(256 256)">
                                        <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span>
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>{!GetUser?null:GetUser.length}</div>
                                <div className='stat-sub-title'> <span className='text-success'>+{_24hRegisteringNbr}</span> adhérés 
                                {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                                </div>
                            </div>
                            <div className='pt-3 color-b'><FontAwesomeIcon icon={faUserFriends} style={{fontSize:'35px'}} /></div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                            Operations générales
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                            <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span>
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>{!Operation?null:Operation.length}</div>
                                <div className='stat-sub-title'> <span className='text-success'>
                                    +{_24hOrderNbr}</span> ordres effectués 
                                    {/* <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                                </div>
                            </div>
                            <div className='pt-3 color-b'><FontAwesomeIcon icon={faHandshake} style={{fontSize:'35px'}} /></div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                            <span className='text-success'>+{!OrdersSell?0:OrdersSell}</span> Les plus achetées 
                            {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>${!_24TotalSell?0:(_24TotalSell*1).toFixed(2)}</div>
                            </div>
                            <div className='pt-2'>
                              {USDTSellLength >= 1 ? <span><img src={`${usdt}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {BNBSellLength >= 1 ? <span><img src={`${bnb}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {BTCSellLength >= 1 ? <span><img src={`${btc}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {TRONSellLength >= 1 ? <span><img src={`${tron}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {LTCSellLength >= 1 ? <span><img src={`${ltc}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {DOGESellLength >= 1 ? <span><img src={`${doge}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {PAYEERSellLength >= 1 ? <span><img src={`${payeer}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {PMSellLength >= 1 ? <span><img src={`${pm}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {BUSDSellLength >= 1 ? <span><img src={`${busd}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {SHIBASellLength >= 1 ? <span><img src={`${shiba}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {MATICSellLength >= 1 ? <span><img src={`${polygonmatic}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {ETHSellLength >= 1 ? <span><img src={`${ethereum}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {POLSellLength >= 1 ? <span><img src={`${polkadot}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {LINKSellLength >= 1 ? <span><img src={`${chainlink}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {ADASellLength >= 1 ? <span><img src={`${cardano}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                              {SOLSellLength >= 1 ? <span><img src={`${solana}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                            <span className='text-success'>+{!OrdersBuy?0:OrdersBuy}</span> Les plus vendues 
                            {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>${!_24TotalBuy?0:(_24TotalBuy*1).toFixed(2)}</div>
                            </div>
                            <div className='pt-2'>
                                {USDTBuyLength >= 1 ? <span><img src={`${usdt}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {BNBBuyLength >= 1 ? <span><img src={`${bnb}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {BTCBuyLength >= 1 ? <span><img src={`${btc}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {TRONBuyLength >= 1 ? <span><img src={`${tron}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {LTCBuyLength >= 1 ? <span><img src={`${ltc}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {DOGEBuyLength >= 1 ? <span><img src={`${doge}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {PAYEERBuyLength >= 1 ? <span><img src={`${payeer}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {PMBuyLength >= 1 ? <span><img src={`${pm}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {BUSDBuyLength >= 1 ? <span><img src={`${busd}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {SHIBABuyLength >= 1 ? <span><img src={`${shiba}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {MATICBuyLength >= 1 ? <span><img src={`${polygonmatic}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {ETHBuyLength >= 1 ? <span><img src={`${ethereum}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {POLBuyLength >= 1 ? <span><img src={`${polkadot}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {LINKBuyLength >= 1 ? <span><img src={`${chainlink}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {ADABuyLength >= 1 ? <span><img src={`${cardano}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                                {SOLBuyLength >= 1 ? <span><img src={`${solana}`} className='nkab-comments-avatar' width="10" height="10" alt="" /></span>:null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='row'>
                <div className='col-md-3'>
                    <div className='stat-card text-success'>
                        <div className='stat-card-title'>
                            Operations totales avec succès
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                            {/* <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span> */}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>{!AllOperationSuccess?null:AllOperationSuccess.length}</div>
                                {/* <div className='stat-sub-title'> <span className='text-success'>
                                    +{_24hOrderNbr}</span> ordres effectués <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span>
                                </div> */}
                            </div>
                            {/* <div className='pt-3 color-b'><FontAwesomeIcon icon={faHandshake} style={{fontSize:'35px'}} /></div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card text-danger'>
                        <div className='stat-card-title'>
                            Operations totales en échec
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                            {/* <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span> */}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>{!AllOperationFailed?null:AllOperationFailed.length}</div>
                                {/* <div className='stat-sub-title'> <span className='text-success'>
                                    +{_24hOrderNbr}</span> ordres effectués <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span>
                                </div> */}
                            </div>
                            {/* <div className='pt-3 color-b'><FontAwesomeIcon icon={faHandshake} style={{fontSize:'35px'}} /></div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card text-danger'>
                        <div className='stat-card-title'>
                            Operations totales non traitées
                            <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                            </span>
                            {/* <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span> */}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                                <div>{!AllOperationPinding?null:AllOperationPinding.length}</div>
                                {/* <div className='stat-sub-title'> <span className='text-success'>
                                    +{_24hOrderNbr}</span> ordres effectués <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span>
                                </div> */}
                            </div>
                            {/* <div className='pt-3 color-b'><FontAwesomeIcon icon={faHandshake} style={{fontSize:'35px'}} /></div> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                    </div>
                </div>
            </div>
            
            <div className='row'>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                          Client a vendu 
                          {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          <span>
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip">
                                  <g transform="translate(256 256)">
                                      <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                          <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path>
                                      </g>
                                  </g>
                              </svg>
                          </span>
                          <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span>
                        </div>
                        <div>
                          <div className='stat-card-content'>
                              <div>{TotalSellMoney} XOF</div>
                          </div>
                            <span className='pointer'> <Button onClick={()=>seeSellingList(TimeFilter)} variant="contained" size="small"> Voir la liste <strong>({OnlySellFiltered})</strong> </Button> </span>
                          </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                          Client a acheté 
                          {/* en 
                          <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          <span>
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip">
                                  <g transform="translate(256 256)">
                                      <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                          <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path>
                                      </g>
                                  </g>
                              </svg>
                          </span>
                          <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span>
                        </div>
                        <div>
                          <div className='stat-card-content'>
                              <div>{TotalBuyMoney} XOF</div>
                          </div>
                            <span className='pointer'> <Button onClick={()=>seeBuyingList(TimeFilter)} variant="contained" size="small"> Voir la liste <strong>({OnlyBuyFiltered})</strong> </Button> </span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='stat-card text-center'>
                        <div className='stat-card-title'>
                          Marge bénéficiaire 
                          {/* en 
                          <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                          </span>
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                            <div className='text-success bolder mt-3' style={{fontSize:'25px'}}> {BenefTotal} XOF </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='row'>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                          Client a vendu par <strong>wave</strong> 
                          {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          <span>
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip">
                                  <g transform="translate(256 256)">
                                      <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                          <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path>
                                      </g>
                                  </g>
                              </svg>
                          </span>
                          {/* <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span> */}
                        </div>
                        <div>
                          <div className='stat-card-content'>
                              <div>{TotalSellWaveMoney} XOF</div>
                          </div>
                            {/* <span className='pointer'> <Button onClick={()=>seeSellingList(TimeFilter)} variant="contained" size="small"> Voir la liste <strong>({OnlySellWaveFiltered})</strong> </Button> </span> */}
                          </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className='stat-card'>
                        <div className='stat-card-title'>
                          Client a acheté par <strong>wave</strong> 
                          {/* en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          <span>
                              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip">
                                  <g transform="translate(256 256)">
                                      <g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)">
                                          <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path>
                                      </g>
                                  </g>
                              </svg>
                          </span>
                          {/* <span className='ml-2 stat-see-card-detail'>Détails <FontAwesomeIcon icon={faArrowRight} /></span> */}
                        </div>
                        <div>
                          <div className='stat-card-content'>
                              <div>{TotalBuyWaveMoney} XOF</div>
                          </div>
                            {/* <span className='pointer'> <Button onClick={()=>seeBuyingList(TimeFilter)} variant="contained" size="small"> Voir la liste <strong>({OnlyBuyWaveFiltered})</strong> </Button> </span> */}
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='stat-card text-center'>
                        <div className='stat-card-title'>
                          {/* Marge bénéficiaire en <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}} className='pointer'>{TimeStatus}</span> */}
                          {/* <span>
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="question-circle" className="svg-inline--fa fa-question-circle fa-w-16 ms-1 text-400" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{transformOrigin: "0.5em 0.5em 0px"}} id="weeklySalesTooltip"><g transform="translate(256 256)"><g transform="translate(0, 0)  scale(0.9375, 0.9375)  rotate(0 0 0)"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm107.244-255.2c0 67.052-72.421 68.084-72.421 92.863V300c0 6.627-5.373 12-12 12h-45.647c-6.627 0-12-5.373-12-12v-8.659c0-35.745 27.1-50.034 47.579-61.516 17.561-9.845 28.324-16.541 28.324-29.579 0-17.246-21.999-28.693-39.784-28.693-23.189 0-33.894 10.977-48.942 29.969-4.057 5.12-11.46 6.071-16.666 2.124l-27.824-21.098c-5.107-3.872-6.251-11.066-2.644-16.363C184.846 131.491 214.94 112 261.794 112c49.071 0 101.45 38.304 101.45 88.8zM298 368c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42z" transform="translate(-256 -256)"></path></g></g></svg>
                          </span> */}
                        </div>
                        <div className='d-flex justify-content-around'>
                            <div className='stat-card-content'>
                              {/* <div className='text-success bolder mt-3' style={{fontSize:'25px'}}> {BenefWaveTotal} XOF </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
              SeeOperation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                    <div className='mr-2'>
                      <span className='bolder'>{_24hOrderNbr} </span> 
                        Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                      <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                      <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue{OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                    <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                    <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div>
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">N°</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">Opération</th>
                        <th scope="col">Crypto</th>
                        <th scope="col">Taux</th>
                        <th scope="col">Quantité</th>
                        <th scope="col">Réseau</th>
                        <th scope="col">Par</th>
                        <th scope="col">Montant</th>
                        <th scope="col">Date</th>
                        <th scope="col">Statut</th>
                        <th scope="col">Détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {GlobalValidated.length > 0 && GlobalValidated.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <span className='color-white'>{index + 1}</span>
                            </th>
                            <td data-title="Email">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>{item.SenderEmail}</span> : 
                              item.operatorAction === 'sel' ? 
                              <span className='text-danger'>{item.SenderEmail}</span> : 
                              null}
                            </td>
                            <td data-title="Contact">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>{item.SenderPhone}</span> : 
                              item.operatorAction === 'sel' ? <span className='text-danger'>{item.SenderPhone}</span> : 
                              null}
                            </td>
                            <td data-title="Opération">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>Achat</span> : 
                              item.operatorAction === 'sel' ? <span className='text-danger'>Vente</span> : 
                              null}
                            </td>
                            <td data-title="Monnaie">
                              {
                                item.serviceName === TRON ? 
                                <img className='images-drapeau mr-2' src={`../${tron}`} alt="" />:
                                item.serviceName === BTC ? 
                                <img className='images-drapeau mr-2' src={`../${btc}`} alt="" />:
                                item.serviceName === LTC ? 
                                <img className='images-drapeau mr-2' src={`../${ltc}`} alt="" />:
                                item.serviceName === USDT ? 
                                <img className='images-drapeau mr-2' src={`../${usdt}`} alt="" />:
                                item.serviceName === DOGE ? 
                                <img className='images-drapeau mr-2' src={`../${doge}`} alt="" />:
                                item.serviceName === BUSD ? 
                                <img className='images-drapeau mr-2' src={`../${busd}`} alt="" />:
                                item.serviceName === SHIB ? 
                                <img className='images-drapeau mr-2' src={`../${shiba}`} alt="" />:
                                item.serviceName === MATIC ? 
                                <img className='images-drapeau mr-2' src={`../${polygonmatic}`} alt="" />:
                                item.serviceName === POLKADOT ? 
                                <img className='images-drapeau mr-2' src={`../${polkadot}`} alt="" />:
                                item.serviceName === ETH ? 
                                <img className='images-drapeau mr-2' src={`../${ethereum}`} alt="" />:
                                item.serviceName === LINK ? 
                                <img className='images-drapeau mr-2' src={`../${chainlink}`} alt="" />:
                                item.serviceName === CARDANO ? 
                                <img className='images-drapeau mr-2' src={`../${cardano}`} alt="" />:
                                item.serviceName === SOLANA ? 
                                <img className='images-drapeau mr-2' src={`../${solana}`} alt="" />:
                                item.serviceName === PAYEER ? 
                                <img className='images-drapeau mr-2' src={`../${payeer}`} alt="" />:
                                item.serviceName === PM ? 
                                <img className='images-drapeau mr-2' src={`../${pm}`} alt="" />:
                                item.serviceName === BNB ? 
                                <img className='images-drapeau mr-2' src={`../${bnb}`} alt="" />:null
                              } 
                            </td>
                            <td data-title="Taux">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>{item.rate} XOF</span> : 
                              item.operatorAction === 'sel' ? <span className='text-danger'>{item.rate} XOF</span> : 
                              null}
                            </td>
                            <td data-title="Quantité">
                              {item.operatorAction === 'buy' ? 
                                <span className='text-success'>
                                {
                                  item.serviceSymbol === "USDT" ? 
                                  <>{(item.converted * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                                  item.serviceSymbol === "trx" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  item.serviceSymbol === "PM" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  item.serviceSymbol === "PAYEER" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                                }</span> : 
                                  item.operatorAction === 'sel' ? <span className='text-danger'>
                                {
                                  item.serviceSymbol === "USDT" ? 
                                  <>{(item.converted * 1).toFixed(2)} $ {item.serviceSymbol}</>:
                                  item.serviceSymbol === "trx" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  item.serviceSymbol === "PM" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  item.serviceSymbol === "PAYEER" ? 
                                  <>{(item.converted * 1).toFixed(2)} {item.serviceSymbol}</>:
                                  <>{(item.converted * 1).toFixed(5)}{' '}{item.serviceSymbol}</>
                                }</span> : 
                              null}
                            </td>
                            <td data-title="Réseau">
                              {item.tetherNet}
                            </td>
                            <td data-title="Par">
                              {
                                item.PaymentMethod === OM ? 
                                <img className='images-drapeau mr-2' src={`../${om}`} alt="" />:
                                item.PaymentMethod === MOMO ? 
                                <img className='images-drapeau mr-2' src={`../${momo}`} alt="" />:
                                item.PaymentMethod === MOOV ? 
                                <img className='images-drapeau mr-2' src={`../${flooz}`} alt="" />:
                                item.PaymentMethod === WAVE ? 
                                <img className='images-drapeau mr-2' src={`../${wave}`} alt="" />:
                                item.PaymentMethod === TM ? 
                                <img className='images-drapeau mr-2' src={`../${tm}`} alt="" />:
                                item.PaymentMethod === FM ? 
                                <img className='images-drapeau mr-2' src={`../${fm}`} alt="" />:
                                item.PaymentMethod === EM ? 
                                <img className='images-drapeau mr-2' src={`../${em}`} alt="" />:null
                              }
                              </td>
                            <td data-title="Montant">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>{item.SenderChange} XOF</span> : 
                              item.operatorAction === 'sel' ? 
                              <span className='text-danger'>{item.SenderChange} XOF</span> :
                              null}
                            </td>
                            <td data-title="Date">
                              {item.operatorAction === 'buy' ? 
                              <span className='text-success'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))+2}{item.updatedAt.slice(13, 19)}</span> : 
                              item.operatorAction === 'sel' ? 
                              <span className='text-danger'>{item.updatedAt.slice(0, 10)} à{' '} {Number(item.updatedAt.slice(11,13))+2}{item.updatedAt.slice(13, 19)}</span> : 
                              null}
                            </td>
                            <td data-title="Statut">
                            <CheckCircleIcon sx={{color:"green"}} />
                            </td>
                            <td data-title="Détails" className='pointer-details'>
                            <span className="details" ><a href="#nkab-popup" onClick={() => detailOption(item.ID)}><ReadMoreIcon/></a></span>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <div>
                    <div className='text-center p-2'><span className="stat-btn-see-more">Voir plus <FontAwesomeIcon icon={faArrowRight} /></span></div>
                  </div>
                </div>
              </div> 
              :See2Operation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'>{_24hOrderNbr} </span> 
                      Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue{OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}</span>
                        <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                {/* <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div> */}
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Voir détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mails.length > 0 && mails.map((item, index) => (
                          <tr key={index}>
                            <th scope="row">
                              <span className='color-white'>{index + 1}</span>
                              <span className='text-success'>{item}</span>
                            </th>
                            <td data-title="Voir détails">
                            <a href="#nkab-popup" onClick={() => notifHandler(item)}><span className='pointer'><PreviewIcon/></span></a>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div> 
              :See4Operation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'>{_24hOrderNbr} </span> 
                      Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                      <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                      </span>
                      <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue {OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                {/* <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div> */}
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Voir détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mails2.length > 0 && mails2.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <span className='color-white'>{index + 1}</span>
                            <span className='text-success'>{item}</span>
                          </th>
                          <td data-title="Voir détails">
                            <a href="#nkab-popup" onClick={() => notifHandler(item)}><span className='pointer'><PreviewIcon/></span></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> 
              :See6Operation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'>{_24hOrderNbr} </span> 
                      Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                      <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                      </span>
                      <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue {OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                {/* <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div> */}
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Voir détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mails3.length > 0 && mails3.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <span className='color-white'>{index + 1}</span>
                            <span className='text-success'>{item}</span>
                          </th>
                          <td data-title="Voir détails">
                            <a href="#nkab-popup" onClick={() => notifHandler(item)}><span className='pointer'><PreviewIcon/></span></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> 
              :See8Operation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'>{_24hOrderNbr} </span> 
                      Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                      <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                      </span>
                      <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue {OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                {/* <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div> */}
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Voir détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mails4.length > 0 && mails4.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <span className='color-white'>{index + 1}</span>
                            <span className='text-success'>{item}</span>
                          </th>
                          <td data-title="Voir détails">
                            <a href="#nkab-popup" onClick={() => notifHandler(item)}><span className='pointer'><PreviewIcon/></span></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> 
              :See10Operation ? 
              <div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'>{_24hOrderNbr} </span> 
                      Opération{_24hOrderNbr > 1 ? "s":""} reussie{_24hOrderNbr > 1 ? "s":""} en 
                      <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                      </span>
                      <span className={`pointer nkab-stat-see ${SeeOperation ? "color-or":""}`} onClick={seeOperations}> <PreviewIcon/></span>
                    </div> 
                    <span>|</span>
                    <div className='ml-2 mr-2'>
                      <span className='bolder'>{OnlyBuyFiltered} </span> 
                        Uniquement Achatée{OnlyBuyFiltered > 1 ? "s":""}
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeBuying ? "color-or":""}`} onClick={seeBuyingList}> <PreviewIcon/></span>
                    </div>
                    <span>|</span>
                    <div className='ml-2'>
                      <span className='bolder'>{OnlySellFiltered} </span> 
                        Uniquement Vendue {OnlySellFiltered > 1 ? "s":""} en
                        <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}> {TimeStatus}
                        </span>
                        <span className={`pointer nkab-stat-see ${SeeSelling ? "color-or":""}`} onClick={seeSellingList}> <PreviewIcon/></span>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails.length} </span> 
                      Personnes ayant effectué au moins (02) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See2Operation ? "color-or":""}`} onClick={see2Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails2.length} </span> 
                      Personnes ayant effectué au moins (04) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See4Operation ? "color-or":""}`} onClick={see4Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails3.length} </span> 
                      Personnes ayant effectué au moins (06) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See6Operation ? "color-or":""}`} onClick={see6Operations}> <PreviewIcon/></span>
                  </div> 
                  <span>|</span>
                  <div className='ml-2'>
                    <span className='bolder'> {mails4.length} </span> 
                      Personnes ayant effectué au moins (08) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>        {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See8Operation ? "color-or":""}`} onClick={see8Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                <div className='mt-4 d-flex justify-content-center'>
                  <div className='mr-2'>
                    <span className='bolder'> {mails5.length} </span> 
                      Personnes ayant effectué au moins (10) opérations en 
                    <span style={{fontSize:'10px', backgroundColor:'lightgrey', padding:'0px 5px 0px 5px', borderRadius:'5px', color:'#000000'}}>     {TimeStatus}
                    </span>
                    <span className={`pointer nkab-stat-see ${See10Operation ? "color-or":""}`} onClick={see10Operations}> <PreviewIcon/></span>
                  </div> 
                </div>
                {/* <div><span className='pointer'><Button onClick={PdfDucument} variant="contained" size="small"> Imprimer </Button></span></div> */}
                <div className="__op-body-table">
                  <table className='__op-responsive-table'>
                    <thead>
                      <tr>
                        <th scope="col">Email</th>
                        <th scope="col">Voir détails</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mails5.length > 0 && mails5.map((item, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <span className='color-white'>{index + 1}</span>
                            <span className='text-success'>{item}</span>
                          </th>
                          <td data-title="Voir détails">
                            <a href="#nkab-popup" onClick={() => notifHandler(item)}><span className='pointer'><PreviewIcon/></span></a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div> 
              : null
            }
        </div>
        {
          FirstDetails?
          <div>
            <div id="closed"></div>
            <div className="nkab-popup-wrapper" id="nkab-popup">
            <div className="nkab-popup-container">
            <center>
              <img className='nkab-up-img' src="https://tinypic.host/images/2023/02/16/pngtree-television-news-background-image_909008.jpg" width="400" alt=''/><br/><br/>
              <div>
                <div>ID : <strong className='text-warning'>{opid}</strong> </div>
                <div>{op==="sel"?"Vente":op==="buy"?"Achat":null} de <strong className='text-success'>{qt} {opname}</strong> par <strong>{name}</strong> sous l'adresse : <strong className='text-info'>{opaddress}</strong> au numéro de téléphone : <strong>{phone}</strong>.</div>
                <div>Moyen de paiement : <strong className='text-info'>{methodpayment}</strong></div>
                <div>
                  <span>Statut : </span>
                  {
                    validated === "validé" ? <span className='text-success'>Validée avec succès !</span> : validated === "non validé"? <span className='text-danger'>Non traitée</span> : validated === "annulé"? <span className='text-danger'>échec de l'opération</span> :  null
                  } 
                </div>
              </div>
            </center>
            <a className="nkab-popup-close" href="#closed">X</a>
            </div>
            </div>
          </div>:
        <div>
          <div id="closed"></div>
          <div className="nkab-popup-wrapper" id="nkab-popup">
          <div className="nkab-popup-container">
          <center>
            <img className='nkab-up-img' src="https://tinypic.host/images/2023/02/16/pngtree-television-news-background-image_909008.jpg" width="400" alt=''/><br/><br/>
            <div>
              <p>
                <span>
                  {
                    UnicUser.countryName === ci ?
                    <img className='images-drapeau mr-2' src="../../images/ci_logo.png" alt="ci logo" />:
                    UnicUser.countryName === sen ?
                    <img className='images-drapeau mr-2' src="../../images/sen_logo.png" alt="sen logo" />:
                    UnicUser.countryName === burk ?
                    <img className='images-drapeau mr-2' src="../../images/burk_logo.png" alt="burk logo" />:
                    UnicUser.countryName === benin ?
                    <img className='images-drapeau mr-2' src="../../images/benin_logo.png" alt="benin logo" />:
                    UnicUser.countryName === togo ?
                    <img className='images-drapeau mr-2' src="../../images/togo_logo.png" alt="togo logo" />:
                    UnicUser.countryName === mali ?
                    <img className='images-drapeau mr-2' src="../../images/mali_logo.png" alt="mali logo" />:
                    UnicUser.countryName === niger ?
                    <img className='images-drapeau mr-2' src="../../images/niger_logo.png" alt="niger logo" />:null
                  }
                </span>
                <strong className='text-warning uppercase'>{UnicUser.countryName}</strong> 
              </p>
              <p><strong className='text-warning uppercase'>{UnicUser.name} {UnicUser.lastName}</strong> </p>
              <p>Téléphone : <strong className='text-info'>({UnicUser.country}) {UnicUser.telNumber}</strong></p>
              <p>Email : <strong className='text-info'>{UnicUser.email}</strong></p>
              <p>De <strong className='text-info'>{UnicUser.borningWay}</strong></p>
              <p>Résidant à <strong className='text-info'>{UnicUser.borningWay}</strong></p>
              <p>Hadéré dépuis <strong className='text-info'>{UnicUser.updatedAt}</strong></p>
            </div>
          </center>
          <a className="nkab-popup-close" href="#closed">X</a>
          </div>
          </div>
        </div>
        }
    </div>
  )
}

export default AdminStat