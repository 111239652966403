import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faPen } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import LoadingBox from '../components/LoadingBox'
import ChatCustomer from '../components/ChatCustomer'
import RecentSuccessOperation from '../components/RecentSuccessOperation'
import { uid } from 'uid'
import StatisticOfOperations from '../components/StatisticOfOperations'
import ServiceDisponible from '../components/ServiceDisponible'
import ReactSwitch from 'react-switch'
import CampaignIcon from '@mui/icons-material/Campaign';
import BottomNavBar from '../components/BottomNavBar'
import coinGecko from '../api/coinGecko'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import SmsFailedIcon from '@mui/icons-material/SmsFailed';


export default function BuyAndSellScreen(props) {

  const LineLink = true
  // const LineLink = false

  const Logged              = localStorage.getItem('userStatus')
  const userLastName        = localStorage.getItem('userLastName')
  const userName            = localStorage.getItem('userName')
  const SenderCountry       = localStorage.getItem('SenderCountry')
  const Country             = localStorage.getItem('Country')
  var   _ProWallet          = localStorage.getItem('_ProWallet')

  if(Logged === 'Logged'){
    if (_ProWallet === 'true') {

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
    }
  }
  
  if(Logged === 'Logout'){

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }

  localStorage.removeItem("nkab_rge_reference")
  localStorage.removeItem("nkab_rge_token")
  localStorage.removeItem("nkab_rge_id")
  localStorage.removeItem("hub2token")
  localStorage.removeItem("hub2id")
  localStorage.removeItem("LastStatus")
  localStorage.removeItem("Trowed")

  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);

  function handleClose () {
    setOpen2(false);

    if(LineLink){
        window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
    } else {
        window.location.replace("http://localhost:3000/sell-or-buy-crypto");
    }

  };

  const historyOrderRef2 = props.location.hash.slice(1, 6)

  useEffect(() => {
    if (historyOrderRef2 === "echec") {
      setOpen2(true);
    }
  }, [
    historyOrderRef2,
  ])

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1, setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1, setStatus1] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])
  

  const [operatorList,                  setOperatorList             ] = useState([])
  const [OMState,                       setOMState                  ] = useState(false)
  const [MOMOState,                     setMOMOState                ] = useState(false)
  const [MOOVState,                     setMOOVState                ] = useState(false)
  const [WAVEState,                     setWAVEState                ] = useState(false)

  const [interChoice,                   setInterChoice              ] = useState(false)
  const [rateCondition,                 setRateCondition            ] = useState()
  const [contact,                       setContact                  ] = useState([])
  const [tetherActionSelector,          setTetherActionSelector     ] = useState(false)
  const [busdActionSelector,            setBusdActionSelector       ] = useState(false)
  const [shibaActionSelector,           setShibaActionSelector      ] = useState(false)
  const [solanaActionSelector,          setSolanaActionSelector     ] = useState(false)
  const [cardanoActionSelector,         setCardanoActionSelector    ] = useState(false)
  const [polkadotActionSelector,        setPolkadotActionSelector   ] = useState(false)
  const [maticActionSelector,           setMaticActionSelector      ] = useState(false)
  const [linkActionSelector,            setLinkActionSelector       ] = useState(false)
  const [operatorNames,                 setOperatorNames            ] = useState()
  const [MinOrMax,                      setMinOrMax                 ] = useState(false)
  const [itemsOpener,                   setItemsOpener              ] = useState(false)
  const [itemsOneOpener,                setItemsOneOpener           ] = useState(false)
  const [checked,                       setChecked                  ] = useState(false)
  const [NkabSpinner,                   setNkabSpinner              ] = useState(false)
  const [userEmail,                     setUserEmail                ] = useState("")
  const [UserID,                        setUserID                   ] = useState("")
  const [User,                          setUser                     ] = useState([])
  const [adr_Coin,                      setAdr_Coin                 ] = useState([])
  const [Memo,                          setMemo                     ] = useState("")
  const [UnicUserStop,                  setUnicUserStop             ] = useState(false)
  const [services,                      setServices                 ] = useState([])
  const [IDCliked,                      setIDCliked                 ] = useState()
  const [ItemStatusCliked,              setItemStatusCliked         ] = useState("")
  const [OmOptionClicked,               setOmOptionClicked          ] = useState("")
  const [MomoOptionClicked,             setMomoOptionClicked        ] = useState("")
  const [FloozOptionClicked,            setFloozOptionClicked       ] = useState("")
  const [FmOptionClicked,               setFmOptionClicked          ] = useState("")
  const [TmOptionClicked,               setTmOptionClicked          ] = useState("")
  const [WaveOptionClicked,             setWaveOptionClicked        ] = useState("")
  const [OpSelcted,                     setOpSelcted                ] = useState(false)
  const [SellInput,                     setSellInput                ] = useState("")

  const [BTCResult,                     setBTCResult                ] = useState('')
  const [TRONResult,                    setTRONResult               ] = useState('')
  const [LTCResult,                     setLTCResult                ] = useState('')
  const [USDTResult,                    setUSDTResult               ] = useState('')
  const [PMResult,                      setPMResult                 ] = useState('')
  const [DOGEResult,                    setDOGEResult               ] = useState('')
  const [BUSDResult,                    setBUSDResult               ] = useState('')
  const [SHIBAResult,                   setSHIBAResult              ] = useState('')
  const [POLYGONMATICResult,            setPOLYGONMATICResult       ] = useState('')
  const [ETHEREUMResult,                setETHEREUMResult           ] = useState('')
  const [POKADOTResult,                 setPOKADOTResult            ] = useState('')
  const [CHAINLINKResult,               setCHAINLINKResult          ] = useState('')
  const [CARDANOResult,                 setCARDANOResult            ] = useState('')
  const [SOLANAResult,                  setSOLANAResult             ] = useState('')
  const [BNBResult,                     setBNBResult                ] = useState('')
  const [TONResult,                     setTONResult                ] = useState('')

  const [BTCMaxLimite,                  setBTCMaxLimite             ] = useState('')
  const [TRONMaxLimite,                 setTRONMaxLimite            ] = useState('')
  const [LTCMaxLimite,                  setLTCMaxLimite             ] = useState('')
  const [USDTMaxLimite,                 setUSDTMaxLimite            ] = useState('')
  const [PMMaxLimite,                   setPMMaxLimite              ] = useState('')
  const [DOGEMaxLimite,                 setDOGEMaxLimite            ] = useState('')
  const [BUSDMaxLimite,                 setBUSDMaxLimite            ] = useState('')
  const [SHIBAMaxLimite,                setSHIBAMaxLimite           ] = useState('')
  const [POLYGONMATICMaxLimite,         setPOLYGONMATICMaxLimite    ] = useState('')
  const [ETHEREUMMaxLimite,             setETHEREUMMaxLimite        ] = useState('')
  const [POKADOTMaxLimite,              setPOKADOTMaxLimite         ] = useState('')
  const [CHAINLINKMaxLimite,            setCHAINLINKMaxLimite       ] = useState('')
  const [CARDANOMaxLimite,              setCARDANOMaxLimite         ] = useState('')
  const [SOLANAMaxLimite,               setSOLANAMaxLimite          ] = useState('')
  const [BNBMaxLimite,                  setBNBMaxLimite             ] = useState('')
  const [TONMaxLimite,                  setTONMaxLimite             ] = useState('')

  const [BNBStop, setBNBStop] = useState(false)
  const [BSCprice, setBSCprice] = useState([])

  const [BTCStop, setBTCStop] = useState(false)
  const [BTCprice, setBTCprice] = useState([])

  const [TRONStop, setTRONStop] = useState(false)
  const [TRONprice, setTRONprice] = useState([])

  const [USDTStop, setUSDTStop] = useState(false)
  const [USDTprice, setUSDTprice] = useState([])

  const [LINKStop, setLINKStop] = useState(false)
  const [CHAINLINKprice, setCHAINLINKprice] = useState([])

  const [MATICStop, setMATICStop] = useState(false)
  const [POLYGONMATICprice, setPOLYGONMATICprice] = useState([])

  const [DOTEStop, setDOTEStop] = useState(false)
  const [POKADOTprice, setPOKADOTprice] = useState([])

  const [BUSDStop, setBUSDStop] = useState(false)
  const [BUSDprice, setBUSDprice] = useState([])

  const [PMStop, setPMStop] = useState(false)
  const [PMprice, setPMprice] = useState([])

  const [SOLStop, setSOLStop] = useState(false)
  const [SOLANAprice, setSOLANAprice] = useState([])

  const [ADAStop, setADAStop] = useState(false)
  const [CARDANOprice, setCARDANOprice] = useState([])

  const [ETHStop, setETHStop] = useState(false)
  const [ETHEREUMprice, setETHEREUMprice] = useState([])

  const [SHIBAStop, setSHIBAStop] = useState(false)
  const [SHIBAprice, setSHIBAprice] = useState([])

  const [DOGEStop, setDOGEStop] = useState(false)
  const [DOGEprice, setDOGEprice] = useState([])

  const [LTCStop, setLTCStop] = useState(false)
  const [LTCprice, setLTCprice] = useState([])

  const [TONStop, setTONStop  ] = useState(false)
  const [TONprice, setTONprice ] = useState([])



  const [BNBRateStop, setBNBRateStop] = useState(false)
  const [NT_rateBNB, setNT_rateBNB] = useState([])

  const [BTCRateStop, setBTCRateStop] = useState(false)
  const [NT_rateBTC, setNT_rateBTC] = useState([])

  const [TRONRateStop, setTRONRateStop] = useState(false)
  const [NT_rateTRON, setNT_rateTRON] = useState([])

  const [USDTRateStop, setUSDTRateStop] = useState(false)
  const [NT_rateUSDT, setNT_rateUSDT] = useState([])

  const [LINKRateStop, setLINKRateStop] = useState(false)
  const [NT_rateLINK, setNT_rateLINK] = useState([])

  const [MATICRateStop, setMATICRateStop] = useState(false)
  const [NT_rateMATIC, setNT_rateMATIC] = useState([])

  const [DOTERateStop, setDOTERateStop] = useState(false)
  const [NT_rateDOTE, setNT_rateDOTE] = useState([])

  const [BUSDRateStop, setBUSDRateStop] = useState(false)
  const [NT_rateBUSD, setNT_rateBUSD] = useState([])

  const [PMRateStop, setPMRateStop] = useState(false)
  const [NT_ratePM, setNT_ratePM] = useState([])

  const [SOLRateStop, setSOLRateStop] = useState(false)
  const [NT_rateSOL, setNT_rateSOL] = useState([])

  const [ADARateStop, setADARateStop] = useState(false)
  const [NT_rateADA, setNT_rateADA] = useState([])

  const [ETHRateStop, setETHRateStop] = useState(false)
  const [NT_rateETH, setNT_rateETH] = useState([])

  const [SHIBARateStop, setSHIBARateStop] = useState(false)
  const [NT_rateSHIBA, setNT_rateSHIBA] = useState([])

  const [DOGERateStop, setDOGERateStop] = useState(false)
  const [NT_rateDOGE, setNT_rateDOGE] = useState([])

  const [LTCRateStop, setLTCRateStop] = useState(false)
  const [NT_rateLTC, setNT_rateLTC] = useState([])

  const [TONRateStop, setTONRateStop  ] = useState(false)
  const [NT_rateTON, setNT_rateTON ] = useState([])

  const [MBDOGERateStop, setMBDOGERateStop  ] = useState(false)
  const [NT_rateMBDOGE, setNT_rateMBDOGE ] = useState([])


  const [BNBChargeStop, setBNBChargeStop] = useState(false)
  const [NT_ChargeBNB, setNT_ChargeBNB] = useState([])

  const [BTCChargeStop, setBTCChargeStop] = useState(false)
  const [NT_ChargeBTC, setNT_ChargeBTC] = useState([])

  const [TRONChargeStop, setTRONChargeStop] = useState(false)
  const [NT_ChargeTRON, setNT_ChargeTRON] = useState([])

  const [USDTChargeStop, setUSDTChargeStop] = useState(false)
  const [NT_ChargeUSDT, setNT_ChargeUSDT] = useState([])

  const [USDTCharge2Stop, setUSDTCharge2Stop] = useState(false)
  const [NT_Charge2USDT, setNT_Charge2USDT] = useState([])

  const [LINKChargeStop, setLINKChargeStop] = useState(false)
  const [NT_ChargeLINK, setNT_ChargeLINK] = useState([])

  const [MATICChargeStop, setMATICChargeStop] = useState(false)
  const [NT_ChargeMATIC, setNT_ChargeMATIC] = useState([])

  const [DOTEChargeStop, setDOTEChargeStop] = useState(false)
  const [NT_ChargeDOTE, setNT_ChargeDOTE] = useState([])

  const [BUSDChargeStop, setBUSDChargeStop] = useState(false)
  const [NT_ChargeBUSD, setNT_ChargeBUSD] = useState([])

  const [PMChargeStop, setPMChargeStop] = useState(false)
  const [NT_ChargePM, setNT_ChargePM] = useState([])

  const [SOLChargeStop, setSOLChargeStop] = useState(false)
  const [NT_ChargeSOL, setNT_ChargeSOL] = useState([])

  const [ADAChargeStop, setADAChargeStop] = useState(false)
  const [NT_ChargeADA, setNT_ChargeADA] = useState([])

  const [ETHChargeStop, setETHChargeStop] = useState(false)
  const [NT_ChargeETH, setNT_ChargeETH] = useState([])

  const [SHIBAChargeStop, setSHIBAChargeStop] = useState(false)
  const [NT_ChargeSHIBA, setNT_ChargeSHIBA] = useState([])

  const [DOGEChargeStop, setDOGEChargeStop] = useState(false)
  const [NT_ChargeDOGE, setNT_ChargeDOGE] = useState([])

  const [LTCChargeStop, setLTCChargeStop] = useState(false)
  const [NT_ChargeLTC, setNT_ChargeLTC] = useState([])

  const [TONChargeStop, setTONChargeStop  ] = useState(false)
  const [NT_ChargeTON, setNT_ChargeTON ] = useState([])

  const [MBDOGEChargeStop, setMBDOGEChargeStop  ] = useState(false)
  const [NT_ChargeMBDOGE, setNT_ChargeMBDOGE ] = useState([])



  const [BNBRate2Stop, setBNBRate2Stop] = useState(false)
  const [NT_rate2BNB, setNT_rate2BNB] = useState([])

  const [BTCRate2Stop, setBTCRate2Stop] = useState(false)
  const [NT_rate2BTC, setNT_rate2BTC] = useState([])

  const [TRONRate2Stop, setTRONRate2Stop] = useState(false)
  const [NT_rate2TRON, setNT_rate2TRON] = useState([])

  const [USDTRate2Stop, setUSDTRate2Stop] = useState(false)
  const [NT_rate2USDT, setNT_rate2USDT] = useState([])

  const [LINKRate2Stop, setLINKRate2Stop] = useState(false)
  const [NT_rate2LINK, setNT_rate2LINK] = useState([])

  const [MATICRate2Stop, setMATICRate2Stop] = useState(false)
  const [NT_rate2MATIC, setNT_rate2MATIC] = useState([])

  const [DOTERate2Stop, setDOTERate2Stop] = useState(false)
  const [NT_rate2DOTE, setNT_rate2DOTE] = useState([])

  const [BUSDRate2Stop, setBUSDRate2Stop] = useState(false)
  const [NT_rate2BUSD, setNT_rate2BUSD] = useState([])

  const [PMRate2Stop, setPMRate2Stop] = useState(false)
  const [NT_rate2PM, setNT_rate2PM] = useState([])

  const [SOLRate2Stop, setSOLRate2Stop] = useState(false)
  const [NT_rate2SOL, setNT_rate2SOL] = useState([])

  const [ADARate2Stop, setADARate2Stop] = useState(false)
  const [NT_rate2ADA, setNT_rate2ADA] = useState([])

  const [ETHRate2Stop, setETHRate2Stop] = useState(false)
  const [NT_rate2ETH, setNT_rate2ETH] = useState([])

  const [SHIBARate2Stop, setSHIBARate2Stop] = useState(false)
  const [NT_rate2SHIBA, setNT_rate2SHIBA] = useState([])

  const [DOGERate2Stop, setDOGERate2Stop] = useState(false)
  const [NT_rate2DOGE, setNT_rate2DOGE] = useState([])

  const [LTCRate2Stop, setLTCRate2Stop] = useState(false)
  const [NT_rate2LTC, setNT_rate2LTC] = useState([])

  const [TONRate2Stop, setTONRate2Stop  ] = useState(false)
  const [NT_rate2TON, setNT_Rate2TON ] = useState([])

  useEffect(()=>{
    const fetchOperators = async () => {
    try {
        const { data } = await axios.get(
          '/api/v1/get-money-operators',
        )
        if(data.length > 0){
          setOperatorList(data)
        }
    } catch (error) {}

  }
  fetchOperators()
  },[])

  
  useEffect(() => {
  
    if (operatorList.length > 0) {
      const operatorListFilted = operatorList.filter((obj) => {
        return obj.operatorName === "Orange Money"
      })
      if(operatorListFilted.length > 0){
        const omFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 1
        })
        if(omFilted.length > 0){
          setOMState(true)
        }
      }
      if(operatorListFilted.length > 0){
        const omFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 0
        })
        if(omFilted.length > 0){
          setOMState(false)
        }
      }
    }

  }, [operatorList])


  
  useEffect(() => {
  
    if (operatorList.length > 0) {
      const operatorListFilted = operatorList.filter((obj) => {
        return obj.operatorName === "Mobile Money"
      })
      if(operatorListFilted.length > 0){
        const momoFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 1
        })
        if(momoFilted.length > 0){
          setMOMOState(true)
        }
      }
      if(operatorListFilted.length > 0){
        const momoFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 0
        })
        if(momoFilted.length > 0){
          setMOMOState(false)
        }
      }
    }

  }, [operatorList])


  
  useEffect(() => {
  
    if (operatorList.length > 0) {
      const operatorListFilted = operatorList.filter((obj) => {
        return obj.operatorName === "Moov Money"
      })
      if(operatorListFilted.length > 0){
        const moovFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 1
        })
        if(moovFilted.length > 0){
          setMOOVState(true)
        }
      }
      if(operatorListFilted.length > 0){
        const moovFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 0
        })
        if(moovFilted.length > 0){
          setMOOVState(false)
        }
      }
    }

  }, [operatorList])


  
  useEffect(() => {
  
    if (operatorList.length > 0) {
      const operatorListFilted = operatorList.filter((obj) => {
        return obj.operatorName === "Wave"
      })
      if(operatorListFilted.length > 0){
        const maveFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 1
        })
        if(maveFilted.length > 0){
          setWAVEState(true)
        }
      }
      if(operatorListFilted.length > 0){
        const maveFilted = operatorListFilted.filter((obj) => {
          return obj.isActived === 0
        })
        if(maveFilted.length > 0){
          setWAVEState(false)
        }
      }
    }

  }, [operatorList])

  const BTCname = "Bitcoin"
  const BTCSymbol = "btc"

  const TRONname = "TRON"
  const TRONSymbol = "trx"

  const LTCname = "Litecoin"
  const LTCSymbol = "ltc"

  const DOGEname = "Dogecoin"
  const DOGESymbol = "doge"

  const BSCname = "Binance Smart Chain"

  const PMname = "Perfect Money"
  const PMSymbol = "PM"

  const BUSDname = "Binance USD"
  const BUSDSymbol = "busd"

  const SHIBAname = "Shiba Inu"
  const SHIBASymbol = "shib"

  const POLYGONMATICname = "Polygon"
  const POLYGONMATICSymbol = "matic"

  const ETHEREUMname = "Ethereum"
  const ETHEREUMSymbol = "eth"

  const POKADOTname = "Polkadot"
  const POKADOTSymbol = "dot"

  const CHAINLINKname = "Chainlink"
  const CHAINLINKSymbol = "link"

  const CARDANOname = "Cardano"
  const CARDANOSymbol = "ada"

  const SOLANAname = "Solana"
  const SOLANASymbol = "sol"
  
  const TONname = "Toncoin"
  const TONSymbol = "ton"

    
  useEffect(()=>{

      const fetchData = async () => {

          if(!BTCStop){

              try {
                  const res = await axios.get('/api/nt-btc')

                  if(res.data[0].ID){
                    setBTCprice(res.data[0].btc)
                    setBTCStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BTCStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!TRONStop){

              try {
                  const res = await axios.get('/api/nt-tron')

                  if(res.data[0].ID){
                    setTRONprice(res.data[0].tron)
                    setTRONStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TRONStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LTCStop){

              try {
                  const res = await axios.get('/api/nt-ltc')

                  if(res.data[0].ID){
                    setLTCprice(res.data[0].ltc)
                    setLTCStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LTCStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!USDTStop){

              try {
                  const res = await axios.get('/api/nt-usdt')

                  if(res.data[0].ID){
                    setUSDTprice(res.data[0].usdt)
                    setUSDTStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[USDTStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOGEStop){

              try {
                  const res = await axios.get('/api/nt-doge')

                  if(res.data[0].ID){
                    setDOGEprice(res.data[0].doge)
                    setDOGEStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOGEStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BUSDStop){

              try {
                  const res = await axios.get('/api/nt-busd')

                  if(res.data[0].ID){
                    setBUSDprice(res.data[0].busd)
                    setBUSDStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BUSDStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SHIBAStop){

              try {
                  const res = await axios.get('/api/nt-shiba')

                  if(res.data[0].ID){
                    setSHIBAprice(res.data[0].shiba)
                    setSHIBAStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SHIBAStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!MATICStop){

              try {
                  const res = await axios.get('/api/nt-matic')

                  if(res.data[0].ID){
                    setPOLYGONMATICprice(res.data[0].matic)
                    setMATICStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[MATICStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ETHStop){

              try {
                  const res = await axios.get('/api/nt-eth')

                  if(res.data[0].ID){
                    setETHEREUMprice(res.data[0].eth)
                    setETHStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ETHStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!LINKStop){

              try {
                  const res = await axios.get('/api/nt-link')

                  if(res.data[0].ID){
                    setCHAINLINKprice(res.data[0].link)
                    setLINKStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[LINKStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!DOTEStop){

              try {
                  const res = await axios.get('/api/nt-dote')

                  if(res.data[0].ID){
                    setPOKADOTprice(res.data[0].dote)
                    setDOTEStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[DOTEStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!ADAStop){

              try {
                  const res = await axios.get('/api/nt-ada')

                  if(res.data[0].ID){
                    setCARDANOprice(res.data[0].ada)
                    setADAStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[ADAStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!SOLStop){

              try {
                  const res = await axios.get('/api/nt-sol')

                  if(res.data[0].ID){
                    setSOLANAprice(res.data[0].sol)
                    setSOLStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[SOLStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!PMStop){

              try {
                  const res = await axios.get('/api/nt-pm')

                  if(res.data[0].ID){
                      setPMprice(res.data[0].pm)
                      setPMStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[PMStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!BNBStop){

              try {
                  const res = await axios.get('/api/nt-bnb')

                  if(res.data[0].ID){
                    setBSCprice(res.data[0].bnb)
                    setBNBStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[BNBStop])
  
  useEffect(()=>{

      const fetchData = async () => {

          if(!TONStop){

              try {
                  const res = await axios.get('/api/nt-ton')

                  if(res.data[0].ID){
                    setTONprice(res.data[0].ton)
                    setTONStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[TONStop])

  useEffect(()=>{

    const fetchDada12 = async () => {
      if(!UnicUserStop){
        try {
          const { data } = await axios.get('/api/v1/get-users')

          setUserID(localStorage.getItem('userID'))

          if(data.length > 0 && UserID){
            const userGetted = data.filter((obj) => {
              return obj.ID === Number(UserID)
            })

            if(userGetted.length > 0){
              setUser(userGetted[0])
              if(User.ID){
                setUserEmail(User.email)
              }
              setUnicUserStop(true)
            }

          }
        } catch (error) {}
      }
      }
    fetchDada12()

  },[UserID, User, UnicUserStop])
    
  useEffect(()=>{

    const fetchData = async () => {

        if(!BTCRateStop){

            try {
                const res = await axios.get('/api/nt-ratebtc')

                if(res.data[0].ID){
                    setNT_rateBTC(res.data[0].btc)
                    setBTCRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BTCRateStop])

useEffect(()=>{

  const fetchData = async () => {

      if(!BTCChargeStop){

          try {
              const res = await axios.get('/api/nt-chargebtc')

              if(res.data[0].ID){
                  setNT_ChargeBTC(res.data[0].btc)
                  setBTCChargeStop(true)
              }
          } catch (error) {
          }
      }
  }
  fetchData()

},[BTCChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!BTCRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2btc')

                if(res.data[0].ID){
                    setNT_rate2BTC(res.data[0].btc)
                    setBTCRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BTCRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!TRONRateStop){

            try {
                const res = await axios.get('/api/nt-ratetron')

                if(res.data[0].ID){
                    setNT_rateTRON(res.data[0].tron)
                    setTRONRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TRONRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!TRONChargeStop){

            try {
                const res = await axios.get('/api/nt-chargetron')

                if(res.data[0].ID){
                    setNT_ChargeTRON(res.data[0].tron)
                    setTRONChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TRONChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!TRONRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2tron')

                if(res.data[0].ID){
                    setNT_rate2TRON(res.data[0].tron)
                    setTRONRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TRONRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!LTCRateStop){

            try {
                const res = await axios.get('/api/nt-rateltc')

                if(res.data[0].ID){
                    setNT_rateLTC(res.data[0].ltc)
                    setLTCRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LTCRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!LTCChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeltc')

                if(res.data[0].ID){
                    setNT_ChargeLTC(res.data[0].ltc)
                    setLTCChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LTCChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!LTCRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2ltc')

                if(res.data[0].ID){
                    setNT_rate2LTC(res.data[0].ltc)
                    setLTCRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LTCRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!USDTRateStop){

            try {
                const res = await axios.get('/api/nt-rateusdt')

                if(res.data[0].ID){
                    setNT_rateUSDT(res.data[0].usdt)
                    setUSDTRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[USDTRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!USDTChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeusdt')

                if(res.data[0].ID){
                    setNT_ChargeUSDT(res.data[0].usdt)
                    setUSDTChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[USDTChargeStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!USDTCharge2Stop){

            try {
                const res = await axios.get('/api/nt-chargeusdt2')

                if(res.data[0].ID){
                    setNT_Charge2USDT(res.data[0].usdt)
                    setUSDTCharge2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[USDTCharge2Stop])

useEffect(()=>{

    const fetchData = async () => {

        if(!USDTRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2usdt')

                if(res.data[0].ID){
                    setNT_rate2USDT(res.data[0].usdt)
                    setUSDTRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[USDTRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!DOGERateStop){

            try {
                const res = await axios.get('/api/nt-ratedoge')

                if(res.data[0].ID){
                    setNT_rateDOGE(res.data[0].doge)
                    setDOGERateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOGERateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!DOGEChargeStop){

            try {
                const res = await axios.get('/api/nt-chargedoge')

                if(res.data[0].ID){
                    setNT_ChargeDOGE(res.data[0].doge)
                    setDOGEChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOGEChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!DOGERate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2doge')

                if(res.data[0].ID){
                    setNT_rate2DOGE(res.data[0].doge)
                    setDOGERate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOGERate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!BUSDChargeStop){

            try {
                const res = await axios.get('/api/nt-chargebusd')

                if(res.data[0].ID){
                    setNT_ChargeBUSD(res.data[0].busd)
                    setBUSDChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BUSDChargeStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!BUSDRateStop){

            try {
                const res = await axios.get('/api/nt-ratebusd')

                if(res.data[0].ID){
                    setNT_rateBUSD(res.data[0].busd)
                    setBUSDRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BUSDRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!BUSDChargeStop){

            try {
                const res = await axios.get('/api/nt-chargebusd')

                if(res.data[0].ID){
                    setNT_ChargeBUSD(res.data[0].busd)
                    setBUSDChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BUSDChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!BUSDRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2busd')

                if(res.data[0].ID){
                    setNT_rate2BUSD(res.data[0].busd)
                    setBUSDRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BUSDRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!SHIBARateStop){

            try {
                const res = await axios.get('/api/nt-rateshiba')

                if(res.data[0].ID){
                    setNT_rateSHIBA(res.data[0].shiba)
                    setSHIBARateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SHIBARateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!SHIBAChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeshiba')

                if(res.data[0].ID){
                    setNT_ChargeSHIBA(res.data[0].shiba)
                    setSHIBAChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SHIBAChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!SHIBARate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2shiba')

                if(res.data[0].ID){
                    setNT_rate2SHIBA(res.data[0].shiba)
                    setSHIBARate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SHIBARate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!MATICRateStop){

            try {
                const res = await axios.get('/api/nt-ratematic')

                if(res.data[0].ID){
                    setNT_rateMATIC(res.data[0].matic)
                    setMATICRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[MATICRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!MATICChargeStop){

            try {
                const res = await axios.get('/api/nt-chargematic')

                if(res.data[0].ID){
                    setNT_ChargeMATIC(res.data[0].matic)
                    setMATICChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[MATICChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!MATICRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2matic')

                if(res.data[0].ID){
                    setNT_rate2MATIC(res.data[0].matic)
                    setMATICRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[MATICRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!ETHRateStop){

            try {
                const res = await axios.get('/api/nt-rateeth')

                if(res.data[0].ID){
                    setNT_rateETH(res.data[0].eth)
                    setETHRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ETHRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!ETHChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeeth')

                if(res.data[0].ID){
                    setNT_ChargeETH(res.data[0].eth)
                    setETHChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ETHChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!ETHRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2eth')

                if(res.data[0].ID){
                    setNT_rate2ETH(res.data[0].eth)
                    setETHRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ETHRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!LINKRateStop){

            try {
                const res = await axios.get('/api/nt-ratelink')

                if(res.data[0].ID){
                    setNT_rateLINK(res.data[0].link)
                    setLINKRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LINKRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!LINKChargeStop){

            try {
                const res = await axios.get('/api/nt-chargelink')

                if(res.data[0].ID){
                    setNT_ChargeLINK(res.data[0].link)
                    setLINKChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LINKChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!LINKRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2link')

                if(res.data[0].ID){
                    setNT_rate2LINK(res.data[0].link)
                    setLINKRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[LINKRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!DOTERateStop){

            try {
                const res = await axios.get('/api/nt-ratedote')

                if(res.data[0].ID){
                    setNT_rateDOTE(res.data[0].dote)
                    setDOTERateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOTERateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!DOTEChargeStop){

            try {
                const res = await axios.get('/api/nt-chargedote')

                if(res.data[0].ID){
                    setNT_ChargeDOTE(res.data[0].dote)
                    setDOTEChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOTEChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!DOTERate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2dote')

                if(res.data[0].ID){
                    setNT_rate2DOTE(res.data[0].dote)
                    setDOTERate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[DOTERate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!ADARateStop){

            try {
                const res = await axios.get('/api/nt-rateada')

                if(res.data[0].ID){
                    setNT_rateADA(res.data[0].ada)
                    setADARateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ADARateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!ADAChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeada')

                if(res.data[0].ID){
                    setNT_ChargeADA(res.data[0].ada)
                    setADAChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ADAChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!ADARate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2ada')

                if(res.data[0].ID){
                    setNT_rate2ADA(res.data[0].ada)
                    setADARate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[ADARate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!SOLRateStop){

            try {
                const res = await axios.get('/api/nt-ratesol')

                if(res.data[0].ID){
                    setNT_rateSOL(res.data[0].sol)
                    setSOLRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SOLRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!SOLChargeStop){

            try {
                const res = await axios.get('/api/nt-chargesol')

                if(res.data[0].ID){
                    setNT_ChargeSOL(res.data[0].sol)
                    setSOLChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SOLChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!SOLRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2sol')

                if(res.data[0].ID){
                    setNT_rate2SOL(res.data[0].sol)
                    setSOLRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[SOLRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!PMRateStop){

            try {
                const res = await axios.get('/api/nt-ratepm')

                if(res.data[0].ID){
                    setNT_ratePM(res.data[0].pm)
                    setPMRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[PMRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!PMChargeStop){

            try {
                const res = await axios.get('/api/nt-chargepm')

                if(res.data[0].ID){
                    setNT_ChargePM(res.data[0].pm)
                    setPMChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[PMChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!PMRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2pm')

                if(res.data[0].ID){
                    setNT_rate2PM(res.data[0].pm)
                    setPMRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[PMRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!BNBRateStop){

            try {
                const res = await axios.get('/api/nt-ratebnb')

                if(res.data[0].ID){
                    setNT_rateBNB(res.data[0].bnb)
                    setBNBRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BNBRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!BNBChargeStop){

            try {
                const res = await axios.get('/api/nt-chargebnb')

                if(res.data[0].ID){
                    setNT_ChargeBNB(res.data[0].bnb)
                    setBNBChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BNBChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!BNBRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2bnb')

                if(res.data[0].ID){
                    setNT_rate2BNB(res.data[0].bnb)
                    setBNBRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[BNBRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!TONRateStop){

            try {
                const res = await axios.get('/api/nt-rateton')

                if(res.data[0].ID){
                    setNT_rateTON(res.data[0].ton)
                    setTONRateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TONRateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!TONChargeStop){

            try {
                const res = await axios.get('/api/nt-chargeton')

                if(res.data[0].ID){
                    setNT_ChargeTON(res.data[0].ton)
                    setTONChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TONChargeStop])

useEffect(()=>{

    const fetchData = async () => {

        if(!TONRate2Stop){

            try {
                const res = await axios.get('/api/nt-rate2ton')

                if(res.data[0].ID){
                    setNT_Rate2TON(res.data[0].ton)
                    setTONRate2Stop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[TONRate2Stop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!MBDOGERateStop){

            try {
                const res = await axios.get('/api/nt-ratembdoge')

                if(res.data[0].ID){
                    setNT_rateMBDOGE(res.data[0].mbdoge)
                    setMBDOGERateStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[MBDOGERateStop])
    
useEffect(()=>{

    const fetchData = async () => {

        if(!MBDOGEChargeStop){

            try {
                const res = await axios.get('/api/nt-chargembdoge')

                if(res.data[0].ID){
                    setNT_ChargeMBDOGE(res.data[0].mbdoge)
                    setMBDOGEChargeStop(true)
                }
            } catch (error) {
            }
        }
    }
    fetchData()

},[MBDOGEChargeStop])

const [coins2, setCoins2] = useState([])

useEffect(()=>{
  
  const fetchDada2 = async () => {

    const response = await coinGecko.get('/coins/markets', {
      params: {
        vs_currency: 'usd',
        ids:
          'binancecoin,binance-usd,tron,litecoin,dogecoin,shiba-inu,matic-network,chainlink,polkadot,cardano,solana,the-open-network',
      },
    })
    if(response.data.length > 0){
      setCoins2(response.data)
    }
  }
  fetchDada2()
},[])

    
// useEffect(()=>{
//     const fetchBTC = async () => {
//       try {
//           const response = await coinGeckoData.get('/coins/')
//           const _data = response.data.coins
//           const BTCFilted = _data.filter((items) => { return items.id === 'bitcoin' })
          
//           if(BTCFilted[0].price){
//             // setBTCprice(Number(BTCFilted[0].price))
//             localStorage.setItem('BTCprice', BTCFilted[0].price)

//             try {
    
//                 axios.post('/api/update-nt-btc', 
//                     {
//                         ID: 1,
//                         btc: BTCFilted[0].price,
//                         createdAt: new Date(),
//                         updatedAt: new Date(),
//                     }
//                     )
    
//             } catch (err) {}
//           }
//       } catch (error) { }
//     }
//     fetchBTC()

// },[])

useEffect(()=>{
const fetchBTC = async () => {

  try {
    if(coins2.length > 0){

      if(coins2[0].id === "binancecoin"){

        if(coins2[0].current_price){
  
          try {
  
              axios.post('/api/update-nt-bnb', 
                  {
                      ID: 1,
                      bnb: coins2[0].current_price,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  )
  
          } catch (err) {}
        }

      } else if(coins2[0].id === "solana"){

        if(coins2[0].current_price){
  
          try {
  
              axios.post('/api/update-nt-sol', 
                  {
                      ID: 1,
                      sol: coins2[0].current_price,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  )
  
          } catch (err) {}
        }

      }
    }
  } catch (error) {
  }
}
fetchBTC()

},[coins2])

useEffect(()=>{
const fetchBTC = async () => {

try {
  if(coins2.length > 0){

    if(coins2[1].id === "solana"){

      if(coins2[1].current_price){
  
        try {
  
            axios.post('/api/update-nt-sol', 
                {
                    ID: 1,
                    sol: coins2[1].current_price,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                }
                )
  
        } catch (err) {}
      }

    } else if(coins2[1].id === "binancecoin"){

      if(coins2[1].current_price){
  
        try {
  
            axios.post('/api/update-nt-bnb', 
                {
                    ID: 1,
                    bnb: coins2[1].current_price,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                }
                )
  
        } catch (err) {}
      }

    }
  }
} catch (error) {
}
}
fetchBTC()

},[coins2])

useEffect(()=>{
const fetchBTC = async () => {

try {
  if(coins2.length > 0){

    if(coins2[5].current_price){

      try {

          axios.post('/api/update-nt-ada', 
              {
                  ID: 1,
                  ada: coins2[5].current_price,
                  createdAt: new Date(),
                  updatedAt: new Date(),
              }
              )

      } catch (err) {}
    }
  }
} catch (error) {
}
}
fetchBTC()

},[coins2])

// useEffect(()=>{
//   const fetchBTC = async () => {

//     try {
//       if(coins2.length > 0){

//         if(coins2[2].current_price){

//           try {
  
//               axios.post('/api/update-nt-doge', 
//                   {
//                       ID: 1,
//                       doge: coins2[2].current_price,
//                       createdAt: new Date(),
//                       updatedAt: new Date(),
//                   }
//                   )
  
//           } catch (err) {}
//         }
//       }
//     } catch (error) {
//     }
//   }
//   fetchBTC()

// },[coins2])

useEffect(()=>{
  const fetchBTC = async () => {

    try {
      if(coins2.length > 0){

        if(coins2[4].current_price){

          try {
  
              axios.post('/api/update-nt-ton', 
                  {
                      ID: 1,
                      ton: coins2[4].current_price,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  )
  
          } catch (err) {}
        }
      }
    } catch (error) {
    }
  }
  fetchBTC()

},[coins2])

// useEffect(()=>{
// const fetchBTC = async () => {

//   try {
//     if(coins2.length > 0){

//       if(coins2[6].current_price){

//         try {

//             axios.post('/api/update-nt-shiba', 
//                 {
//                     ID: 1,
//                     shiba: coins2[6].current_price,
//                     createdAt: new Date(),
//                     updatedAt: new Date(),
//                 }
//                 )

//         } catch (err) {}
//       }
//     }
//   } catch (error) {
//   }
// }
// fetchBTC()

// },[coins2])

// useEffect(()=>{
//     const fetchBTC = async () => {

//       try {
//           const response = await coinGeckoData.get('/coins/')
//           const _data = response.data.coins
//           const USDTFilted = _data.filter((items) => {
//             return items.id === 'tether'
//           })
//           if(USDTFilted[0].price){
//             // setUSDTprice(Number(USDTFilted[0].price))
//             localStorage.setItem('USDTprice', USDTFilted[0].price)

//             try {
    
//                 axios.post('/api/update-nt-usdt', 
//                     {
//                         ID: 1,
//                         usdt: USDTFilted[0].price,
//                         createdAt: new Date(),
//                         updatedAt: new Date(),
//                     }
//                     )
    
//             } catch (err) {}

//           }
//       } catch (error) {
//       }
//     }
//     fetchBTC()

// },[])

// useEffect(()=>{
//   const fetchBTC = async () => {

//     try {
//       if(coins2.length > 0){

//         if(coins2[4].current_price){

//           try {
  
//               axios.post('/api/update-nt-tron', 
//                   {
//                       ID: 1,
//                       tron: coins2[4].current_price,
//                       createdAt: new Date(),
//                       updatedAt: new Date(),
//                   }
//                   )
  
//           } catch (err) {}
//         }
//       }
//     } catch (error) {
//     }
//   }
//   fetchBTC()

// },[coins2])

// useEffect(()=>{
// const fetchBTC = async () => {

//   try {
//     if(coins2.length > 0){

//       if(coins2[10].current_price){

//         try {

//             axios.post('/api/update-nt-matic', 
//                 {
//                     ID: 1,
//                     matic: coins2[10].current_price,
//                     createdAt: new Date(),
//                     updatedAt: new Date(),
//                 }
//                 )

//         } catch (err) {}
//       }
//     }
//   } catch (error) {
//   }
// }
// fetchBTC()

// },[coins2])

// useEffect(()=>{
// const fetchBTC = async () => {

//   try {
//     if(coins2.length > 0){

//       if(coins2[9].current_price){

//         try {

//             axios.post('/api/update-nt-ltc', 
//                 {
//                     ID: 1,
//                     ltc: coins2[9].current_price,
//                     createdAt: new Date(),
//                     updatedAt: new Date(),
//                 }
//                 )

//         } catch (err) {}
//       }
//     }
//   } catch (error) {
//   }
// }
// fetchBTC()

// },[coins2])

useEffect(()=>{
  const fetchBTC = async () => {

    try {
      if(coins2.length > 0){

        if(coins2[11].current_price){

          try {
  
              axios.post('/api/update-nt-busd', 
                  {
                      ID: 1,
                      busd: coins2[11].current_price,
                      createdAt: new Date(),
                      updatedAt: new Date(),
                  }
                  )
  
          } catch (err) {}
        }
      }
    } catch (error) {
    }
  }
  fetchBTC()

},[coins2])

// useEffect(()=>{
//     const fetchBTC = async () => {

//       try {
//           const response = await coinGeckoData.get('/coins/')
//           const _data = response.data.coins
//           const ETHFilted = _data.filter((items) => {
//             return items.id === 'ethereum'
//           })
//           if(ETHFilted[0].price){
//             // setETHEREUMprice(Number(ETHFilted[0].price))
//             localStorage.setItem('ETHEREUMprice', ETHFilted[0].price)

//             try {
    
//                 axios.post('/api/update-nt-eth', 
//                     {
//                         ID: 1,
//                         eth: ETHFilted[0].price,
//                         createdAt: new Date(),
//                         updatedAt: new Date(),
//                     }
//                     )
    
//             } catch (err) {}

//           }
//       } catch (error) {
//       }
//     }
//     fetchBTC()

// },[])

  function BuyUsdtClick(){
    setItemStatusCliked("buy")
    setIDCliked(1)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyBnbClick(){
    setItemStatusCliked("buy")
    setIDCliked(2)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyBtcClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("buy")
    // setIDCliked(3)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyLtcClick(){
    setItemStatusCliked("buy")
    setIDCliked(4)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyDogeClick(){
    setItemStatusCliked("buy")
    setIDCliked(5)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyPmClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("buy")
    // setIDCliked(7)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyTronClick(){
    setItemStatusCliked("buy")
    setIDCliked(8)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyBusdClick(){
    // alert("Cette monnaie n'est pour l'instant pas disponible")
    setItemStatusCliked("buy")
    setIDCliked(9)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyShibaClick(){
    setItemStatusCliked("buy")
    setIDCliked(10)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyMaticClick(){
    setItemStatusCliked("buy")
    setIDCliked(11)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyEthClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // props.history.push(`/sell-or-buy-crypto`)
    // setItemStatusCliked("buy")
    // setIDCliked(12)
  }

  function BuyDotClick(){
    setItemStatusCliked("buy")
    setIDCliked(13)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyLinkClick(){
    setItemStatusCliked("buy")
    setIDCliked(14)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyPayeerClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyAdaClick(){
    setItemStatusCliked("buy")
    setIDCliked(15)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuySolClick(){
    setItemStatusCliked("buy")
    setIDCliked(16)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function BuyTonClick(){
    setItemStatusCliked("buy")
    setIDCliked(17)
    props.history.push(`/sell-or-buy-crypto`)
  }






  function SelUsdtClick(){
    setItemStatusCliked("sel")
    setIDCliked(1)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelBnbClick(){
    setItemStatusCliked("sel")
    setIDCliked(2)
    props.history.push(`/sell-or-buy-crypto`)
  }


  function SelBtcClick(){
    // alert("Cette monnaie n'est pour l'instant pas disponible")
    setItemStatusCliked("sel")
    setIDCliked(3)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelLtcClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("sel")
    // setIDCliked(4)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function SelDogeClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("sel")
    // setIDCliked(5)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function SelPmClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("sel")
    // setIDCliked(7)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function SelTronClick(){
    setItemStatusCliked("sel")
    setIDCliked(8)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelBusdClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("sel")
    // setIDCliked(9)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function SelShibaClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    // setItemStatusCliked("sel")
    // setIDCliked(10)
    // props.history.push(`/sell-or-buy-crypto`)
  }

  function SelMaticClick(){
    setItemStatusCliked("sel")
    setIDCliked(11)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelEthClick(){
    // alert("Cette monnaie n'est pour l'instant pas disponible")
    setItemStatusCliked("sel")
    setIDCliked(12)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelDotClick(){
    setItemStatusCliked("sel")
    setIDCliked(13)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelLinkClick(){
    setItemStatusCliked("sel")
    setIDCliked(14)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelAdaClick(){
    setItemStatusCliked("sel")
    setIDCliked(15)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelPayeerClick(){
    alert("Cette monnaie n'est pour l'instant pas disponible")
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelSolClick(){
    setItemStatusCliked("sel")
    setIDCliked(16)
    props.history.push(`/sell-or-buy-crypto`)
  }

  function SelTonClick(){
    setItemStatusCliked("sel")
    setIDCliked(17)
    props.history.push(`/sell-or-buy-crypto`)
  }


  const adrTotal            =  adr_Coin.length
  const adrTotal2            =  Memo.length
  const limitAdrCount2       =  9
  const limitAdrCount       =  7

  const buy_btc_taux            = NT_rateBTC
  const sell_btc_taux           = NT_rate2BTC

  const buy_tron_taux           = NT_rateTRON
  const sell_tron_taux          = NT_rate2TRON

  const buy_ltc_taux            = NT_rateLTC
  const sell_ltc_taux           = NT_rate2LTC

  const buy_doge_taux           = NT_rateDOGE
  const sell_doge_taux          = NT_rate2DOGE

  const buy_bsc_taux            = NT_rateBNB
  const sell_bsc_taux           = NT_rate2BNB

  const buy_usdt_taux           = NT_rateUSDT
  const sell_usdt_taux          = NT_rate2USDT

  const buy_pm_taux             = NT_ratePM
  const sell_pm_taux            = NT_rate2PM
  
  const buy_busd_taux           = NT_rateBUSD
  const sell_busd_taux          = NT_rate2BUSD

  const buy_shiba_taux          = NT_rateSHIBA
  const sell_shiba_taux         = NT_rate2SHIBA

  const buy_polygonmatic_taux   = NT_rateMATIC
  const sell_polygonmatic_taux  = NT_rate2MATIC

  const buy_ethereum_taux       = NT_rateETH
  const sell_ethereum_taux      = NT_rate2ETH

  const buy_pokadot_taux        = NT_rateDOTE
  const sell_pokadot_taux       = NT_rate2DOTE

  const buy_chainlink_taux      = NT_rateLINK
  const sell_chainlink_taux     = NT_rate2LINK

  const buy_cardano_taux        = NT_rateADA
  const sell_cardano_taux       = NT_rate2ADA

  const buy_solana_taux         = NT_rateSOL
  const sell_solana_taux        = NT_rate2SOL

  const buy_ton_taux            = NT_rateTON
  const sell_ton_taux           = NT_rate2TON

  const toogleHandleChange = (val) => {
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("transActivated", true)
      setChecked(val)
      localStorage.setItem("isRActive",false)

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/nkab-pro");
      } else {
         window.location.replace("http://localhost:3000/nkab-pro");
      }
  }

  const serviceLists = [
    {
      ID: 1,
      isActivated: 1,
      serviceImageLink: "../images/usdt.png",
      serviceName: "Tether(USDT)",
      serviceSymbol: "USDT"
    },
    {
      ID: 2,
      isActivated: 1,
      serviceImageLink: "../images/bnb.png",
      serviceName: "Binance Smart Chain",
      serviceSymbol: "BNB"
    },
    {
      ID: 3,
      isActivated: 1,
      serviceImageLink: "../images/btc.png",
      serviceName: "Bitcoin",
      serviceSymbol: "BTC"
    },
    {
      ID: 4,
      isActivated: 1,
      serviceImageLink: "../images/ltc.png",
      serviceName: "Litecoin",
      serviceSymbol: "LTC"
    },
    {
      ID: 5,
      isActivated: 1,
      serviceImageLink: "../images/doge.png",
      serviceName: "Dogecoin",
      serviceSymbol: "DOGE"
    },
    {
      ID: 6,
      isActivated: 1,
      serviceImageLink: "../images/payeer.png",
      serviceName: "Payeer",
      serviceSymbol: "PAYEER"
    },
    {
      ID: 7,
      isActivated: 1,
      serviceImageLink: "../images/pm.png",
      serviceName: "Perfect Money",
      serviceSymbol: "PM"
    },
    {
      ID: 8,
      isActivated: 1,
      serviceImageLink: "../images/tron.png",
      serviceName: "TRON",
      serviceSymbol: "trx"
    },
    {
      ID: 9,
      isActivated: 1,
      serviceImageLink: "../images/busd.png",
      serviceName: "Binance USD",
      serviceSymbol: "BUSD"
    },
    {
      ID: 10,
      isActivated: 1,
      serviceImageLink: "../images/shiba.png",
      serviceName: "Shiba Inu",
      serviceSymbol: "SHIB"
    },
    {
      ID: 11,
      isActivated: 1,
      serviceImageLink: "../images/polygonmatic.png",
      serviceName: "Polygon",
      serviceSymbol: "MATIC"
    },
    {
      ID: 12,
      isActivated: 1,
      serviceImageLink: "../images/eth.png",
      serviceName: "Ethereum",
      serviceSymbol: "ETH"
    },
    {
      ID: 13,
      isActivated: 1,
      serviceImageLink: "../images/pokadot.png",
      serviceName: "Polkadot",
      serviceSymbol: "DOT"
    },
    {
      ID: 14,
      isActivated: 1,
      serviceImageLink: "../images/chainlink.png",
      serviceName: "Chainlink",
      serviceSymbol: "LINK"
    },
    {
      ID: 15,
      isActivated: 1,
      serviceImageLink: "../images/cardano.png",
      serviceName: "Cardano",
      serviceSymbol: "ADA"
    },
    {
      ID: 16,
      isActivated: 1,
      serviceImageLink: "../images/solana.png",
      serviceName: "Solana",
      serviceSymbol: "SOL"
    },
    {
      ID: 17,
      isActivated: 1,
      serviceImageLink: "../images/ton.png",
      serviceName: "Toncoin",
      serviceSymbol: "TON"
    },
  ]


  useEffect(()=>{

    const fetchDada12 = async () => {
      if(IDCliked){
        const serviceGetted = serviceLists.filter((obj) => {
          return obj.ID === Number(IDCliked)
        })
        setServices(serviceGetted[0])
        }
      }
    fetchDada12()

  },[IDCliked])

  function OrangeHanlder(){
    setNkabSpinner(false)
    setOperatorNames('Orange Money')
    setItemsOpener(false)
    setOmOptionClicked("nkp_bank_option_clicked")
    setMomoOptionClicked("")
    setFloozOptionClicked("")
    setFmOptionClicked("")
    setTmOptionClicked("")
    setWaveOptionClicked("")
    setOpSelcted(true)
  }
  function MobileHanlder(){
    setNkabSpinner(false)
    setOperatorNames('Mobile Money')
    setItemsOpener(false)
    setOmOptionClicked("")
    setMomoOptionClicked("nkp_bank_option_clicked")
    setFloozOptionClicked("")
    setFmOptionClicked("")
    setTmOptionClicked("")
    setWaveOptionClicked("")
    setOpSelcted(true)
  }
  function MoovHanlder(){
    setNkabSpinner(false)
    setOperatorNames('Moov Money')
    setItemsOpener(false)
    setOmOptionClicked("")
    setMomoOptionClicked("")
    setFloozOptionClicked("nkp_bank_option_clicked")
    setFmOptionClicked("")
    setTmOptionClicked("")
    setWaveOptionClicked("")
    setOpSelcted(true)
  }
  function WaveHanlder(){
    setNkabSpinner(false)
    setOperatorNames('Wave')
    setItemsOpener(false)
    setOmOptionClicked("")
    setMomoOptionClicked("")
    setFmOptionClicked("")
    setTmOptionClicked("")
    setFloozOptionClicked("")
    setWaveOptionClicked("nkp_bank_option_clicked")
    setOpSelcted(true)
  }
  function FreeHanlder(){
    setNkabSpinner(false)
    setOperatorNames('Free Money')
    setItemsOpener(false)
    setOmOptionClicked("")
    setMomoOptionClicked("")
    setFmOptionClicked("nkp_bank_option_clicked")
    setTmOptionClicked("")
    setFloozOptionClicked("")
    setWaveOptionClicked("")
    setOpSelcted(true)
  }
  function TMHanlder(){
    setNkabSpinner(false)
    setOperatorNames('TMoney')
    setItemsOpener(false)
    setOmOptionClicked("")
    setMomoOptionClicked("")
    setFmOptionClicked("")
    setTmOptionClicked("nkp_bank_option_clicked")
    setFloozOptionClicked("")
    setWaveOptionClicked("")
    setOpSelcted(true)
  }

  const [NetAlert, setNetAlert] = useState('')

  const ci = "Côte d'Ivoire"
  const sen = "Sénégal"
  const burk = "Burkina Faso"
  const benin = "Bénin"
  const togo = "Togo"
  const mali = "Mali"
  const niger = "Niger"


  useEffect(() => {
      
      if (ItemStatusCliked === 'sel') {
        if (services.serviceName === BTCname) {
          setRateCondition(sell_btc_taux)
        } else if (services.serviceName === TRONname) {
          setRateCondition(sell_tron_taux)
        } else if (services.serviceName === LTCname) {
          setRateCondition(sell_ltc_taux)
        } else if (services.serviceName === DOGEname) {
          setRateCondition(sell_doge_taux)
        } else if (services.serviceName === BUSDname) {
          setRateCondition(sell_busd_taux)
        } else if (services.serviceName === SHIBAname) {
          setRateCondition(sell_shiba_taux)
        } else if (services.serviceName === POLYGONMATICname) {
          setRateCondition(sell_polygonmatic_taux)
        } else if (services.serviceName === ETHEREUMname) {
          setRateCondition(sell_ethereum_taux)
        } else if (services.serviceName === POKADOTname) {
          setRateCondition(sell_pokadot_taux)
        } else if (services.serviceName === CHAINLINKname) {
          setRateCondition(sell_chainlink_taux)
        } else if (services.serviceName === CARDANOname) {
          setRateCondition(sell_cardano_taux)
        } else if (services.serviceName === SOLANAname) {
          setRateCondition(sell_solana_taux)
        } else if (services.serviceName === 'Binance Smart Chain') {
          setRateCondition(sell_bsc_taux)
        } else if (services.serviceName === 'Tether(USDT)') {
          setRateCondition(sell_usdt_taux)
        } else if (services.serviceName === PMname) {
          setRateCondition(sell_pm_taux)
        } else if (services.serviceName === TONname) {
          setRateCondition(sell_ton_taux)
        }
      }
      
      if ( ItemStatusCliked === 'buy' ) {
        if (services.serviceName === BTCname) {
          setRateCondition(buy_btc_taux)
        } else if (services.serviceName === TRONname) {
          setRateCondition(buy_tron_taux)
        } else if (services.serviceName === LTCname) {
          setRateCondition(buy_ltc_taux)
        } else if (services.serviceName === DOGEname) {
          setRateCondition(buy_doge_taux)
        } else if (services.serviceName === BUSDname) {
          setRateCondition(buy_busd_taux)
        } else if (services.serviceName === SHIBAname) {
          setRateCondition(buy_shiba_taux)
        } else if (services.serviceName === POLYGONMATICname) {
          setRateCondition(buy_polygonmatic_taux)
        } else if (services.serviceName === ETHEREUMname) {
          setRateCondition(buy_ethereum_taux)
        } else if (services.serviceName === POKADOTname) {
          setRateCondition(buy_pokadot_taux)
        } else if (services.serviceName === CHAINLINKname) {
          setRateCondition(buy_chainlink_taux)
        } else if (services.serviceName === CARDANOname) {
          setRateCondition(buy_cardano_taux)
        } else if (services.serviceName === SOLANAname) {
          setRateCondition(buy_solana_taux)
        } else if (services.serviceName === 'Binance Smart Chain') {
          setRateCondition(buy_bsc_taux)
        } else if (services.serviceName === 'Tether(USDT)') {
          setRateCondition(buy_usdt_taux)
        } else if (services.serviceName === PMname) {
          setRateCondition(buy_pm_taux)
        } else if (services.serviceName === TONname) {
          setRateCondition(buy_ton_taux)
        }
      }
  }, [
    ItemStatusCliked,
    services.serviceName,

    BTCname,
    TRONname,
    LTCname,
    DOGEname,
    BUSDname,
    SHIBAname,
    POLYGONMATICname,
    ETHEREUMname,
    POKADOTname,
    CHAINLINKname,
    CARDANOname,
    SOLANAname,
    PMname,
    TONname,

    sell_btc_taux,
    buy_btc_taux,

    sell_tron_taux,
    buy_tron_taux,

    sell_ltc_taux,
    buy_ltc_taux,

    sell_doge_taux,
    buy_doge_taux,

    sell_busd_taux,
    buy_busd_taux,

    sell_shiba_taux,
    buy_shiba_taux,

    sell_polygonmatic_taux,
    buy_polygonmatic_taux,

    sell_ethereum_taux,
    buy_ethereum_taux,

    sell_pokadot_taux,
    buy_pokadot_taux,

    sell_chainlink_taux,
    buy_chainlink_taux,

    sell_cardano_taux,
    buy_cardano_taux,

    sell_solana_taux,
    buy_solana_taux,

    sell_bsc_taux,
    buy_bsc_taux,

    sell_usdt_taux,
    buy_usdt_taux,

    sell_pm_taux,
    buy_pm_taux,

    sell_ton_taux,
    buy_ton_taux,
  ])


  const [Quantity, setQuantity] = useState(false)
  const INIT                =  { montant: 0, converted: 0, cfa: 0, dollar: 0 }
  const [exchange, setExchange] = useState(INIT)

  const handleChange = ({ target: { value, name } }) => {
    if (services.serviceName === BTCname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * BTCprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateBTC + (NT_rateBTC*NT_ChargeBTC))
        const converted = val
        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeBTC
        const converted = dollar / BTCprice
        const cfa = val
        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }

    if (services.serviceName === TRONname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * TRONprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateTRON*NT_ChargeTRON)
        const converted = val
        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeTRON
        const converted = dollar / TRONprice
        const cfa = val
        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }

    if (services.serviceName === LTCname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * LTCprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateLTC*NT_ChargeLTC)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeLTC
        const converted = dollar / LTCprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

    if (services.serviceName === DOGEname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * DOGEprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateDOGE*NT_ChargeDOGE)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeDOGE
        const converted = dollar / DOGEprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }
    
    if (services.serviceName === BUSDname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * BUSDprice
        const cfa = busdActionSelector === "bep20" ? Number((dollar * rateCondition).toFixed(0)) + NT_rateBUSD*NT_ChargeBUSD : 0
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = busdActionSelector === "bep20" ? ((val / rateCondition) - NT_ChargeBUSD) : 0
        const converted = dollar / BUSDprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }

    if (services.serviceName === SHIBAname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * SHIBAprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateSHIBA*NT_ChargeSHIBA)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeSHIBA
        const converted = tetherNet === "erc20" ? ((dollar - 4.69) / SHIBAprice) : dollar / SHIBAprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

    if (services.serviceName === POLYGONMATICname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * POLYGONMATICprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateMATIC*NT_ChargeMATIC)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeMATIC
        const converted = dollar / POLYGONMATICprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

    if (services.serviceName === POKADOTname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * POKADOTprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateDOTE*NT_ChargeDOTE)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeDOTE
        const converted = dollar / POKADOTprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

   if (services.serviceName === CHAINLINKname && ItemStatusCliked === 'buy') {
    setNkabSpinner(false)
    setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * CHAINLINKprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateLINK*NT_ChargeLINK)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeLINK
        const converted = tetherNet === "erc20" ? ((dollar - 4.59) / CHAINLINKprice) : dollar / CHAINLINKprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 
    
    if (services.serviceName === CARDANOname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * CARDANOprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateADA*NT_ChargeADA)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeADA
        const converted = dollar / CARDANOprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

    if (services.serviceName === SOLANAname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * SOLANAprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateSOL*NT_ChargeSOL)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeSOL
        const converted = dollar / SOLANAprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 

    if (services.serviceName === 'Binance Smart Chain' && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
     const val = Number(value.trim())
     if (interChoice === true) {
       const dollar = val * BSCprice
       const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateBNB*NT_ChargeBNB)
       const converted = val


       setExchange({
         [name]: val,
         converted,
         cfa,
         dollar,
       })
     } else if (interChoice === false) {
      setNkabSpinner(false)
      setQuantity(true)
      const dollar = (val / rateCondition) - NT_ChargeBNB
       const converted = dollar / BSCprice
       const cfa = val

       setExchange({
         [name]: val,
         converted,
         cfa,
         dollar,
       })
     }
   }

    if (services.serviceName === 'Ethereum' && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * ETHEREUMprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateETH + (NT_rateETH*NT_ChargeETH))
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeETH
        const converted = dollar / ETHEREUMprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    } 
    
    if (services.serviceName === 'Tether(USDT)' && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = (val * USDTprice)
        const cfa = tetherActionSelector === "trc20" ? Number((dollar * rateCondition).toFixed(0)) + (NT_rateUSDT*NT_ChargeUSDT) : tetherActionSelector === "bep20" ? Number((dollar * rateCondition).toFixed(0)) + (NT_rateUSDT*NT_Charge2USDT) : 0
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = tetherActionSelector === "trc20" ? ((val / rateCondition) - NT_ChargeUSDT) : tetherActionSelector === "bep20" ? ((val / rateCondition) - NT_Charge2USDT) : 0
        const converted = (dollar / USDTprice)
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }

    if (services.serviceName === PMname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * PMprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_ratePM*NT_ChargePM)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargePM
        const converted = dollar / PMprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }

    if (services.serviceName === TONname && ItemStatusCliked === 'buy') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      if (interChoice === true) {
        const dollar = val * TONprice
        const cfa = Number((dollar * rateCondition).toFixed(0)) + (NT_rateTON*NT_ChargeTON)
        const converted = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      } else if (interChoice === false) {
        setNkabSpinner(false)
        setQuantity(true)
        const dollar = (val / rateCondition) - NT_ChargeTON
        const converted = dollar / TONprice
        const cfa = val

        setExchange({
          [name]: val,
          converted,
          cfa,
          dollar,
        })
      }
    }



    if (services.serviceName === BTCname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * BTCprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === TRONname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * TRONprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === LTCname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * LTCprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === DOGEname && ItemStatusCliked === 'sel' ) {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * DOGEprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    } 

    if (services.serviceName === BUSDname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * BUSDprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === SHIBAname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * SHIBAprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === POLYGONMATICname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * POLYGONMATICprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === POKADOTname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * POKADOTprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === CHAINLINKname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * CHAINLINKprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === CARDANOname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * CARDANOprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === SOLANAname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * SOLANAprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === 'Binance Smart Chain' && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * BSCprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === 'Ethereum' && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * ETHEREUMprice
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === 'Tether(USDT)' && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * 1
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === PMname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * 1
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }

    if (services.serviceName === TONname && ItemStatusCliked === 'sel') {
      setNkabSpinner(false)
      setQuantity(true)
      const val = Number(value.trim())
      setSellInput(val)
      const dollar = val * 1
      const cfa = Number((dollar * rateCondition).toFixed(0))
      const converted = val
      setExchange({
        [name]: val,
        converted,
        cfa,
        dollar,
      })
    }
  }

  const phoneHandleChange = ({target: {value}}) => {
    setNkabSpinner(false)
    setContact(`${value}`)
  }


  var exch = exchange.converted

  //BUY
  const buyMinBTC =           Number(((20 / BTCprice) * 1).toFixed(5))
  const buyMinTRON =          Number(((5 / TRONprice) * 1).toFixed(2))
  const buyMinLTC =           Number(((5 / LTCprice) * 1).toFixed(4))
  const buyMinUSDT =          Number(((10 / USDTprice) * 1).toFixed(2))
  const buyMinPM =            Number(((10 / PMprice) * 1).toFixed(2))
  const buyMinDOGE =          Number(((15 / DOGEprice) * 1).toFixed(4))
  const buyMinBUSD =          Number(((10 / BUSDprice) * 1).toFixed(4))
  const buyMinSHIBA =         Number(((10 / SHIBAprice) * 1).toFixed(4))
  const buyMinPOLYGONMATIC =  Number(((14 / POLYGONMATICprice) * 1).toFixed(4))
  const buyMinETHEREUM =      Number(((10 / ETHEREUMprice) * 1).toFixed(4))
  const buyMinPOKADOT =       Number(((10 / POKADOTprice) * 1).toFixed(4))
  const buyMinCHAINLINK =     Number(((10 / CHAINLINKprice) * 1).toFixed(4))
  const buyMinCARDANO =       Number(((10 / CARDANOprice) * 1).toFixed(4))
  const buyMinSOLANA =        Number(((10 / SOLANAprice) * 1).toFixed(4))
  const buyMinBSC =           Number(((5 / BSCprice) * 1).toFixed(4))
  const buyMinTON =           Number(((5 / TONprice) * 1).toFixed(4))

  const buyMaxBTC =           ((3000 / BTCprice)*1).toFixed(5)
  const buyMaxTRON =          Number(((3000 / TRONprice) * 1).toFixed(2))
  const buyMaxLTC =           Number(((3000 / LTCprice) * 1).toFixed(4))
  const buyMaxUSDT =          Number(((3000 / USDTprice) * 1).toFixed(2))
  const buyMaxPM =            Number(((3000 / PMprice) * 1).toFixed(2))
  const buyMaxDOGE =          Number(((3000 / DOGEprice) * 1).toFixed(4))
  const buyMaxBUSD =          Number(((3000 / BUSDprice) * 1).toFixed(4))
  const buyMaxSHIBA =         Number(((3000 / SHIBAprice) * 1).toFixed(4))
  const buyMaxPOLYGONMATIC =  Number(((3000 / POLYGONMATICprice) * 1).toFixed(4))
  const buyMaxETHEREUM =      Number(((3000 / ETHEREUMprice) * 1).toFixed(4))
  const buyMaxPOKADOT =       Number(((3000 / POKADOTprice) * 1).toFixed(4))
  const buyMaxCHAINLINK =     Number(((3000 / CHAINLINKprice) * 1).toFixed(4))
  const buyMaxCARDANO =       Number(((3000 / CARDANOprice) * 1).toFixed(4))
  const buyMaxSOLANA =        Number(((3000 / SOLANAprice) * 1).toFixed(4))
  const buyMaxBSC =           Number(((3000 / BSCprice) * 1).toFixed(4))
  const buyMaxTON =           Number(((3000 / TONprice) * 1).toFixed(4))

  //SELL
  const sellMinBTC =          Number(((10 / BTCprice) * 1).toFixed(4))
  const sellMinTRON =         Number(((5 / TRONprice) * 1).toFixed(2))
  const sellMinLTC =          Number(((10 / LTCprice) * 1).toFixed(4))
  const sellMinUSDT =         Number(((10 / USDTprice) * 1).toFixed(2))
  const sellMinPM =           Number(((10 / PMprice) * 1).toFixed(2))
  const sellMinDOGE =         Number(((10 / DOGEprice) * 1).toFixed(4))
  const sellMinBUSD =         Number(((10 / BUSDprice) * 1).toFixed(4))
  const sellMinSHIBA =        Number(((10 / SHIBAprice) * 1).toFixed(4))
  const sellMinPOLYGONMATIC = Number(((11 / POLYGONMATICprice) * 1).toFixed(4))
  const sellMinETHEREUM =     Number(((10 / ETHEREUMprice) * 1).toFixed(4))
  const sellMinPOKADOT =      Number(((10 / POKADOTprice) * 1).toFixed(4))
  const sellMinCHAINLINK =    Number(((10 / CHAINLINKprice) * 1).toFixed(4))
  const sellMinCARDANO =      Number(((10 / CARDANOprice) * 1).toFixed(4))
  const sellMinSOLANA =       Number(((10 / SOLANAprice) * 1).toFixed(4))
  const sellMinBSC =          Number(((10 / BSCprice) * 1).toFixed(4))
  const sellMinTON =          Number(((10 / TONprice) * 1).toFixed(4))

  const sellMaxBTC =          Number(((3000 / BTCprice) * 1).toFixed(4))
  const sellMaxTRON =         Number(((3000 / TRONprice) * 1).toFixed(2))
  const sellMaxLTC =          Number(((3000 / LTCprice) * 1).toFixed(4))
  const sellMaxUSDT =         Number(((3000 / USDTprice) * 1).toFixed(2))
  const sellMaxPM =           Number(((3000 / PMprice) * 1).toFixed(2))
  const sellMaxDOGE =         Number(((3000 / DOGEprice) * 1).toFixed(4))
  const sellMaxBUSD =         Number(((3000 / BUSDprice) * 1).toFixed(4))
  const sellMaxSHIBA =        Number(((3000 / SHIBAprice) * 1).toFixed(4))
  const sellMaxPOLYGONMATIC = Number(((3000 / POLYGONMATICprice) * 1).toFixed(4))
  const sellMaxETHEREUM =     Number(((3000 / ETHEREUMprice) * 1).toFixed(4))
  const sellMaxPOKADOT =      Number(((3000 / POKADOTprice) * 1).toFixed(4))
  const sellMaxCHAINLINK =    Number(((3000 / CHAINLINKprice) * 1).toFixed(4))
  const sellMaxCARDANO =      Number(((3000 / CARDANOprice) * 1).toFixed(4))
  const sellMaxSOLANA =       Number(((3000 / SOLANAprice) * 1).toFixed(4))
  const sellMaxBSC =          Number(((3000 / BSCprice) * 1).toFixed(4))
  const sellMaxTON =          Number(((3000 / TONprice) * 1).toFixed(4))

  useEffect(() => {

    if ( ItemStatusCliked === 'sel' ) {
      if (services.serviceName === BTCname) {
        if (exch >= sellMinBTC && exch <= sellMaxBTC) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === TRONname) {
        if (exch >= sellMinTRON && exch <= sellMaxTRON) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === LTCname) {
        if (exch >= sellMinLTC && exch <= sellMaxLTC) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === 'Tether(USDT)') {
        if (exch >= sellMinUSDT && exch <= sellMaxUSDT) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === PMname) {
        if (exch >= sellMinPM && exch <= sellMaxPM) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === DOGEname) {
        if (exch >= sellMinDOGE && exch <= sellMaxDOGE) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === BUSDname) {
        if (exch >= sellMinBUSD && exch <= sellMaxBUSD) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === SHIBAname) {
        if (exch >= sellMinSHIBA && exch <= sellMaxSHIBA) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === POLYGONMATICname) {
        if (exch >= sellMinPOLYGONMATIC && exch <= sellMaxPOLYGONMATIC) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === ETHEREUMname) {
        if (exch >= sellMinETHEREUM && exch <= sellMaxETHEREUM) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === POKADOTname) {
        if (exch >= sellMinPOKADOT && exch <= sellMaxPOKADOT) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === CHAINLINKname) {
        if (exch >= sellMinCHAINLINK && exch <= sellMaxCHAINLINK) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === CARDANOname) {
        if (exch >= sellMinCARDANO && exch <= sellMaxCARDANO) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === SOLANAname) {
        if (exch >= sellMinSOLANA && exch <= sellMaxSOLANA) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === TONname) {
        if (exch >= sellMinTON && exch <= sellMaxTON) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
      if (services.serviceName === 'Binance Smart Chain') {
        if (exch >= sellMinBSC && exch <= sellMaxBSC) {
          setMinOrMax(true)
        } else {
          setMinOrMax(false)
        }
      }
    }
  }, [
    exch,
    services.serviceName,

    BSCname,
    BTCname,
    TRONname,
    DOGEname,
    BUSDname,
    SHIBAname,
    POLYGONMATICname,
    ETHEREUMname,
    POKADOTname,
    CHAINLINKname,
    CARDANOname,
    SOLANAname,
    LTCname,
    PMname,
    TONname,

    sellMinBTC,
    sellMinTRON,
    sellMinLTC,
    sellMinUSDT,
    sellMinDOGE,
    sellMinBUSD,
    sellMinPM,
    sellMinSHIBA,
    sellMinPOLYGONMATIC,
    sellMinETHEREUM,
    sellMinPOKADOT,
    sellMinCHAINLINK,
    sellMinCARDANO,
    sellMinSOLANA,
    sellMinBSC,
    sellMinTON,

    sellMaxBTC,
    sellMaxTRON,
    sellMaxLTC,
    sellMaxUSDT,
    sellMaxPM,
    sellMaxDOGE,
    sellMaxBUSD,
    sellMaxSHIBA,
    sellMaxPOLYGONMATIC,
    sellMaxETHEREUM,
    sellMaxPOKADOT,
    sellMaxCHAINLINK,
    sellMaxCARDANO,
    sellMaxSOLANA,
    sellMaxBSC,
    sellMaxTON,
    ItemStatusCliked
  ])

  useEffect(() => {
    if(secondsRemaining1){
      if (ItemStatusCliked === 'buy') {
        if (services.serviceName === BTCname) {
          setBTCResult(`${(exchange.converted * 1).toFixed(5)} ${BTCSymbol}`)
          setBTCMaxLimite(`${buyMaxBTC} ${BTCSymbol} ${' '}`)
          if (exch >= buyMinBTC && exch <= buyMaxBTC) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === TRONname) {
          setTRONResult(`${(exchange.converted * 1).toFixed(5)} ${TRONSymbol}`)
          setTRONMaxLimite(`${buyMaxTRON} ${TRONSymbol} ${' '}`)
          if (exch >= buyMinTRON && exch <= buyMaxTRON) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === LTCname) {
          setLTCResult(`${(exchange.converted * 1).toFixed(5)} ${LTCSymbol}`)
          setLTCMaxLimite(`${buyMaxLTC} ${LTCSymbol} ${' '}`)
          if (exch >= buyMinLTC && exch <= buyMaxLTC) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === 'Tether(USDT)') {
          setUSDTResult(`$ ${(exchange.converted * 1).toFixed(2)}`)
          setUSDTMaxLimite(`$ ${buyMaxUSDT} ${' '}`)
          if (exch >= buyMinUSDT && exch <= buyMaxUSDT) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === PMname) {
          setPMResult(`$ ${(exchange.converted * 1).toFixed(2)}`)
          setPMMaxLimite(`$ ${buyMaxPM} ${' '}`)
          if (exch >= buyMinPM && exch <= buyMaxPM) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === DOGEname) {
          setDOGEResult(`${(exchange.converted * 1).toFixed(5)} ${DOGESymbol}`)
          setDOGEMaxLimite(`${buyMaxDOGE} ${DOGESymbol} ${' '}`)
          if (exch >= buyMinDOGE && exch <= buyMaxDOGE) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === BUSDname) {
          setBUSDResult(`${(exchange.converted * 1).toFixed(5)} ${BUSDSymbol}`)
          setBUSDMaxLimite(`${buyMaxBUSD} ${BUSDSymbol} ${' '}`)
          if (exch >= buyMinBUSD && exch <= buyMaxBUSD) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === SHIBAname) {
          setSHIBAResult(`${(exchange.converted * 1).toFixed(5)} ${SHIBASymbol}`)
          setSHIBAMaxLimite(`${buyMaxSHIBA} ${SHIBASymbol} ${' '}`)
          if (exch >= buyMinSHIBA && exch <= buyMaxSHIBA) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === POLYGONMATICname) {
          setPOLYGONMATICResult(`${(exchange.converted * 1).toFixed(5)} ${POLYGONMATICSymbol}`)
          setPOLYGONMATICMaxLimite(`${buyMaxPOLYGONMATIC} ${POLYGONMATICSymbol} ${' '}`)
          if (exch >= buyMinPOLYGONMATIC && exch <= buyMaxPOLYGONMATIC) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === ETHEREUMname) {
          setETHEREUMResult(`${(exchange.converted * 1).toFixed(5)} ${ETHEREUMSymbol}`)
          setETHEREUMMaxLimite(`${buyMaxETHEREUM} ${ETHEREUMSymbol} ${' '}`)
          if (exch >= buyMinETHEREUM && exch <= buyMaxETHEREUM) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === POKADOTname) {
          setPOKADOTResult(`${(exchange.converted * 1).toFixed(5)} ${POKADOTSymbol}`)
          setPOKADOTMaxLimite(`${buyMaxPOKADOT} ${POKADOTSymbol} ${' '}`)
          if (exch >= buyMinPOKADOT && exch <= buyMaxPOKADOT) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === CHAINLINKname) {
          setCHAINLINKResult(`${(exchange.converted * 1).toFixed(5)} ${CHAINLINKSymbol}`)
          setCHAINLINKMaxLimite(`${buyMaxCHAINLINK} ${CHAINLINKSymbol} ${' '}`)
          if (exch >= buyMinCHAINLINK && exch <= buyMaxCHAINLINK) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === CARDANOname) {
          setCARDANOResult(`${(exchange.converted * 1).toFixed(5)} ${CARDANOSymbol}`)
          setCARDANOMaxLimite(`${buyMaxCARDANO} ${CARDANOSymbol} ${' '}`)
          if (exch >= buyMinCARDANO && exch <= buyMaxCARDANO) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === SOLANAname) {
          setSOLANAResult(`${(exchange.converted * 1).toFixed(5)} ${SOLANASymbol}`)
          setSOLANAMaxLimite(`${buyMaxSOLANA} ${SOLANASymbol} ${' '}`)
          if (exch >= buyMinSOLANA && exch <= buyMaxSOLANA) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === TONname) {
          setTONResult(`${(exchange.converted * 1).toFixed(5)} ${TONSymbol}`)
          setTONMaxLimite(`${buyMaxTON} ${TONSymbol} ${' '}`)
          if (exch >= buyMinTON && exch <= buyMaxTON) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
        if (services.serviceName === 'Binance Smart Chain') {
          setBNBResult(`${(exchange.converted * 1).toFixed(5)} BNB`)
          setBNBMaxLimite(`${buyMaxBSC} BNB ${' '}`)
          if (exch >= buyMinBSC && exch <= buyMaxBSC) {
            setMinOrMax(true)
          } else {
            setMinOrMax(false)
          }
        }
      }
    }

  }, [
    secondsRemaining1,
    exch,
    services.serviceName,
    exchange.converted,

    BSCname,
    BTCname,
    TRONname,
    DOGEname,
    BUSDname,
    SHIBAname,
    POLYGONMATICname,
    ETHEREUMname,
    POKADOTname,
    CHAINLINKname,
    CARDANOname,
    SOLANAname,
    LTCname,
    PMname,
    TONname,

    BTCSymbol,
    TRONSymbol,
    DOGESymbol,
    BUSDSymbol,
    SHIBASymbol,
    POLYGONMATICSymbol,
    ETHEREUMSymbol,
    POKADOTSymbol,
    CHAINLINKSymbol,
    CARDANOSymbol,
    SOLANASymbol,
    LTCSymbol,
    PMSymbol,
    TONSymbol,

    buyMinBTC,
    buyMinTRON,
    buyMinLTC,
    buyMinUSDT,
    buyMinPM,
    buyMinDOGE,
    buyMinBUSD,
    buyMinSHIBA,
    buyMinPOLYGONMATIC,
    buyMinETHEREUM,
    buyMinPOKADOT,
    buyMinCHAINLINK,
    buyMinCARDANO,
    buyMinSOLANA,
    buyMinBSC,
    buyMinTON,

    buyMaxBTC,
    buyMaxTRON,
    buyMaxLTC,
    buyMaxUSDT,
    buyMaxPM,
    buyMaxDOGE,
    buyMaxBUSD,
    buyMaxSHIBA,
    buyMaxPOLYGONMATIC,
    buyMaxETHEREUM,
    buyMaxPOKADOT,
    buyMaxCHAINLINK,
    buyMaxCARDANO,
    buyMaxSOLANA,
    buyMaxBSC,
    buyMaxTON,
    ItemStatusCliked
  ])


  const OperationUId = uid(8)

  const tetherNet = services.serviceName === 'Tether(USDT)' ? tetherActionSelector : services.serviceName === BUSDname ?  busdActionSelector : services.serviceName === SHIBAname ? shibaActionSelector : services.serviceName === POKADOTname ? polkadotActionSelector : services.serviceName === POLYGONMATICname ? maticActionSelector : services.serviceName === SOLANAname ? solanaActionSelector :services.serviceName === CHAINLINKname ? linkActionSelector : services.serviceName === CARDANOname ? cardanoActionSelector : 'no net'


  function placeOrderHandler () {
    setNkabSpinner(true)
    const servicesId = services.ID
    const serviceName = services.serviceName
    const serviceSymbol = services.serviceSymbol
    const serviceImageLink = services.serviceImageLink
    const operatorListName = operatorNames
    const operatorUserInfoId = UserID
    const operatorUserInfoName = userName
    const operatorUserInfoLastName = userLastName
    const operatorUserInfoPhone = 
    `${Country}${contact.length === 15 ? contact.slice(5,18) : 
    contact.length === 14 ? contact.slice(4,18) : 
    contact.length === 13 ? contact.slice(3,18) : 
    contact.length === 12 ? contact.slice(4,18) : 
    contact.length === 11 ? contact.slice(3,18) : 
    contact}`
    const operatorUserInfoCountry = SenderCountry
    const codePostalCountry = SenderCountry 
    const operatorAction = ItemStatusCliked

    const notifierId = UserID
    const notifyImageLink = serviceName
    const notifyServicesId = servicesId
    const notifyOperatorAction = operatorAction

    if (!MinOrMax || !operatorNames || serviceName === "--Sélectionnez la monnaie--") {
    } else if ( exchange && ( ItemStatusCliked === 'buy' || ItemStatusCliked === 'sel') && contact.length > 0) {
      if ( ItemStatusCliked === 'buy' && operatorUserInfoPhone ) {
  
        localStorage.setItem('servicesId',                servicesId)
        localStorage.setItem('serviceName',               serviceName)
        localStorage.setItem('serviceSymbol',             serviceSymbol)
        localStorage.setItem('serviceImageLink',          serviceImageLink)
        localStorage.setItem('rate',                      rateCondition)
        localStorage.setItem('adr_Coin',                  adr_Coin)
        localStorage.setItem('montant',                   exchange.montant)
        localStorage.setItem('converted',                 exchange.converted)
        localStorage.setItem('cfa',                       exchange.cfa)
        localStorage.setItem('dollar',                    exchange.dollar)
        localStorage.setItem('operatorListName',          operatorListName)
        localStorage.setItem('operatorAction',            operatorAction)
        localStorage.setItem('operatorUserInfoId',        operatorUserInfoId)
        localStorage.setItem('operatorUserInfoName',      operatorUserInfoName)
        localStorage.setItem('operatorUserInfoLastName',  operatorUserInfoLastName)
        localStorage.setItem('operatorUserInfoPhone',     operatorUserInfoPhone)
        localStorage.setItem('codePostalCountry',         codePostalCountry)
        localStorage.setItem('operatorUserInfoCountry',   operatorUserInfoCountry)
        localStorage.setItem('tetherNet',                 tetherNet)
        localStorage.setItem('memo',                      Memo)

        if(OperationUId){
        
          axios.post(
              '/api/v1/notification/create-notif',
              {
                notifierId,
                notifyImageLink,
                notifyServicesId,
                notifyOperatorAction,
                notifySenderName : userName,
                notifySenderPhone : operatorUserInfoPhone,
                notifySenderEmail : userEmail,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            ).then((res)=>{
              if(res){
                props.history.push(`/order/${OperationUId}`)
              }
            })
        }
      } else if ( ItemStatusCliked === 'sel' && operatorUserInfoPhone && contact.length > 0 ) {

        localStorage.setItem('servicesId',                servicesId)
        localStorage.setItem('serviceName',               serviceName)
        localStorage.setItem('serviceSymbol',             serviceSymbol)
        localStorage.setItem('serviceImageLink',          serviceImageLink)
        localStorage.setItem('rate',                      rateCondition)
        localStorage.setItem('adr_Coin',                  'no adress')
        localStorage.setItem('montant',                   exchange.montant)
        localStorage.setItem('converted',                 exchange.converted)
        localStorage.setItem('cfa',                       exchange.cfa)
        localStorage.setItem('dollar',                    exchange.dollar)
        localStorage.setItem('operatorListName',          operatorListName)
        localStorage.setItem('operatorAction',            operatorAction)
        localStorage.setItem('operatorUserInfoId',        operatorUserInfoId)
        localStorage.setItem('operatorUserInfoName',      operatorUserInfoName)
        localStorage.setItem('operatorUserInfoLastName',  operatorUserInfoLastName)
        localStorage.setItem('operatorUserInfoPhone',     operatorUserInfoPhone)
        localStorage.setItem('codePostalCountry',         codePostalCountry)
        localStorage.setItem('operatorUserInfoCountry',   operatorUserInfoCountry)
        localStorage.setItem('tetherNet',                 tetherNet)
        localStorage.setItem('memo',                      'no memo')
        
        if(OperationUId){
        
          axios.post(
              '/api/v1/notification/create-notif',
              {
                notifierId,
                notifyImageLink,
                notifyServicesId,
                notifyOperatorAction,
                notifySenderName : userName,
                notifySenderPhone : operatorUserInfoPhone,
                notifySenderEmail : userEmail,
                createdAt: new Date(),
                updatedAt: new Date(),
              },
            ).then((res)=>{
              if(res){
                props.history.push(`/order/${OperationUId}`)
              }
            })
        }
      }
    }
  }

  const choiceHandler = () => {
    setInterChoice(true)
  }
  const choiceHandler2 = () => {
    setInterChoice(false)
  }

  const [TetherItemSelector,    setTetherItemSelector]    = useState("")
  const [TetherItemSelector2,   setTetherItemSelector2]   = useState("")

  const [BusdItemSelector,      setBusdItemSelector]      = useState("")
  const [BusdItemSelector2,     setBusdItemSelector2]     = useState("")

  const [ShibaItemSelector,     setShibaItemSelector]     = useState("")
  const [ShibaItemSelector2,    setShibaItemSelector2]    = useState("")

  const [SolanaItemSelector,    setSolanaItemSelector]    = useState("")
  const [SolanaItemSelector2,   setSolanaItemSelector2]   = useState("")

  const [CardanoItemSelector,   setCardanoItemSelector]   = useState("")
  const [CardanoItemSelector2,  setCardanoItemSelector2]  = useState("")

  const [PolkadotItemSelector,  setPolkadotItemSelector]  = useState("")
  const [PolkadotItemSelector2, setPolkadotItemSelector2] = useState("")

  const [MaticItemSelector,     setMaticItemSelector]     = useState("")
  const [MaticItemSelector2,    setMaticItemSelector2]    = useState("")

  const [LinkItemSelector,      setLinkItemSelector]      = useState("")
  const [LinkItemSelector2,     setLinkItemSelector2]     = useState("")

  function tetherActionSelector1() {
    setNkabSpinner(false)
    setTetherActionSelector('trc20')
    setTetherItemSelector('selected')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function tetherActionSelector2() {
    setNkabSpinner(false)
    setTetherActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('selected')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function busdActionSelector2() {
    setNkabSpinner(false)
    setBusdActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('selected')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function shibaActionSelector1() {
    setNkabSpinner(false)
    setShibaActionSelector('erc20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('selected')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function shibaActionSelector2() {
    setNkabSpinner(false)
    setShibaActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('selected')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function solanaActionSelector1() {
    setNkabSpinner(false)
    setSolanaActionSelector('solana')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('selected')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function solanaActionSelector2() {
    setNkabSpinner(false)
    setSolanaActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('selected')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function cardanoActionSelector1() {
    setNkabSpinner(false)
    setCardanoActionSelector('cardano')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('selected')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function cardanoActionSelector2() {
    setNkabSpinner(false)
    setCardanoActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('selected')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setPolkadotActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function polkadotActionSelector1() {
    setNkabSpinner(false)
    setPolkadotActionSelector('polkadot')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('selected')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function polkadotActionSelector2() {
    setNkabSpinner(false)
    setPolkadotActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('selected')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setMaticActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function maticActionSelector1() {
    setNkabSpinner(false)
    setMaticActionSelector('matic')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('selected')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function maticActionSelector2() {
    setNkabSpinner(false)
    setMaticActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('selected')
    setLinkItemSelector('')
    setLinkItemSelector2('')
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setLinkActionSelector(false)
    setItemsOneOpener(false)
  }

  function linkActionSelector1() {
    setNkabSpinner(false)
    setLinkActionSelector('erc20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('selected')
    setLinkItemSelector2('')
    setMaticActionSelector(false)
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setItemsOneOpener(false)
  }

  function linkActionSelector2() {
    setNkabSpinner(false)
    setLinkActionSelector('bep20')
    setTetherItemSelector('')
    setTetherItemSelector2('')
    setBusdItemSelector('')
    setBusdItemSelector2('')
    setShibaItemSelector('')
    setShibaItemSelector2('')
    setSolanaItemSelector('')
    setSolanaItemSelector2('')
    setCardanoItemSelector('')
    setCardanoItemSelector2('')
    setPolkadotItemSelector('')
    setPolkadotItemSelector2('')
    setMaticItemSelector('')
    setMaticItemSelector2('')
    setLinkItemSelector('')
    setLinkItemSelector2('selected')
    setMaticActionSelector(false)
    setBusdActionSelector(false)
    setTetherActionSelector(false)
    setShibaActionSelector(false)
    setSolanaActionSelector(false)
    setCardanoActionSelector(false)
    setPolkadotActionSelector(false)
    setItemsOneOpener(false)
  }
    const loadingAdres = "Contrôle d'adresse entrant en cours..."

    var OPChecked = ItemStatusCliked === 'sel'?"sell-op-session-checked":ItemStatusCliked === 'buy'?"buy-op-session-checked":''

    function GoBack(){
      setPolkadotActionSelector(false)
      setBusdActionSelector(false)
      setTetherActionSelector(false)
      setShibaActionSelector(false)
      setSolanaActionSelector(false)
      setCardanoActionSelector(false)
      setMaticActionSelector(false)
      setLinkActionSelector(false)
  
      localStorage.removeItem('servicesId')
      localStorage.removeItem('serviceName')
      localStorage.removeItem('serviceSymbol')
      localStorage.removeItem('serviceImageLink')
      localStorage.removeItem('rate')
      localStorage.removeItem('adr_Coin')
      localStorage.removeItem('montant')
      localStorage.removeItem('converted')
      localStorage.removeItem('cfa')
      localStorage.removeItem('dollar')
      localStorage.removeItem('operatorListName')
      localStorage.removeItem('operatorAction')
      localStorage.removeItem('operatorUserInfoId')
      localStorage.removeItem('operatorUserInfoName')
      localStorage.removeItem('operatorUserInfoLastName')
      localStorage.removeItem('operatorUserInfoEmail')
      localStorage.removeItem('operatorUserInfoPhone')
      localStorage.removeItem('codePostalCountry')
      localStorage.removeItem('operatorUserInfoCountry')
      localStorage.removeItem('tetherNet')
      localStorage.removeItem('memo')

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
      } else {
         window.location.replace("http://localhost:3000/sell-or-buy-crypto");
      }
    }
  
    function handleClose2 () {
  
      setOpen(false);
  
      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
  
    };
    

    
  return (
    <div>
        <div className={`${themeMode === "true" ? "d-flex justify-content-center nkab-lite-rate-t p-3 mt-4" : "d-flex justify-content-center nkab-lite-rate p-3 mt-4"}`} style={{color:`${themeMode === "true" ? "#90d9ff" : "#00360c"}`}}>
          <div className="col-sm-12 text-center">
            <div className="nkab-lite-rate-content">
              <span><CampaignIcon style={{fontSize:"30px"}}/></span> <span>Vendez vos monnaies à {NT_rate2USDT} XOF chez NKAB Exchange.</span>
            </div>
            <div className='nkab-annonceur'>Dorénavant, pour toute assistance, nous vous prions de bien vouloir nous contacter via WhatsApp au numéro suivant: <strong className='nkab-annonceur-num'>+225 07 48 09 81 05</strong></div>
          </div>
        </div>
      <div className='container mt-5'>
        <div className="main-session" >
          <br/>
          <div className='nkp__version-modal'>
              <div className='d-flex justify-content-between pr-4 pl-4'>
                  <div>
                    {
                      ItemStatusCliked === 'sel' || ItemStatusCliked === 'buy' ?
                      <span className='pointer' onClick={GoBack} style={{ color: '#0a146e', backgroundColor:'#90d9ff', borderRadius:'50px', padding:'3px', fontSize:"13px" }} >
                        <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Retour
                      </span>:null
                    }
                  </div>
                  <div className='mr-4 color-or'>
                      Pro <ReactSwitch className='react-switch-handle' checked={checked} onChange={toogleHandleChange} />
                  </div>
              </div>
          </div>
          <br/>

          <div>
            {
              ItemStatusCliked === 'sel' || ItemStatusCliked === 'buy' ? 
              <div style={{marginTop:'-30px'}} className='pb-3 pl-2 nkp__version-modal_lite'>
                <span className='pointer' onClick={GoBack} style={{ color: '#0a146e', backgroundColor:'#90d9ff', borderRadius:'50px', padding:'3px', fontSize:"13px" }} >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Retour
                </span>
              </div>:null
            }
            <div className="d-flex justify-content-center">
              <div className={`buy-seesion justify-content-center ${OPChecked}`}>
                { ItemStatusCliked === 'sel' ? (
                  ////////////////////////////////////////////// SELL ///////////////////////////////////////////////
                  <>
                    <div className='d-flex justify-content-around'>
                      <div>
                        <div className='pt-2'>
                          {
                            SenderCountry === ci ?
                            <img className='images-drapeau mr-2' src="../../images/ci_logo.png" alt="" />:
                            SenderCountry === sen ?
                            <img className='images-drapeau mr-2' src="../../images/sen_logo.png" alt="" />:
                            SenderCountry === burk ?
                            <img className='images-drapeau mr-2' src="../../images/burk_logo.png" alt="" />:
                            SenderCountry === benin ?
                            <img className='images-drapeau mr-2' src="../../images/benin_logo.png" alt="" />:
                            SenderCountry === togo ?
                            <img className='images-drapeau mr-2' src="../../images/togo_logo.png" alt="" />:
                            SenderCountry === mali ?
                            <img className='images-drapeau mr-2' src="../../images/mali_logo.png" alt="" />:
                            SenderCountry === niger ?
                            <img className='images-drapeau mr-2' src="../../images/niger_logo.png" alt="" />:null
                          }
                          <span className="color-white">{SenderCountry}</span>
                        </div>
                      </div>
                        <div>
                          <div className='pt-2'>
                            <img className='images-drapeau mr-2' src={services.serviceImageLink} alt="" />
                            {/* <span className="color-white">{services.serviceName}</span> */}
                          </div>
                        </div>
                    </div>
                    <br />
                    <div style={{width:'100%'}}>
                        <div className='d-flex justify-content-around rate-bg' style={{width:'100%'}}>
                          <div className="color-w"> Taux </div>
                          <div className="success pl-5"> {rateCondition} XOF </div>
                        </div>
                    </div>
                    <div className='mt-4'>
                      {SellInput > 0 && ((services.serviceName === 'Tether(USDT)' || services.serviceName === BUSDname || services.serviceName === SHIBAname || services.serviceName === POKADOTname || services.serviceName === POLYGONMATICname || services.serviceName === SOLANAname || services.serviceName === CHAINLINKname || services.serviceName === CARDANOname) && !tetherNet) ? <div className='text-center text-danger'><span>Vous devez sélectionner un réseau *</span></div>:null}
                      { services.serviceName === 'Tether(USDT)' ?
                        <div className="__content bolder pt-3">
                          <div className='d-flex justify-content-around mb-2'>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${TetherItemSelector}`} onClick={tetherActionSelector1}>
                              <span>TRC20</span>
                            </div>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${TetherItemSelector2}`} onClick={tetherActionSelector2}>
                              <span>BEP20</span>
                            </div>
                          </div>
                        </div>:
                        services.serviceName === BUSDname ?
                        <div className="__content bolder pt-3">
                          <div className='d-flex justify-content-around mb-2'>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${BusdItemSelector2}`} onClick={busdActionSelector2}>
                              <span>BEP20</span>
                            </div>
                          </div>
                        </div>:
                        services.serviceName === SHIBAname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${ShibaItemSelector}`} onClick={shibaActionSelector1}>
                                <span>ERC20</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${ShibaItemSelector2}`} onClick={shibaActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === POLYGONMATICname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${MaticItemSelector}`} onClick={maticActionSelector1}>
                                <span>MATIC</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${MaticItemSelector2}`} onClick={maticActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === CHAINLINKname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${LinkItemSelector}`} onClick={linkActionSelector1}>
                                <span>ERC20</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${LinkItemSelector2}`} onClick={linkActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === POKADOTname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${PolkadotItemSelector}`} onClick={polkadotActionSelector1}>
                                <span>POLKADOT</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${PolkadotItemSelector2}`} onClick={polkadotActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === CARDANOname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${CardanoItemSelector}`} onClick={cardanoActionSelector1}>
                                <span>CARDANO</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${CardanoItemSelector2}`} onClick={cardanoActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === SOLANAname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${SolanaItemSelector}`} onClick={solanaActionSelector1}>
                                <span>SOLANA</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${SolanaItemSelector2}`} onClick={solanaActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>
                      :null}
                    </div>
                    { NetAlert === "Vous devez choisir un réseau!" ? <div className='text-center mt-2'><span className='text-danger'>{NetAlert}</span></div> : null }
                    <div className="d-felx justify-content-between">
                      {NetAlert === "Vous devez choisir un réseau!" ? null : <>
                      {SellInput > 0 && !OpSelcted ? <div className='text-center text-danger mt-4'><span>Vous devez sélectionner un opérateur *</span></div>:null}
                        {
                          SenderCountry === ci ? 
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !OMState ? <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !MOMOState ? 
                                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !MOOVState ? 
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !WAVEState ? 
                                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === sen ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !OMState ? <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              <span className={`m-2 pointer nkp_bank_option ${FmOptionClicked}`} onClick={FreeHanlder}><img src="../../images/fm.png" width="50" alt="nkab drapeau" /></span>
                              {
                                !WAVEState ? 
                                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === mali ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !OMState ? <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !WAVEState ? 
                                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === togo ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              <span className={`m-2 pointer nkp_bank_option ${TmOptionClicked}`} onClick={TMHanlder}><img src="../../images/tm.png" width="50" alt="nkab drapeau" /></span>
                              {
                                !MOOVState ? 
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === burk ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !OMState ? <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !MOOVState ? 
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !WAVEState ? 
                                <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === benin ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !MOMOState ? 
                                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !MOOVState ? 
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> :
                          SenderCountry === niger ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              {
                                !OMState ? <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                              {
                                !MOOVState ? 
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>:null
                              }
                          </div> : null
                        }
                        </>
                      }
                      {SellInput > 0 && OpSelcted && contact.length === 0 ? <div className='text-center text-danger mt-4'><span>Vous devez saisir un contact *</span></div>:null}
                      {!operatorNames ? null : 
                      <div className='pt-2 d-flex'>
                        <input
                          className="buy-element-input text-center"
                          type=""
                          placeholder={`${Country}${' XX XX XX XX'}`}
                          onChange={phoneHandleChange}
                          required
                          style={{width:'100%', borderRadius:"50px 50px 50px 50px", backgroundColor: "#162942", color: "#bae7ff"}}
                        />
                      </div>}
                      {adr_Coin.length > 0 && !Quantity ? <div className='text-center text-danger mt-4'><span>Vous devez saisir un montant *</span></div>:null}
                      <div className="d-flex mt-2 mb-3">
                        {services.serviceName === BTCname ? (
                          <>
                          {
                            !BTCprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité BTC`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) :services.serviceName === TRONname ? (
                          <>
                          {
                            !TRONprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité TRX`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === LTCname ? (
                          <>
                          {
                            !LTCprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité LTC`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === DOGEname ? (
                          <>
                          {
                            !DOGEprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité DOGE`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === BUSDname ? (
                          <>
                          {
                            !BUSDprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité BUSD`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === SHIBAname ? (
                          <>
                          {
                            !SHIBAprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité SHIBA`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === POLYGONMATICname ? (
                          <>
                          {
                            !POLYGONMATICprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité POLYGONMATIC`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === ETHEREUMname ? (
                          <>
                          {
                            !ETHEREUMprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité ETHEREUM`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === POKADOTname ? (
                          <>
                          {
                            !POKADOTprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité POKADOT`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === CHAINLINKname ? (
                          <>
                          {
                            !CHAINLINKprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité CHAINLINK`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === CARDANOname ? (
                          <>
                          {
                            !CARDANOprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité CARDANO`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === SOLANAname ? (
                          <>
                          {
                            !SOLANAprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité SOLANA`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === TONname ? (
                          <>
                          {
                            !TONprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité TON`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ): services.serviceName === 'Binance Smart Chain' ? (
                          <>
                          {
                            !BSCprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité BNB`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : services.serviceName === 'Tether(USDT)' ? (
                          <>
                          {
                            !USDTprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <>
                            {tetherActionSelector === 'trc20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité TRC20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : tetherActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : busdActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :shibaActionSelector === 'erc20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité ERC20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : shibaActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :solanaActionSelector === 'solana' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité SOLANA`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : solanaActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :cardanoActionSelector === 'cardano' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité CARDANO`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : cardanoActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :polkadotActionSelector === 'polkadot' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité POLKADOT`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : polkadotActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :maticActionSelector === 'matic' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité MATIC`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : maticActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) :linkActionSelector === 'erc20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité ERC20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : linkActionSelector === 'bep20' ? (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité BEP20`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            ) : (
                              <input
                                className="buy-element-input text-center"
                                type=""
                                name="montant"
                                placeholder={`Saisir la quantité USDT`}
                                required
                                onChange={handleChange}
                                style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                              />
                            )}
                            </>
                          }
                          </>
                        ) : services.serviceName === PMname ? (
                          <>
                          {
                            !PMprice?<span className='text-danger'>Non actif pour l'instant!</span>:
                            <input
                              className="buy-element-input text-center"
                              type=""
                              name="montant"
                              placeholder={`Saisir la quantité PM`}
                              required
                              onChange={handleChange}
                              style={{width:'100%', borderRadius:"50px 50px 50px 50px", height:'50%', backgroundColor: "#162942", color: "#bae7ff"}}
                            />
                          }
                          </>
                        ) : null}
                        <input
                          className="color-w text-center"
                          type=""
                          name="resultat"
                          required
                          disabled
                          value={`${(exchange.cfa * 1).toFixed(0)} CFA`}
                          style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                        />
                      </div>
                      {
                        NkabSpinner ? <>
                        {services.serviceName === BTCname && exchange.converted < sellMinBTC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === TRONname && exchange.converted < sellMinTRON ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === LTCname && exchange.converted < sellMinLTC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === DOGEname && exchange.converted < sellMinDOGE ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === BUSDname && exchange.converted < sellMinBUSD ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === SHIBAname && exchange.converted < sellMinSHIBA ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === POLYGONMATICname && exchange.converted < sellMinPOLYGONMATIC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === ETHEREUMname && exchange.converted < sellMinETHEREUM ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === POKADOTname && exchange.converted < sellMinPOKADOT ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === CHAINLINKname && exchange.converted < sellMinCHAINLINK ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === CARDANOname && exchange.converted < sellMinCARDANO ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === SOLANAname && exchange.converted < sellMinSOLANA ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === TONname && exchange.converted < sellMinTON ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === 'Binance Smart Chain' && exchange.converted < sellMinBSC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === 'Tether(USDT)' && exchange.converted < sellMinUSDT ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === PMname && exchange.converted < sellMinPM ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        </>:null
                      }
                      <div className="d-flex justify-content-between">
                        <span className="color-w" >Montant min :</span>
                        <span className="montant_minim bolder">
                          {services.serviceName === BTCname ? ( <> {sellMinBTC} {BTCSymbol}{' '} </> ) : null}
                          {services.serviceName === TRONname ? ( <> {sellMinTRON} {'TRX'}{' '} </> ) : null}
                          {services.serviceName === LTCname ? ( <> {sellMinLTC} {LTCSymbol}{' '} </> ) : null}
                          {services.serviceName === DOGEname ? ( <> {sellMinDOGE} {DOGESymbol}{' '} </> ) : null}
                          {services.serviceName === BUSDname ? ( <> {sellMinBUSD} {BUSDSymbol}{' '} </> ) : null}
                          {services.serviceName === SHIBAname ? ( <> {sellMinSHIBA} {SHIBASymbol}{' '} </> ) : null}
                          {services.serviceName === POLYGONMATICname ? ( <> {sellMinPOLYGONMATIC} {POLYGONMATICSymbol}{' '} </> ) : null}
                          {services.serviceName === ETHEREUMname ? ( <> {sellMinETHEREUM} {ETHEREUMSymbol}{' '} </> ) : null}
                          {services.serviceName === POKADOTname ? ( <> {sellMinPOKADOT} {POKADOTSymbol}{' '} </> ) : null}
                          {services.serviceName === CHAINLINKname ? ( <> {sellMinCHAINLINK} {CHAINLINKSymbol}{' '} </> ) : null}
                          {services.serviceName === CARDANOname ? ( <>{sellMinCARDANO} {CARDANOSymbol}{' '} </> ) : null}
                          {services.serviceName === SOLANAname ? ( <> {sellMinSOLANA} {SOLANASymbol}{' '} </> ) : null}
                          {services.serviceName === 'Binance Smart Chain' ? ( <> {sellMinBSC} {'BNB'}{' '}  </> ) : null}
                          {services.serviceName === 'Tether(USDT)' ? ( <> $ {sellMinUSDT} </> ) : null}
                          {services.serviceName === PMname ?  <> $ {sellMinPM} </> : null}
                          {services.serviceName === TONname ?  <> $ {sellMinTON} </> : null}
                        </span>
                      </div>
                      {
                        NkabSpinner ? <>
                        {services.serviceName === BTCname && exchange.converted > sellMaxBTC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === TRONname && exchange.converted > sellMaxTRON ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === LTCname && exchange.converted > sellMaxLTC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === DOGEname && exchange.converted > sellMaxDOGE ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === BUSDname && exchange.converted > sellMaxBUSD ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === SHIBAname && exchange.converted > sellMaxSHIBA ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === POLYGONMATICname && exchange.converted > sellMaxPOLYGONMATIC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === ETHEREUMname && exchange.converted > sellMaxETHEREUM ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === POKADOTname && exchange.converted > sellMaxPOKADOT ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === CHAINLINKname && exchange.converted > sellMaxCHAINLINK ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === CARDANOname && exchange.converted > sellMaxCARDANO ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === SOLANAname && exchange.converted > sellMaxSOLANA ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === TONname && exchange.converted > sellMaxTON ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === 'Binance Smart Chain' && exchange.converted > sellMaxBSC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === 'Tether(USDT)' && exchange.converted > sellMaxUSDT ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === PMname && exchange.converted > sellMaxPM ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        </>:null
                      }
                      <div className="d-flex justify-content-between">
                        <span className="color-w" >Montant max :</span>
                        <span className="montant_minim bolder">
                          
                          {services.serviceName === BTCname ? ( <> {sellMaxBTC} {BTCSymbol}{' '} </> ) : null}
                          {services.serviceName === TRONname ? ( <> {sellMaxTRON} {'TRX'}{' '} </> ) : null}
                          {services.serviceName === LTCname ? ( <> {sellMaxLTC} {LTCSymbol}{' '} </> ) : null}
                          {services.serviceName === DOGEname ? ( <> {sellMaxDOGE} {DOGESymbol}{' '} </> ) : null}
                          {services.serviceName === BUSDname ? ( <> {sellMaxBUSD} {BUSDSymbol}{' '} </> ) : null}
                          {services.serviceName === SHIBAname ? ( <> {sellMaxSHIBA} {SHIBASymbol}{' '} </> ) : null}
                          {services.serviceName === POLYGONMATICname ? ( <> {sellMaxPOLYGONMATIC} {POLYGONMATICSymbol}{' '} </> ) : null}
                          {services.serviceName === ETHEREUMname ? ( <> {sellMaxETHEREUM} {ETHEREUMSymbol}{' '} </> ) : null}
                          {services.serviceName === POKADOTname ? ( <> {sellMaxPOKADOT} {POKADOTSymbol}{' '} </> ) : null}
                          {services.serviceName === CHAINLINKname ? ( <> {sellMaxCHAINLINK} {CHAINLINKSymbol}{' '} </> ) : null}
                          {services.serviceName === CARDANOname ? ( <> {sellMaxCARDANO} {CARDANOSymbol}{' '} </> ) : null}
                          {services.serviceName === SOLANAname ? ( <> {sellMaxSOLANA} {SOLANASymbol}{' '} </> ) : null}
                          {services.serviceName === 'Binance Smart Chain' ? ( <> {sellMaxBSC} {'BNB'}{' '} </> ) : null}
                          {services.serviceName === 'Tether(USDT)' ? ( <> $ {sellMaxUSDT} </> ) : null}
                          {services.serviceName === PMname ? <> $ {sellMaxPM} </> : null}
                          {services.serviceName === TONname ? <> $ {sellMaxTON} </> : null}
                        </span>
                      </div>
                      <br/>
                      <div className='text-center'>
                        {
                          !NkabSpinner ?
                          <button onClick={placeOrderHandler} className="primary block" >
                            <span className="button-lower uppercase" style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}> Continuer </span>
                          </button> :
                          <button className="primary block d-flex justify-content-center" disabled >
                            <div className="nkab-spinner "></div>
                          </button>
                        }
                      </div>
                      <br/>
                      <div className="alert alert-info alert-dismissible bottom-reserve-alert">
                        <a href="#/" className="close" data-dismiss="alert" aria-label="close" > &times; </a>
                        <div className="text-center">
                          Vous avez un montant supérieur à la réserve ou au montant maximal indiqué ? <br />
                          <strong> Cliquez{' '} <a href="https://wa.me/message/F5ZODUQQAYXCM1"> ici </a>{' '} pour vendre plus </strong>{' '}
                          ! ( <strong className='success'>CASH XOF</strong>)
                        </div>
                      </div>
                    </div>
                  </>
                ) : ////////////////////////////////////////////// BUY ///////////////////////////////////////////////
                ItemStatusCliked === 'buy' ? (
                  <>
                    <div className='d-flex justify-content-around'>
                      <div>
                        <div>
                          <div className='pt-2'>
                            {
                              SenderCountry === ci ?
                              <img className='images-drapeau mr-2' src="../../images/ci_logo.png" alt="" />:
                              SenderCountry === sen ?
                              <img className='images-drapeau mr-2' src="../../images/sen_logo.png" alt="" />:
                              SenderCountry === burk ?
                              <img className='images-drapeau mr-2' src="../../images/burk_logo.png" alt="" />:
                              SenderCountry === benin ?
                              <img className='images-drapeau mr-2' src="../../images/benin_logo.png" alt="" />:
                              SenderCountry === togo ?
                              <img className='images-drapeau mr-2' src="../../images/togo_logo.png" alt="" />:
                              SenderCountry === mali ?
                              <img className='images-drapeau mr-2' src="../../images/mali_logo.png" alt="" />:
                              SenderCountry === niger ?
                              <img className='images-drapeau mr-2' src="../../images/niger_logo.png" alt="" />:null
                            }
                            <span className="color-w">{SenderCountry}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className='pt-2'>
                            <img className='images-drapeau mr-2' src={services.serviceImageLink} alt="" />
                            {/* <span className="color-w">{services.serviceName}</span> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div style={{width: '100%'}}>
                        <div className='d-flex justify-content-around mt-3 rate-bg' style={{width:'100%'}}>
                          <span className="color-w pr-5"> Taux </span>
                          <span className="success pl-5"> {rateCondition} XOF </span>
                        </div>
                    </div>
                    <div className='mt-4'>
                      {adr_Coin.length > 0 && ((services.serviceName === 'Tether(USDT)' || services.serviceName === BUSDname || services.serviceName === SHIBAname || services.serviceName === POKADOTname || services.serviceName === POLYGONMATICname || services.serviceName === SOLANAname || services.serviceName === CHAINLINKname || services.serviceName === CARDANOname) && !tetherNet) ? <div className='text-center text-danger'><span>Vous devez sélectionner un réseau *</span></div>:null}
                      { services.serviceName === 'Tether(USDT)' ?
                        <div className="__content bolder pt-3">
                          <div className='d-flex justify-content-around mb-2'>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${TetherItemSelector}`} onClick={tetherActionSelector1}>
                              <span>TRC20</span>
                            </div>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${TetherItemSelector2}`} onClick={tetherActionSelector2}>
                              <span>BEP20</span>
                            </div>
                          </div>
                        </div>:
                        services.serviceName === BUSDname ?
                        <div className="__content bolder pt-3">
                          <div className='d-flex justify-content-around mb-2'>
                            <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${BusdItemSelector2}`} onClick={busdActionSelector2}>
                              <span>BEP20</span>
                            </div>
                          </div>
                        </div>:
                        services.serviceName === SHIBAname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              {/* <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${ShibaItemSelector}`} onClick={shibaActionSelector1}>
                                <span>ERC20</span>
                              </div> */}
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${ShibaItemSelector2}`} onClick={shibaActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === POLYGONMATICname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${MaticItemSelector}`} onClick={maticActionSelector1}>
                                <span>MATIC</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${MaticItemSelector2}`} onClick={maticActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === CHAINLINKname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${LinkItemSelector}`} onClick={linkActionSelector1}>
                                <span>ERC20</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${LinkItemSelector2}`} onClick={linkActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === POKADOTname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${PolkadotItemSelector}`} onClick={polkadotActionSelector1}>
                                <span>POLKADOT</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${PolkadotItemSelector2}`} onClick={polkadotActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === CARDANOname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${CardanoItemSelector}`} onClick={cardanoActionSelector1}>
                                <span>CARDANO</span>
                              </div>
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${CardanoItemSelector2}`} onClick={cardanoActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>:
                        services.serviceName === SOLANAname ? 
                          <div className="__content bolder pt-3">
                            <div className='d-flex justify-content-around mb-2'>
                              {/* <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${SolanaItemSelector}`} onClick={solanaActionSelector1}>
                                <span>SOLANA</span>
                              </div> */}
                              <div className={`item-poped-in-buy-sell pointer text-center __choice_items ${SolanaItemSelector2}`} onClick={solanaActionSelector2}>
                                <span>BEP20</span>
                              </div>
                            </div>
                          </div>
                      :null}
                    </div>
                    { NetAlert === "Vous devez choisir un réseau!" ? <div className='text-center mt-2'><span className='text-danger'>{NetAlert}</span></div> : null }
                    <div className="d-felx justify-content-between">
                      {NetAlert === "Vous devez choisir un réseau!" ? null : <>
                      {adr_Coin.length > 0 && !OpSelcted ? <div className='text-center text-danger mt-4'><span>Vous devez sélectionner un opérateur *</span></div>:null}
                        {
                          SenderCountry === ci ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                            <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                            <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                            <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                            <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                          </div>:
                          SenderCountry === sen ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                            <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${FmOptionClicked}`} onClick={FreeHanlder}><img src="../../images/fm.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                          </div> :
                          SenderCountry === mali ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                          </div> :
                          SenderCountry === togo ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              <span className={`m-2 pointer nkp_bank_option ${TmOptionClicked}`} onClick={TMHanlder}><img src="../../images/tm.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                          </div> :
                          SenderCountry === burk ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${WaveOptionClicked}`} onClick={WaveHanlder}><img src="../../images/wave.png" width="50" alt="nkab drapeau" /></span>
                          </div> :
                          SenderCountry === benin ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                                <span className={`m-2 pointer nkp_bank_option ${MomoOptionClicked}`} onClick={MobileHanlder}><img src="../../images/momo.png" width="50" alt="nkab drapeau" /></span>
                                <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                          </div> :
                          SenderCountry === niger ?
                          <div className='d-flex justify-content-center uppercase mt-2'>
                              <span className={`m-2 pointer nkp_bank_option ${OmOptionClicked}`} onClick={OrangeHanlder}><img src="../../images/om.png" width="50" alt="nkab drapeau" /></span>
                              <span className={`m-2 pointer nkp_bank_option ${FloozOptionClicked}`} onClick={MoovHanlder}><img src="../../images/flooz.png" width="50" alt="nkab drapeau" /></span>
                          </div> : null
                        }
                        </>
                      }
                      {adr_Coin.length > 0 && OpSelcted && contact.length === 0 ? <div className='text-center text-danger mt-4'><span>Vous devez saisir un contact *</span></div>:null}
                      {!operatorNames ? null : 
                      <div className='pt-2 d-flex'>
                        <input
                          className="buy-element-input text-center"
                          type=""
                          placeholder={`${Country}${' XX XX XX XX'}`}
                          onChange={phoneHandleChange}
                          required
                          style={{width:'100%', borderRadius:"50px 50px 50px 50px", backgroundColor: "#162942", color: "#bae7ff"}}
                        />
                      </div>}
                        {adr_Coin.length > 0 && !Quantity ? <div className='text-center text-danger mt-4'><span>Vous devez saisir un montant *</span></div>:null}
                      <div className="d-flex justify-content-around mt-2">
                        <div className="d-flex justify-content-around">
                          {services.serviceName === BTCname ? (
                              <>
                              {interChoice === true ? 
                                  <input
                                    style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                    type=""
                                    name="converted"
                                    placeholder={`Saisir la quantité ${BTCSymbol}`}
                                    required
                                    onChange={handleChange}
                                  /> : 
                                  <div className="d-flex">
                                    <div onClick={choiceHandler} className="option-button" >
                                      <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                    </div>
                                    <input
                                      style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                      className="color-w text-center uppercase"
                                      type=""
                                      name="resultat"
                                      required
                                      disabled
                                      value={BTCResult}
                                    />
                                  </div>}
                              </>
                          ) :services.serviceName === TRONname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                    style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${TRONSymbol}`}
                                  required
                                  onChange={handleChange}
                                  /> : 
                                  <div className="d-flex">
                                    <div onClick={choiceHandler} className="option-button" >
                                      <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                    </div>
                                    <input
                                      style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                      className="color-w text-center uppercase"
                                      type=""
                                      name="resultat"
                                      required
                                      disabled
                                      value={TRONResult}
                                    />
                                  </div>}
                            </>
                          ) : services.serviceName === LTCname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                    style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${LTCSymbol}`}
                                  required
                                  onChange={handleChange}
                                />: 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={LTCResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === DOGEname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${DOGESymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={DOGEResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === BUSDname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${BUSDSymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={BUSDResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === SHIBAname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${SHIBASymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={SHIBAResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === POLYGONMATICname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${POLYGONMATICSymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={POLYGONMATICResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === ETHEREUMname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${ETHEREUMSymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={ETHEREUMResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === POKADOTname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${POKADOTSymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={POKADOTResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === CHAINLINKname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${CHAINLINKSymbol}`}
                                  required
                                  onChange={handleChange}
                                />: 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={CHAINLINKResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === CARDANOname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${CARDANOSymbol}`}
                                  required
                                  onChange={handleChange}
                                />: 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={CARDANOResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === SOLANAname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${SOLANASymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={SOLANAResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === TONname ? (
                            <>
                            {interChoice === true ? 
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${TONSymbol}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={TONResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === 'Binance Smart Chain' ? (
                            <>
                            {interChoice === true ? 
                                <input
                                    style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Saisir la quantité ${'BNB'}`}
                                  required
                                  onChange={handleChange}
                                /> : 
                                <div className="d-flex">
                                  <div onClick={choiceHandler} className="color-w option-button" >
                                    <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                  </div>
                                  <input
                                    style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                    className="color-w text-center uppercase"
                                    type=""
                                    name="resultat"
                                    required
                                    disabled
                                    value={BNBResult}
                                  />
                                </div>}
                            </>
                          ) : services.serviceName === 'Tether(USDT)' ? (
                            <>
                            {interChoice === true ? (
                              <>
                                {tetherActionSelector === 'trc20' ? (
                                  <input
                                    style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                    type=""
                                    name="converted"
                                    placeholder={`Entrez la quantité`}
                                    required
                                    onChange={handleChange}
                                  />
                                ) : tetherActionSelector === 'bep20' ? (
                                  <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                    className="buy-element-input"
                                    type=""
                                    name="converted"
                                    placeholder={`Entrez la quantité`}
                                    required
                                    onChange={handleChange}
                                  />
                                ) : null}
                              </>
                            ) : (
                              <>
                                {tetherActionSelector === 'trc20' ? (
                                  <div className="d-flex">
                                    <div onClick={choiceHandler} className="color-w option-button" >
                                      <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                    </div>
                                    <input
                                      style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                      className="color-w text-center uppercase"
                                      type=""
                                      name="resultat"
                                      required
                                      disabled
                                      value={USDTResult}
                                    />
                                  </div>
                                ) : tetherActionSelector === 'bep20' ? (
                                  <div className="d-flex">
                                    <div onClick={choiceHandler} className="color-w option-button" >
                                      <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                    </div>
                                    <input
                                      style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                      className="color-w text-center uppercase"
                                      type=""
                                      name="resultat"
                                      required
                                      disabled
                                      value={`$ ${(
                                        exchange.converted * 1).toFixed(2)}`}
                                    />
                                  </div>
                                ) : null}
                              </>
                            )}
                            </>
                          ) : services.serviceName === PMname ? (
                            <>
                            {interChoice === true ? (
                              <>
                                <input
                                  style={{ width:'100%', borderRadius:"50px 50px 50px 50px", height:'80%', backgroundColor: "#162942", color: "#bae7ff"}}
                                  type=""
                                  name="converted"
                                  placeholder={`Entrez la quantité ${PMSymbol}`}
                                  required
                                  onChange={handleChange}
                                />
                              </>
                            ) : (
                              <div className="d-flex">
                                <div onClick={choiceHandler} className="color-w option-button" >
                                  <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                </div>
                                <input
                                  style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                  className="color-w text-center uppercase"
                                  type=""
                                  name="resultat"
                                  required
                                  disabled
                                  value={PMResult}
                                />
                              </div>
                            )}
                            </>
                          ) : null}
                        </div>
                        {
                          services.serviceName === 'Tether(USDT)' && (tetherActionSelector === 'trc20' || tetherActionSelector === 'bep20') ?
                          <div className="d-flex justify-content-around pb-2">
                            {interChoice === true ? (
                              <div className="d-flex pl-2">
                                <div onClick={choiceHandler2} className="color-w option-button" >
                                  <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                </div>
                                <input
                                  style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                  className="color-w text-center"
                                  type=""
                                  name="resultat"
                                  required
                                  disabled
                                  value={`${(exchange.cfa * 1).toFixed(0)} XOF`}
                                />
                              </div>
                            ) : (
                              <>
                                <input
                                  className="adresse_coin"
                                  type=""
                                  name="montant"
                                  placeholder="Montant en CFA"
                                  required
                                  onChange={handleChange}
                                />
                              </>
                            )}
                          </div>:
                          services.serviceName !== 'Tether(USDT)' ?
                          <div className="d-flex justify-content-around pb-2">
                            {interChoice === true ? (
                              <div className="d-flex pl-2">
                                <div onClick={choiceHandler2} className="color-w option-button" >
                                  <span className="inter-choice"> <FontAwesomeIcon icon={faPen} /> </span>
                                </div>
                                <input
                                  style={{width:'100%', fontWeight:'bolder', fontSize:'17px', borderRadius:"50px 50px 50px 50px", backgroundColor: "none", color: "#bae7ff"}}
                                  className="color-w text-center"
                                  type=""
                                  name="resultat"
                                  required
                                  disabled
                                  value={`${(exchange.cfa * 1).toFixed(0)} XOF`}
                                />
                              </div>
                            ) : 
                            <input
                              className="adresse_coin"
                              type=""
                              name="montant"
                              placeholder="Montant en CFA"
                              required
                              onChange={handleChange}
                            />}
                          </div>:null
                        }
                      </div>
                      <div className="d-flex justify-content-around mt-2">
                        <>
                          {tetherActionSelector === 'trc20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse TRC20 (TVPX...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : tetherActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : busdActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : shibaActionSelector === 'erc20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse ERC20..."
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : shibaActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : solanaActionSelector === 'solana' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse SOLANA..."
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : solanaActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : cardanoActionSelector === 'cardano' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse CARDANO"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : cardanoActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : polkadotActionSelector === 'polkadot' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse POLKADOT..."
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : polkadotActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : maticActionSelector === 'matic' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse MATIC"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : maticActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : linkActionSelector === 'erc20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse ERC20"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : linkActionSelector === 'bep20' ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez votre adresse BEP20 (0x1c...)"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) : services.serviceName === PMname ? (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Entrez votre numéro de compte UXXX XXX XX"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          ) :  (
                            <input
                              className="adresse_coin"
                              type="text"
                              placeholder="Copiez collez l'adresse de votre wallet ici"
                              onChange={(e) => setAdr_Coin(e.target.value)}
                            />
                          )}
                        </>
                      </div>
                      {
                        services.serviceName === TONname ?
                        <div className="d-flex justify-content-around mt-2">
                          <input
                            className="adresse_coin"
                            type="text"
                            placeholder="Entrez votre mémo XXX XXX XXX"
                            onChange={(e) => setMemo(e.target.value)}
                          />
                        </div>:null
                      }
                      <br/>
                      {
                        NkabSpinner ? <>
                        {services.serviceName === BTCname && exchange.converted < buyMinBTC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === TRONname && exchange.converted < buyMinTRON ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === LTCname && exchange.converted < buyMinLTC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === DOGEname && exchange.converted < buyMinDOGE ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === BUSDname && exchange.converted < buyMinBUSD ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === SHIBAname && exchange.converted < buyMinSHIBA ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === POLYGONMATICname && exchange.converted < buyMinPOLYGONMATIC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === ETHEREUMname && exchange.converted < buyMinETHEREUM ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === POKADOTname && exchange.converted < buyMinPOKADOT ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === CHAINLINKname && exchange.converted < buyMinCHAINLINK ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === CARDANOname && exchange.converted < buyMinCARDANO ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === SOLANAname && exchange.converted < buyMinSOLANA ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === 'Binance Smart Chain' && exchange.converted < buyMinBSC ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === 'Tether(USDT)' && exchange.converted < buyMinUSDT ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === PMname && exchange.converted < buyMinPM ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        {services.serviceName === TONname && exchange.converted < buyMinTON ? <div className='text-center text-danger mt-4'><span>Minimum non respecté *</span></div> : null}
                        </>:null
                      }
                      <div className="d-flex justify-content-between">
                        <span className="color-w" >Montant min :</span>
                        <span className="montant_minim bolder">
                          {services.serviceName === BTCname ? ( <> {buyMinBTC} {BTCSymbol}{' '}</> ) : null}
                          {services.serviceName === TRONname ? ( <> {buyMinTRON} {'TRX'}{' '} </> ) : null}
                          {services.serviceName === LTCname ? ( <> {buyMinLTC} {LTCSymbol}{' '} </> ) : null}
                          {services.serviceName === DOGEname ? ( <> {buyMinDOGE} {DOGESymbol}{' '} </> ) : null}
                          {services.serviceName === BUSDname ? ( <> {buyMinBUSD} {BUSDSymbol}{' '} </> ) : null}
                          {services.serviceName === SHIBAname ? ( <> {buyMinSHIBA} {SHIBASymbol}{' '} </> ) : null}
                          {services.serviceName === POLYGONMATICname ? ( <> {buyMinPOLYGONMATIC} {POLYGONMATICSymbol}{' '} </> ) : null}
                          {services.serviceName === ETHEREUMname ? ( <> {buyMinETHEREUM} {ETHEREUMSymbol}{' '} </> ) : null}
                          {services.serviceName === POKADOTname ? ( <> {buyMinPOKADOT} {POKADOTSymbol}{' '} </> ) : null}
                          {services.serviceName === CHAINLINKname ? ( <> {buyMinCHAINLINK} {CHAINLINKSymbol}{' '} </> ) : null}
                          {services.serviceName === CARDANOname ? ( <> {buyMinCARDANO} {CARDANOSymbol}{' '} </> ) : null}
                          {services.serviceName === SOLANAname ? ( <> {buyMinSOLANA} {SOLANASymbol}{' '} </> ) : null}
                          {services.serviceName === 'Binance Smart Chain' ? ( <> {buyMinBSC} {'BNB'}{' '} </> ) : null}
                          {services.serviceName === 'Tether(USDT)' ? ( <> $ {buyMinUSDT} </> ) : null}
                          {services.serviceName === PMname ?  <> $ {buyMinPM} </> : null}
                          {services.serviceName === TONname ?  <> $ {buyMinTON} </> : null}
                        </span>
                      </div>
                      {
                        NkabSpinner ? <>
                        {services.serviceName === BTCname && exchange.converted > buyMaxBTC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === TRONname && exchange.converted > buyMaxTRON ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === LTCname && exchange.converted > buyMaxLTC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === DOGEname && exchange.converted > buyMaxDOGE ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === BUSDname && exchange.converted > buyMaxBUSD ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === SHIBAname && exchange.converted > buyMaxSHIBA ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === POLYGONMATICname && exchange.converted > buyMaxPOLYGONMATIC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === ETHEREUMname && exchange.converted > buyMaxETHEREUM ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === POKADOTname && exchange.converted > buyMaxPOKADOT ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === CHAINLINKname && exchange.converted > buyMaxCHAINLINK ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === CARDANOname && exchange.converted > buyMaxCARDANO ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === SOLANAname && exchange.converted > buyMaxSOLANA ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === 'Binance Smart Chain' && exchange.converted > buyMaxBSC ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === 'Tether(USDT)' && exchange.converted > buyMaxUSDT ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === PMname && exchange.converted > buyMaxPM ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        {services.serviceName === TONname && exchange.converted > buyMaxTON ? <div className='text-center text-danger mt-4'><span>Maximum non respecté *</span></div> : null}
                        </>:null
                      }
                      <div className="d-flex justify-content-between">
                        <span className="color-w" >Montant max :</span>
                        <span className="montant_minim bolder">
                          
                          {services.serviceName === BTCname ? BTCMaxLimite : null}
                          {services.serviceName === TRONname ? TRONMaxLimite : null}
                          {services.serviceName === LTCname ? LTCMaxLimite : null}
                          {services.serviceName === DOGEname ? DOGEMaxLimite : null}
                          {services.serviceName === BUSDname ? BUSDMaxLimite : null}
                          {services.serviceName === SHIBAname ? SHIBAMaxLimite : null}
                          {services.serviceName === POLYGONMATICname ? POLYGONMATICMaxLimite : null}
                          {services.serviceName === ETHEREUMname ? ETHEREUMMaxLimite : null}
                          {services.serviceName === POKADOTname ? POKADOTMaxLimite : null}
                          {services.serviceName === CHAINLINKname ? CHAINLINKMaxLimite : null}
                          {services.serviceName === CARDANOname ? CARDANOMaxLimite : null}
                          {services.serviceName === SOLANAname ? SOLANAMaxLimite : null}
                          {services.serviceName === 'Binance Smart Chain' ? BNBMaxLimite : null}
                          {services.serviceName === 'Tether(USDT)' ? USDTMaxLimite : null}
                          {services.serviceName === PMname ? PMMaxLimite : null}
                          {services.serviceName === TONname ? TONMaxLimite : null}

                        </span>
                      </div>
                      <br/>
                      {services.serviceName === TONname ? 
                      <div className='text-center'>
                        {(adr_Coin && adrTotal >= limitAdrCount && adrTotal2 >= limitAdrCount2) ? 
                        <>
                          {
                            !NkabSpinner ?
                            <button onClick={placeOrderHandler} className="primary block" >
                              <span className="button-lower uppercase" style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}> Continuer</span>
                            </button> :
                            <button className="primary block d-flex justify-content-center" disabled >
                              <div className="nkab-spinner "></div>
                            </button>
                          }
                        </> : adr_Coin && adrTotal ? <LoadingBox loadingType={loadingAdres} />: 
                            <span className="adr_verification" >
                            {' '}
                            Veuillez entrer une adresse wallet correcte pour terminer !{' '}
                          </span>}
                      </div>:
                      <div className='text-center'>
                        {adr_Coin && adrTotal >= limitAdrCount ? 
                        <>
                          {
                            !NkabSpinner ?
                            <button onClick={placeOrderHandler} className="primary block" >
                              <span className="button-lower uppercase" style={{color:`${themeMode === "true" ? "#0E233A" : "#fff"}`}}> Continuer.. </span>
                            </button> :
                            <button className="primary block d-flex justify-content-center" disabled >
                              <div className="nkab-spinner "></div>
                            </button>
                          }
                        </> : adr_Coin && adrTotal ? 
                            <LoadingBox loadingType={loadingAdres} />: 
                            <span className="adr_verification" >
                            {' '}
                            Veuillez entrer une adresse wallet correcte pour terminer !{' '}
                          </span>}
                      </div>}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-flex justify-content-around">
                      <div className="text-center">
                        <div className='animate__animated animate__backInLeft'> <a className="__button fire" style={{textDecorationLine:'none'}} href="#g5Ps09Ez7">J'ACHETE</a> </div>
                        {
                          ItemStatusCliked === 'buy' ?
                          <><span className='animate__animated animate__backInUp' style={{color:"#59bd38", backgroundColor:"#152438", padding:"3px", borderRadius:"5px", fontSize:"12px"}}>AVOIR DES CRYPTOS</span><span className="success"> ✔ </span></>:
                          <span className='animate__animated animate__backInUp' style={{color:"#59bd38", backgroundColor:"#152438", padding:"3px", borderRadius:"5px", fontSize:"12px"}}>AVOIR DES CRYPTOS</span>
                        }
                      </div>
                      <div className="text-center">
                        <div className='animate__animated animate__backInRight'> <a className="__button ice" style={{textDecorationLine:'none'}} href="#YjZ2c0HjF">JE VENDS</a> </div>
                        {
                          ItemStatusCliked === 'sel' ?
                          <><span className='animate__animated animate__backInUp' style={{color:"#d44040", backgroundColor:"#152438", padding:"3px", borderRadius:"5px", fontSize:"12px"}}>RETIRER EN FCFA(XOF)</span><span className="success"> ✔ </span></>:
                          <span className='animate__animated animate__backInUp' style={{color:"#d44040", backgroundColor:"#152438", padding:"3px", borderRadius:"5px", fontSize:"12px"}}>RETIRER EN FCFA(XOF)</span>
                        }
                      </div>
                    </div>

                    <div id="g5Ps09Ez7" className="__overlay">
                      <div className={`${themeMode === "true" ? "__popup __popup-t" : "__popup"}`}>
                      {Logged === 'Logout' ?
                        <Alert severity="warning"> Vous n'êtes pas connecté ! </Alert>:
                        <h4 style={{fontFamily:'Palatino, URW Palladio L, serif', color:'#04810f'}} className='bolder'>Que voulez-vous acheter ?</h4>}

                        <a className="__close" href="#/">&times;</a>
                          {
                            Logged === 'Logout' ? 
                            <div className='focus-content text-center'>
                              <br/>
                              <span>Veuillez vous connecter pour commencer!</span>
                              <div className='focus-button-wrapper'>
                                <button className="focus-btn focus-outline" onClick={handleClose}><a href="#/">NON MERCI</a></button>
                                <button className="focus-btn focus-fill" onClick={handleClose2}>SE CONNECTER</button>
                              </div>
                            </div>:
                            <div className="__content">
                              <div className='d-flex justify-content-around mb-2'>
                                <div className='item-poped pointer bg-color-grey'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyBtcClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/btc.png" alt="" /></span>
                                    <span className='lite-currency-item'>BTC</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyUsdtClick}>
                                  {/* <div style={{textDecorationLine:'none'}} onClick={BuyUsdtClick}> */}
                                    <span><img className='images-drapeau mr-2' src="../../images/usdt.png" alt="" /></span>
                                    <span className='lite-currency-item'>USDT</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyBusdClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/busd.png" alt="" /></span>
                                    <span className='lite-currency-item'>BUSD</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyDotClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/pokadot.png" alt="" /></span>
                                    <span className='lite-currency-item'>POL</span>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around mb-2'>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyLtcClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/ltc.png" alt="" /></span>
                                    <span className='lite-currency-item'>LTC</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyDogeClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/doge.png" alt="" /></span>
                                    <span className='lite-currency-item'>DOGE</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyShibaClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/shiba.png" alt="" /></span>
                                    <span className='lite-currency-item'>SHIB</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyLinkClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/chainlink.png" alt="" /></span>
                                    <span className='lite-currency-item'>LINK</span>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around mb-2'>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyBnbClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/bnb.png" alt="" /></span>
                                    <span className='lite-currency-item'>BNB</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyTronClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/tron.png" alt="" /></span>
                                    <span className='lite-currency-item'>TRON</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyMaticClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/polygonmatic.png" alt="" /></span>
                                    <span className='lite-currency-item'>MATIC</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyAdaClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/cardano.png" alt="" /></span>
                                    <span className='lite-currency-item'>ADA</span>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around mb-2'>
                                <div className='item-poped pointer bg-color-grey'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyPmClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/pm.png" alt="" /></span>
                                    <span className='lite-currency-item'>PM</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyTonClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/ton.png" alt="" /></span>
                                    <span className='lite-currency-item'>TON</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer bg-color-grey'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuyEthClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/eth.png" alt="" /></span>
                                    <span className='lite-currency-item'>ETH</span>
                                  </div>
                                </div>
                                <div className='item-poped pointer'>
                                  <div style={{textDecorationLine:'none'}} onClick={BuySolClick}>
                                    <span><img className='images-drapeau mr-2' src="../../images/solana.png" alt="" /></span>
                                    <span className='lite-currency-item'>SOL</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                      </div>
                    </div>

                    <div id="YjZ2c0HjF" className="__overlay">
                      <div className={`${themeMode === "true" ? "__popup __popup-t" : "__popup"}`}>
                      {Logged === 'Logout' ?
                        <Alert severity="warning"> Vous n'êtes pas connecté ! </Alert>:
                        <h4 style={{fontFamily:'Palatino, URW Palladio L, serif', color:'#810404'}} className='bolder'>Que voulez-vous vendre ?</h4>}
                        
                        <a className="__close" href="#/">&times;</a>
                        {
                          Logged === 'Logout' ? 
                          <div className='focus-content text-center'>
                            <br/>
                            <span>Veuillez vous connecter pour commencer!</span>
                            <div className='focus-button-wrapper'>
                              <button className="focus-btn focus-outline" onClick={handleClose}><a href="#/">NON MERCI</a></button>
                              <button className="focus-btn focus-fill" onClick={handleClose2}>SE CONNECTER</button>
                            </div>
                          </div>:
                          <div className="__content">
                            <div className='d-flex justify-content-around mb-2'>
                              {
                                SenderCountry === ci ?
                                <div className='item-poped pointer' onClick={SelBtcClick}>
                                  <div style={{textDecorationLine:'none'}}>
                                    <span><img className='images-drapeau mr-2' src="../../images/btc.png" alt="" /></span>
                                    <span className='lite-currency-item'>BTC</span>
                                  </div>
                                </div>:
                                <div className='item-poped pointer bg-color-grey'>
                                  <div style={{textDecorationLine:'none'}}>
                                    <span><img className='images-drapeau mr-2' src="../../images/btc.png" alt="" /></span>
                                    <span className='lite-currency-item'>BTC</span>
                                  </div>
                                </div>
                              }
                              <div className='item-poped pointer' onClick={SelUsdtClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/usdt.png" alt="" /></span>
                                  <span className='lite-currency-item'>USDT</span>
                                </div>
                              </div>
                              <div className='item-poped pointer bg-color-grey' onClick={SelBusdClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/busd.png" alt="" /></span>
                                  <span className='lite-currency-item'>BUSD</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelDotClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/pokadot.png" alt="" /></span>
                                  <span className='lite-currency-item'>POL</span>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-around mb-2'>
                              <div className='item-poped pointer bg-color-grey' onClick={SelLtcClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/ltc.png" alt="" /></span>
                                  <span className='lite-currency-item'>LTC</span>
                                </div>
                              </div>
                              <div className='item-poped pointer bg-color-grey' onClick={SelDogeClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/doge.png" alt="" /></span>
                                  <span className='lite-currency-item'>DOGE</span>
                                </div>
                              </div>
                              <div className='item-poped pointer bg-color-grey' onClick={SelShibaClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/shiba.png" alt="" /></span>
                                  <span className='lite-currency-item'>SHIB</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelLinkClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/chainlink.png" alt="" /></span>
                                  <span className='lite-currency-item'>LINK</span>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-around mb-2'>
                              <div className='item-poped pointer' onClick={SelBnbClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/bnb.png" alt="" /></span>
                                  <span className='lite-currency-item'>BNB</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelTronClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/tron.png" alt="" /></span>
                                  <span className='lite-currency-item'>TRON</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelMaticClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/polygonmatic.png" alt="" /></span>
                                  <span className='lite-currency-item'>MATIC</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelAdaClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/cardano.png" alt="" /></span>
                                  <span className='lite-currency-item'>ADA</span>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-around mb-2'>
                              <div className='item-poped pointer bg-color-grey' onClick={SelPmClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/pm.png" alt="" /></span>
                                  <span className='lite-currency-item'>PM</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelTonClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/ton.png" alt="" /></span>
                                  <span className='lite-currency-item'>TON</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelEthClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/eth.png" alt="" /></span>
                                  <span className='lite-currency-item'>ETH</span>
                                </div>
                              </div>
                              <div className='item-poped pointer' onClick={SelSolClick}>
                                <div style={{textDecorationLine:'none'}}>
                                  <span><img className='images-drapeau mr-2' src="../../images/solana.png" alt="" /></span>
                                  <span className='lite-currency-item'>SOL</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div> 
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={open2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <strong style={{color:"red"}}>{"Echec de l'opération :("}</strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className='d-flex justify-content-center'>
                  <div className="svg-container">    
                      <svg className="ft-red-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                          <SmsFailedIcon className="text-danger"/>
                      </svg>
                  </div>
              </div>
              Vous devez peut être reprendre une nouvelle votre opération!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}> Ok </Button>
          </DialogActions>
        </Dialog>

        <ServiceDisponible />
        <RecentSuccessOperation /><br/><br/>
        <StatisticOfOperations/>
      </div>

      <ChatCustomer />
      <BottomNavBar />
    </div>
  )
}
