import React, { useState, useEffect } from 'react';

const ImageSlider = ({ images, interval = 5000 }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const fadeOut = setTimeout(() => setOpacity(0), interval - 1000);
    const changeImage = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setOpacity(1);
    }, interval);

    return () => {
      clearTimeout(fadeOut);
      clearTimeout(changeImage);
    };
  }, [currentIndex, images.length, interval]);

  return (
    <div className="nkab-slider-container">
      <img
        src={images[currentIndex]}
        alt={`Slide ${currentIndex}`}
        className="nkab-slider-image"
        style={{ opacity }}
      />
    </div>
  );
};

export default ImageSlider;