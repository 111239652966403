import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import ChatCustomer from '../components/ChatCustomer'
import RecentSuccessOperation from '../components/RecentSuccessOperation'
import ServiceDisponible from '../components/ServiceDisponible'
import SupportCommunity from '../components/SupportCommunity'
import { Button, CardActionArea, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel';
import coinGecko from '../api/coinGecko'
import CampaignIcon from '@mui/icons-material/Campaign';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import NumbersStatus from '../components/NumbersStatus'
import Banner from '../components/Banner'
import BottomNavBar from '../components/BottomNavBar'
import PaymentWays from '../components/paymentways/PaymentWays'

const HomeScreen = (props) => {

  localStorage.removeItem('singup')

  var   _ProWallet = localStorage.getItem('_ProWallet')
  const Logged = localStorage.getItem('userStatus')

  const [ownWitness, setOwnWitness] = useState([])
  const [WitnessesStopReq, setWitnessesStopReq] = useState(false)

  const [USDTRateStop, setUSDTRateStop] = useState(false)
  const [NT_rateUSDT, setNT_rateUSDT] = useState([])

  const [USDTRate2Stop, setUSDTRate2Stop] = useState(false)
  const [NT_rate2USDT, setNT_rate2USDT] = useState([])

  const LineLink = true
  // const LineLink = false

  useEffect(()=>{

      const fetchData = async () => {

          if(!USDTRateStop){

              try {
                  const res = await axios.get('/api/nt-rateusdt')

                  if(res.data[0].ID){
                      setNT_rateUSDT(res.data[0].usdt)
                      setUSDTRateStop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()

  },[USDTRateStop])

  useEffect(()=>{
  
      const fetchData = async () => {
  
          if(!USDTRate2Stop){
  
              try {
                  const res = await axios.get('/api/nt-rate2usdt')
  
                  if(res.data[0].ID){
                      setNT_rate2USDT(res.data[0].usdt)
                      setUSDTRate2Stop(true)
                  }
              } catch (error) {
              }
          }
      }
      fetchData()
  
  },[USDTRate2Stop])

  var dashAction  = props.location.hash.slice(1, 17)
  var homePath    = props.location.pathname.slice(1, 5)
  var firstPath   = props.match.path

  useEffect(() => {

    if((homePath === "home" || firstPath === '/') && Logged === 'Logged'){
        
      if (_ProWallet === 'true') {

        if(LineLink){
          window.location.replace("https://nkab-exchange.net/nkab-pro");
        } else {
           window.location.replace("http://localhost:3000/nkab-pro");
        }
       }
       
    }

  }, [Logged, props.history, dashAction, homePath, _ProWallet, firstPath, LineLink])

  const [isMenuVisible, setMenuVisibility] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isVisible = prevScrollPos > currentScrollPos;
  
      setMenuVisibility(isVisible);
      setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [prevScrollPos]);

  const STATUS = { STARTED: 'Traitement en cours...', }
  
    const INITIAL_COUNT1 = 300
  
    const [secondsRemaining1,            setSecondsRemaining1 ] = useState(INITIAL_COUNT1)
    const [status1,                      setStatus1           ] = useState(STATUS.STOPPED)
  
    useEffect(()=>{
      setStatus1(STATUS.STARTED)
    },[STATUS.STARTED])

  useInterval(
    () => {
    if (secondsRemaining1 > 0) {
        setSecondsRemaining1(secondsRemaining1 - 1)
    } else {
        setStatus1(STATUS.STOPPED)
    }
    },

    status1 === STATUS.STARTED ? 1000 : null,
    // passing null stops the interval
)

function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
    savedCallback.current = callback
    }, [callback])

// Set up the interval.
useEffect(() => {
  function tick() {
    savedCallback.current()
  }
  if (delay !== null) {
    let id = setInterval(tick, delay)
    return () => clearInterval(id)
  }
}, [delay])
}

var [themeMode, setThemeMode] = useState()

let ThemeMode = localStorage.getItem("ThemeMode")

useEffect(()=>{
  
  if(secondsRemaining1 > 0){
    setThemeMode(ThemeMode)
  }

},[ThemeMode, secondsRemaining1])



  useEffect(() => {
    const fetchWitnessDada = async () => {
      try {
        if (!WitnessesStopReq) {
          const { data } = await axios.get(
            '/api/v1/get-witnesses',
          )
          if (data) {
            const _data = data
            const items = _data.slice(0, 3)
            setOwnWitness(items)
          }
          if (data.length > 0) {
            setWitnessesStopReq(true)
          }
        }
      } catch (error) { }
    }
    fetchWitnessDada()
  }, [ownWitness, WitnessesStopReq])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


  function handleStartOperation(){

    if(LineLink){
    
      if(Logged === 'Logout'){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
        window.location.replace("https://nkab-exchange.net/sell-or-buy-crypto");
      }
      
    } else {
    
      if(Logged === 'Logout'){
        window.location.replace("http://localhost:3000/login");
      } else {
        window.location.replace("http://localhost:3000/sell-or-buy-crypto");
      }
    }
  }

  const [coins2, setCoins2] = useState([])

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      const response = await coinGecko.get('/coins/markets', {
        params: {
          vs_currency: 'usd',
          ids:
            'bitcoin,tether,binancecoin,binance-usd,tron,litecoin,dogecoin',
        },
      })
      if(response.data.length > 0){
        setCoins2(response.data)
      }
    }
    fetchDada2()
  },[])

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpen2(false);
  };

  const handleClose2 = () => {

    setOpen(false);

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }

  };

  function handleLogout(){

    localStorage.clear();

    if(_ProWallet === "true"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", true)
      localStorage.setItem("userStatus", "Logout")

      if(LineLink){
        window.location.replace("https://nkab-exchange.net/login");
      } else {
         window.location.replace("http://localhost:3000/login");
      }
    }
    if(_ProWallet === "false"){
      localStorage.setItem("isRActive",false)
      localStorage.setItem("_ProWallet", false)
      localStorage.setItem("userStatus", "Logout")
    }
    
    localStorage.setItem("isRActive",false)

    if(LineLink){
      window.location.replace("https://nkab-exchange.net/login");
    } else {
       window.location.replace("http://localhost:3000/login");
    }
  }

  
  
  

  useEffect(()=>{
    
    const fetchDada2 = async () => {

      try{

        const myHeaders = new Headers();
        myHeaders.append("X-CMC_PRO_API_KEY", "cb764d88-1d56-47db-8133-0429d1395332");
        myHeaders.append("Accept", "*/*");
        myHeaders.append("INSERT_YOUR_API_KEY",  "cb764d88-1d56-47db-8133-0429d1395332");
      
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
      
        fetch("https://pro-api.coinmarketcap.com/v4/dex/spot-pairs/latest?network_id=1", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.error(error));
          
      }catch(error){}
    }
    fetchDada2()
  },[])

  return (
    <div>
        <div className={`${themeMode === "true" ? "d-flex justify-content-center nkab-lite-rate-t p-3 mt-4" : "d-flex justify-content-center nkab-lite-rate p-3 mt-4"}`} style={{color:`${themeMode === "true" ? "#90d9ff" : "#00360c"}`}}>
          <div className="col-sm-12">
            <div className="text-center">
            <div><CampaignIcon style={{fontSize:"30px"}}/>Taux d'achat 1 USD = <strong>{NT_rateUSDT}</strong>  XOF | Taux de vente 1 USD = <strong>{NT_rate2USDT}</strong> XOF</div> 
            <div></div>
            <div className='nkab-annonceur'>Dorénavant, pour toute assistance, nous vous prions de bien vouloir nous contacter via WhatsApp au numéro suivant: <strong className='nkab-annonceur-num'>+225 07 48 09 81 05</strong></div>
              <button onClick={Logged === 'Logout'?handleClickOpen:handleStartOperation} className="welcom-gradient-button">COMMENCEZ ICI</button>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <span style={{color:"#c7b301", fontSize:"12px"}}>Vous souhaitez effectuer une opération?</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{color:"#fff", fontSize:"16px"}}>
              Vous devez vous connecter pour commencer <EmojiEmotionsIcon style={{color:"#c7b301"}} /> !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{color:"#c7b301"}}> Fermer </Button>
            <Button onClick={handleClose2} sx={{ bgcolor: '#c7b301', color:"#0A1929", borderRadius:"20px"}}>Se connecter</Button>
          </DialogActions>
        </Dialog>
      <section>
        <div>
          <div className='row accueil-main_container'>
            <div className='col-md-2'></div>
            <div className='col-md-4'>
              <div>
                <div className='nkab-cryptocurrency-home-image d-flex justify-content-center'> <img className='home-currency-img' src="../../images/forma_image_nkab2.png" width="600" alt="nkab home" /> </div>
              </div>
            </div>
            <div className={`${themeMode === "true" ? "col-md-4" : "col-md-4"}`}>
              <div className="text-center wellcome-title">
                <h1 className='mb-4 animate__animated animate__zoomInDown'>Achetez et Vendez en toute sécurité à des prix abordables ! </h1>
                <h6 className='animate__animated animate__backInRight'> Vous satisfaire dans le monde de la crypto-monnaie est notre priorité. </h6>
              </div>
              <div className='d-flex justify-content-center animate__fadeInDown animate__animated'>
                <button onClick={Logged === 'Logout'?handleClickOpen:handleStartOperation} className="welcom-gradient-button">COMMENCEZ MAINTENANT</button>
              </div>
            </div>
            <div className='col-md-2'></div>
          </div>

          <div>
            <div className="row text-center animate__animated animate__backInLeft">
              <div className="text-center">
                <div className='container'>
                  <Banner/><br/>
                  <NumbersStatus themeMode={themeMode}/>
                  {/* <Variations /> */}

                  <div className={`${themeMode === "true" ? "nkab-com-t" : "nkab-com"}`}>
                    <SupportCommunity />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    
      <section id='allservices'>
        <div className="_our-wrapper">
          <PaymentWays themeMode={themeMode}/>
          <ServiceDisponible />
          <RecentSuccessOperation /><br />
        </div>
      </section>

      <div className='mb-4 container'>
        <div className="_our-header">
          <h1 className="header__title" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}} >TEMOIGNAGES RECENTS</h1>
          <h2 className="header__subtitle" style={{color:`${themeMode === "true" ? "#90d9ff" : "#333"}`}}>Votre satisfaction notre motivation</h2>
        </div>
        <Carousel
              responsive={responsive}
              itemClass={`${themeMode === "true" ? "react-multi-carousel-item react-multi-carousel-item-t" : "react-multi-carousel-item"}`}
            >
        {
          ownWitness.map((item, index) => (
            <div key={index} sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                    {item.posterName}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" style={{fontSize:"13px", color:`${themeMode === "true" ? "#cce8f7" : ""}`}}>
                    {item.witnessText}
                  </Typography>
                  <Typography gutterBottom variant="h5" component="div" style={{color:`${themeMode === "true" ? "#90d9ff" : ""}`}}>
                    <span style={{fontSize:'10px', fontStyle:'italic'}}>{item.updatedAt.slice(0,10)} à {Number(item.updatedAt.slice(11,13)) + 2}{item.updatedAt.slice(13,19)}</span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </div>
          ))
        }
        </Carousel>
      </div>

      <ChatCustomer />
      <BottomNavBar />

    </div>
  )
}



export default HomeScreen

