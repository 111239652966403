import React from 'react'

const Banner = () => {

  return (
    <div className='container mt-5 mb-5'>
        <img className='nkab-exchange-banner' src="../../images/nkab-bannier.jpg" alt="bannière nkab exchange" />
    </div>
  )
}

export default Banner